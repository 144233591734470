import { Card } from '../../Components/Shared/Card/Card'
import { SubHeader } from '../../Components/Shared/SubHeader/SubHeader'
import { Col, Content, Subtext, TextContent } from './Home.styled'
import { ReactComponent as CircleCheck } from '../../assets/svgs/check-circle.svg'
import { useI18n } from '../../hooks/useI18n'

export const Home = () => {
	const { translation } = useI18n()
	return (
		<>
			<Card fullScreen={true} noBtmRadius>
				<Content>
					<Col>
						<CircleCheck />
						<TextContent>
							<SubHeader
								text={translation('successDefaultHeader')}
							/>
							<Subtext>
								{translation('verifiedDefaultSubtext')}
							</Subtext>
						</TextContent>
					</Col>
				</Content>
			</Card>
		</>
	)
}
