import { useMemo } from 'react'
import {
	AS,
	Arrangment,
	ButtonVariant
} from '../../Components/Shared/Result/types'
import { Loading } from '../../Components/Loading'
import { ErrorAlert } from '../../Components/Shared/ErrorAlert'
import { Result } from '../../Components/Shared/Result/Result'
import { useSession } from '../../hooks/useSession'
import { useSlugDetails } from '../../hooks/useSlugDetails'
import { useI18n } from '../../hooks/useI18n'
import { ErrorContainer } from './KycComplete.styled'

export const KycComplete = () => {
	const { translation } = useI18n()
	const { closeSession } = useSession()
	const { data, error, isLoading } = useSlugDetails()

	const errorReason = useMemo(() => {
		if (error) {
			return Object.values(error?.errors)[0]
		}
		return 'Something went wrong'
	}, [error])

	const externalLinkCB = () => {
		if (window && data) {
			closeSession()
			let re = new RegExp(
				/(?:https|http?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/
			)
			let link = re.test(data?.company?.website)
				? data?.company?.website
				: `https://${data?.company?.website}`
			window.location.replace(link)
		}
	}

	let SuccessButton = undefined

	if (data && externalLinkCB) {
		SuccessButton = {
			text: `Go to ${data?.company.name}`,
			variant: 'contained' as ButtonVariant,
			as: 'button' as AS,
			arrangment: 'column' as Arrangment,
			callback: externalLinkCB
		}
	}

	return (
		<>
			{error ? (
				<ErrorContainer>
					<ErrorAlert message={errorReason} severity={'error'} />
				</ErrorContainer>
			) : null}
			{isLoading ? <Loading /> : null}
			{!isLoading ? (
				<Result
					backgroundTransparent={true}
					hideFooter={false}
					button={SuccessButton}
					header={translation('successDefaultHeader')}
					subtext={translation('KYCSuccessSubtext')}
				/>
			) : null}
		</>
	)
}
