import { FC, MouseEvent, useState } from 'react'
import { IProps } from './types'
import {
	Actions,
	CustomAvatar,
	CustomMenu,
	CustomMenuItem
} from './Profile.styled'
import { useWs } from '../../../Context/WebsocketProvider/useWsContext'
import { useI18n } from '../../../hooks/useI18n'

export const Profile: FC<IProps> = ({ logout }) => {
	const { socket } = useWs()
	const { translation } = useI18n()
	const [toggle, setToggle] = useState<null | HTMLElement>(null)
	const open = Boolean(toggle)

	const handleToggle = (event: MouseEvent<HTMLDivElement>) => {
		setToggle(event.currentTarget)
	}

	const handleClose = () => {
		setToggle(null)
	}

	const handleSignout = () => {
		if (socket) {
			socket.on('disconnect', () => {})
		}
		logout()
	}

	return (
		<Actions>
			<CustomAvatar onClick={handleToggle} alt="profile_pic" />
			<CustomMenu onClose={handleClose} anchorEl={toggle} open={open}>
				<CustomMenuItem onClick={handleSignout}>
					{translation('logout')}
				</CustomMenuItem>
			</CustomMenu>
		</Actions>
	)
}
