import { Typography } from '@mui/material'
import styled from 'styled-components'
import ErrorTwoTone from '@mui/icons-material/ErrorTwoTone'
import { CSSProps } from './types'

export const Icon = styled(ErrorTwoTone)`
	color: #ED6C02;
  font-size: 48px;
`;

export const Card = styled.div<CSSProps>`
	width: 100%;
	height: ${({isMobile})=> isMobile ? "94vh" : "100vh"};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 50px;
`;

export const Content = styled.div`
  width: 40%;
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  align-items: center;
	@media (max-width: 1060px) {
			width: 60%;
	}
	@media (max-width: 600px) {
			width: 100%;
	}
`;

export const Subtext = styled(Typography)`
  color: #050B14;
  text-align: center;
`;
