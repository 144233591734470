import styled from 'styled-components'
import { Typography } from '@mui/material'

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	width: 100%;
`

export const Form = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 16px;
`

export const ButtonContainer = styled.div`
	width: 100%;
`

export const ErrorContainer = styled.div`
	position: absolute;
	top: 30px;
	right: 0px;
	z-index: 999;
	min-width: 300px;
	width: fit-content;
`

export const SubText = styled(Typography)``
