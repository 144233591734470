import { FC } from "react"
import { Input } from "../Shared/Input";
import { Card } from "../Shared/Card/Card";
import { Header } from "../Shared/Header";
import { FormButton } from "../Shared/Button";
import { Form, Subtext,  HeaderContainer } from "./UserEmail.styled";
import { IForm, IProps } from "./types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormControl, FormHelperText } from "@mui/material";
import { useTheme } from "styled-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHelpers } from "./useHelpers";
import { CardBody } from "../Shared/CardBody/CardBody";

export const UserEmail: FC<IProps> = ({handleEmail})=>{
    const theme = useTheme()
    const { schema } = useHelpers()
    const {
		watch,
		formState: { errors },
		control,
		handleSubmit
	} = useForm<IForm>({
		defaultValues: {
			email: ''
		},
		resolver: yupResolver(schema),
		mode: 'all'
	})

	let email = watch('email')

	const onSubmit: SubmitHandler<IForm> = (data) => {
		handleEmail(data.email)
	}

    return <Card fullScreen={false}>
		<CardBody fullScreen={true}>
		<HeaderContainer>
        <Header>
            Enter your email
        </Header>
		<Subtext
			sx={{
				color: theme.typography.body2
			}}
			variant="body2"
		>
			Please enter your email address to store your identity data in decenteralized storage.
		</Subtext>
		</HeaderContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
							<FormControl>
								<Controller
									name="email"
									control={control}
									rules={{ required: true }}
									render={({
										field: {
											ref,
											value,
											onChange,
											...props
										}
									}) => (
										<Input
											error={errors.email ? true : false}
											{...props}
											inputRef={ref}
											value={value}
											onChange={onChange}
											variant="filled"
											label="Email Address"
											type="email"
										/>
									)}
								/>
								{errors.email ? (
									<FormHelperText
										sx={{ color: theme.palette.error }}
									>
										{errors.email?.message}
									</FormHelperText>
								) : null}
							</FormControl>
                            <FormButton type="submit" disabled={!email || email.length === 0 || errors.email ? true : false}>
                                Continue
                            </FormButton>
                            </Form>
		</CardBody>
	</Card>
}