import {
	Fragment,
	useRef,
	useCallback,
	useEffect,
	useMemo,
	useState,
	FC
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import { Loading } from '../../Components/Loading'
import { ErrorAlert } from '../../Components/Shared/ErrorAlert'
import { Card } from '../../Components/Shared/Card/Card'
import { CardBody } from '../../Components/Shared/CardBody/CardBody'
import { Header } from '../../Components/Shared/Header'
import { FormButton } from '../../Components/Shared/Button'
import {
	Content,
	Col,
	HeaderContainer,
	IFrame,
	IFrameContent,
	Subtext,
	Wrapper,
	Container,
	ErrorContainer
} from './Veridas.styled'
import { KYCInstructions } from '../../Components/Shared/KYCInstructions/KYCInstructions'
import { useMediaQuery } from '@mui/material'
import { isMobile } from 'react-device-detect'
import { ISubscribeEventArgument } from '../../hooks/useSubscribeEvent/types'
import { useVeridasAlert } from '../../hooks/useVeridasAlert'
import {
	useLocation,
	useNavigate,
	useParams,
	createSearchParams
} from 'react-router-dom'
import { setVeridasToken, setDocumentId } from '../../store/Reducers/Login/userSlice'
import { IProps, IVeridasError } from './types'
import { useI18n } from '../../hooks/useI18n'
import { BrowserNotSupported } from '../../Components/BrowserNotSupported/BrowserNotSupported'
import { validateKYCProcessData } from '../../Utils/validateScore'
import { RestartAgain } from '../../Components/RestartAgain'
import { useWs } from '../../Context/WebsocketProvider/useWsContext'


export const Veridas: FC<IProps> = ({ qrKYC }) => {
	const { translation, i18n } = useI18n()
	const { socket } = useWs();
	const { veridasAccessToken } = useSelector((state: RootState) => state.user)
	const [tokenError, setTokenError] = useState<IVeridasError | null>(null)
	const [macAlert, setMacAlert] = useState<boolean>(false)
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { slug } = useParams()
	const [frameLoading, setFrameLoading] = useState<boolean>(true)
	const node = useRef<HTMLIFrameElement>(null)
	const match = useMediaQuery('(min-width: 600px)')
	const [scan, setScan] = useState<boolean>(false)
	
	const {
		handleVeridasAlert,
		error: veridasAlertError,
		isLoading: veridasAlertLoading
	} = useVeridasAlert()

	const handleError = useCallback(
		(event: any) => {
			if (event.data.type === 'error') {
					setTokenError({
						...event.data
					})
				}
		
		},
		[ setTokenError ]
	)

	const receiveMessage = useCallback(
		(event: any) => {
			if (event.origin.includes('xpressid')) {
				
				if (event.data.type === 'error') {
					handleError(event)
				}
				if (isMobile && event.data.code === 'ProcessCompleted') {
					let {isValidScore} = validateKYCProcessData(event.data.additionalData)
					handleVeridasAlert(JSON.stringify({data: event.data, isValid: isValidScore}), isValidScore);	
				}
			}
		},
		[handleVeridasAlert, handleError]
	)

	useEffect(() => {
		window.addEventListener('message', receiveMessage, false)
		return () => {
			window.removeEventListener('message', receiveMessage, false)
		}
	}, [receiveMessage])

	const showWidget = useMemo(() => {
		return !veridasAlertLoading
	}, [veridasAlertLoading])

	useEffect(() => {
		const validationId = veridasAccessToken?.validation_id
		if (validationId && !qrKYC) {
			let argument: ISubscribeEventArgument = { eventName: validationId }
			if (slug && slug.length > 0) {
				argument['redirectURL'] = {
					pathname:  `/${slug}/process-kyc`,
					search: createSearchParams(location.search).toString()
				}
			}
			socket?.on(validationId, (data: any)=>{
				navigate({
					...argument.redirectURL,
				},{
					state: data
				})
			})
		}

		return ()=>{
			if(validationId && !qrKYC){
				socket?.off(validationId);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [veridasAccessToken, navigate])

	const isMandarin = useMemo(() => {
		return localStorage.getItem('i18n') === 'zh' || i18n.language === 'zh'
	}, [i18n])

	let error = useMemo(() => {
		
		if (veridasAlertError) {
			return {
				header: 'Something went wrong',
				reason: Object.values(veridasAlertError.errors)[0]
			}
		}
		let onMobile = "In order to complete KYC, close the current session and restart the new one by going to your desktop";
		
		if(!veridasAccessToken && !tokenError){
			return {
				header: 'Invalid session',
				reason: `Unfortunately, your current session has timed out.${ qrKYC ? onMobile :"To complete your KYC, kindly click on the 'Try Again' to initiate a new session and continue with the required procedures."}`
			}
		}

		if (tokenError) {
			if(tokenError.errorData?.["errorCode"] === 'TokenNotAllowed'){
				return {
					header: 'Session has expired',
					reason: `Your session has expired. ${ qrKYC ? onMobile : "To complete your KYC, please click on 'Try Again' to initiate a new session"}`,
				}
			}
			return{
				header: 'Process Error',
				reason: !tokenError.errorData ? tokenError.message : tokenError.errorData?.['errorDetail'],
			}
		}
		return null
	}, [veridasAlertError, tokenError, qrKYC, veridasAccessToken])

	useEffect(() => {
		let platformBrowser = window.navigator.platform;
		let browser = window.navigator.userAgent;
		let platform = /Mac|iPhone|iPod|iPad/i
		let testBrowser = /Chrome|firefox|Opera|Seamonkey|OPR/i

		if (platform.test(platformBrowser) && testBrowser.test(browser)) {
			setMacAlert(true)
		}
	}, [setMacAlert])

	const isBrowserSupported = useMemo(() => {
		if (tokenError && tokenError.code === 'BrowserNotSupported') {
			return true
		}
		return false
	}, [tokenError])

	const stepRefresh = useMemo(()=>{
		return tokenError?.additionalData === 'document' || tokenError?.additionalData === 'selfie'
	}, [tokenError])

	const redirect = () => {
		const { pathname } = location;
		let next = { 
			pathname: '/select-country-and-document-type',
			search: ''
		}
		
		if (slug && slug.length > 0) {
			next = {
				pathname: `/${slug}/kyc`,
				search: createSearchParams(location.search).toString()
			}			
		}
		dispatch(setVeridasToken(null))
		dispatch(setDocumentId(null))
		if (isMobile && pathname === `/${slug}/mobile/kyc` ) {
			handleVeridasAlert(JSON.stringify({data: tokenError, isValid: false}), false);
		} else {
			navigate(next)
		}
	}

	const refresh = ()=>{
		window.location.reload();
	}

	return (
		<>
			{isBrowserSupported? (
				<BrowserNotSupported subtext={error?.reason} />
			) : (
				<Card fullScreen={true}>
					{stepRefresh || veridasAlertError ? (
						<ErrorContainer>
							<ErrorAlert message={error?.reason} />
						</ErrorContainer>
					) : null}
					{!stepRefresh && tokenError && !veridasAlertError ? <RestartAgain header={error?.header} handleError={redirect} reason={error?.reason} />: null}
					{macAlert ? (
						<ErrorContainer>
							<ErrorAlert
								title="Alert"
								severity="warning"
								message={
									'Use Safari browser for a better experience'
								}
							/>
						</ErrorContainer>
					) : null}
					<CardBody fullScreen={true}>
						{!match && isMobile && !scan ? (
							<Col>
								{' '}
								<KYCInstructions />{' '}
								<FormButton onClick={() => setScan(true)}>
									{translation('start')}
								</FormButton>
							</Col>
						) : (
							<Fragment>
								<HeaderContainer isMobile={isMobile}>
									<Header>
										{translation('continueOnMobile')}
									</Header>
									<Subtext variant="body2">
										{translation('veridasSubtext')}
									</Subtext>
								</HeaderContainer>
								<Content>
									
									{(!tokenError && veridasAlertLoading)? (
										<Loading />
									) : null}
									{showWidget ? (
										<Wrapper>
											<IFrameContent>
												{!tokenError && veridasAccessToken  && frameLoading ? (
													<Loading />
												) : null}
												{stepRefresh ? (
													<Container
														isMobile={isMobile}
													>
														<FormButton
															onClick={refresh}
														>
															{translation(
																'startAgain'
															)}
														</FormButton>
													</Container>
												) : null}
												{!tokenError && veridasAccessToken ? (
													<IFrame
														ref={node}
														isMandarin={isMandarin}
														isMobile={isMobile}
														frameLoading={
															frameLoading
														}
														loading="eager"
														onLoad={() => {
															setFrameLoading(
																false
															)
														}}
														id="XpressID-iframe"
														allow="camera; microphone; geolocation;"
														src={`${process.env.REACT_APP_XPRESS_URI}?access_token=${veridasAccessToken?.access_token}`}
													/>
												) : null}
											</IFrameContent>
										</Wrapper>
									) : null}
								</Content>
							</Fragment>
						)}
					</CardBody>
				</Card>
			)}
		</>
	)
}
