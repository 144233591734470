import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { IVariables, IResponse, IError } from './types'
import { useParams, useSearchParams } from 'react-router-dom'
import { useRequestClientContext } from '../../Context/RequestClientProvider'
import { AxiosResponse, AxiosError } from 'axios'

export const useFindUser = () => {
	const URL = '/users/find-by-email';
	const { slug } = useParams();
	const [searchParams] = useSearchParams()
	const request = useRequestClientContext()
	const email = searchParams.get('email')

	const requestQuery = async (): Promise<
		AxiosResponse<IResponse> | AxiosError<IError> | any
	> => {
		let variables = {
			email: email ?? ''
		}
		return await request?.get<IResponse, IVariables, IError>(variables, URL)
	}

	const { isFetching, error, data, refetch } = useQuery<IResponse, IError>({
		queryKey: ['findUser'],
		queryFn: requestQuery,
		enabled: false,
		cacheTime: 0
	})

	useEffect(() => {
		if (email && !data && slug !== 'dlabs') {
			refetch()
		}

	}, [email, refetch, data, slug])

	return {
		findUser: refetch,
		data,
		loading: isFetching,
		error: error
	}
}
