import { FC, useRef } from 'react'
import { Result } from '../Shared/Result'
import { IProps } from './types'
import { ReactComponent as CircleWarnning } from '../../assets/svgs/warnning-circle.svg'
import styled from 'styled-components'
import { IconButton } from '@mui/material'
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import { theme } from '../../Theme'

export const BrowserNotSupported: FC<IProps> = ({ subtext }) => {
	const linkNode = useRef(false);

	const copyToClipboard = ()=>{
		linkNode.current = true;
		let link =  window.location.href;
		window.navigator.clipboard.writeText(link);
	}

	return (
		<Result
			header={'Browser Not Supported'}
			subtext={subtext}
			Icon={CircleWarnning}
			extraComponent={
				<LinkWrapper>
					<Text>{ window.location.href }</Text>
					<CustomIconButton onClick={copyToClipboard} title="copy" >
						<InventoryRoundedIcon sx={{color: linkNode.current ? theme.palette.success : theme.palette.input }}  />
					</CustomIconButton>
				</LinkWrapper>
			}
		/>
	)
}

const LinkWrapper = styled.div`
	border: 1px solid ${(props)=> props.theme.palette.input};
	display: flex;
	border-radius: 5px;
	background-color: ${(props)=> props.theme.palette.input}
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	align-items: center;
	`

const CustomIconButton = styled(IconButton)`
	padding: 5px;

`;

const Text = styled.span`
	padding: 0px 5px;
`;