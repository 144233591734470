import { Dialog, DialogTitle, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomDialog = styled(Dialog)`
    
`;

export const CustomDialogTitle = styled(DialogTitle)`
    color: #ED6C02; //${(props)=> props.theme.palette.error};
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-direction: column;
`

export const Text = styled(Typography)`
    text-align: center;
    font-weight: 700;
`;