import { useMemo } from 'react'
import {
	AS,
	Arrangment,
	ButtonVariant
} from '../../Components/Shared/Result/types'
import { Loading } from '../../Components/Loading'
import { Result } from '../../Components/Shared/Result/Result'
import { useSlugDetails } from '../../hooks/useSlugDetails'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { ReactComponent as CircleWarnning } from '../../assets/svgs/warnning-circle.svg'
import { useNavigate } from 'react-router-dom'
import { useI18n } from '../../hooks/useI18n'

export const ApproveRequestSuccess = () => {
	const { t } = useI18n()
	const { data, error, isLoading } = useSlugDetails()
	const { deleteItem } = useLocalStorage()
	const navigate = useNavigate()
	const buttonCallback = () => {
		deleteItem('TOGGGLE_REFERER_ID')
		navigate('/')
	}
	const button = {
		text: 'Close',
		variant: 'contained' as ButtonVariant,
		as: 'button' as AS,
		arrangment: 'column' as Arrangment,
		callback: buttonCallback
	}

	const errorReason = useMemo(() => {
		if (!error) {
			return 'Something went wrong'
		} else if (typeof error?.errors === 'string') {
			return t(error?.errors.split(' ').join(''))
		}else{
			return t(Object.values(error.errors)[0])
		}
	}, [error, t])

	const externalLinkCB = () => {
		if (window && data) {
			let re = /(?:https|http?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/
			let link = re.test(data?.company.website)
				? data?.company.website
				: `https://${data?.company.website}`
			window.location.replace(link)
		}
	}

	let SuccessButton = undefined

	if (data && externalLinkCB) {
		SuccessButton = {
			text: `Go to ${data?.company.name}`,
			variant: 'contained' as ButtonVariant,
			as: 'button' as AS,
			arrangment: 'column' as Arrangment,
			callback: externalLinkCB
		}
	}

	return (
		<>
			{isLoading ? <Loading /> : null}
			{data && !isLoading ? (
				<Result
					backgroundTransparent={true}
					hideFooter={false}
					button={SuccessButton}
					header="Access Granted"
					subtext={
						t('approvedRequest_granted', {
							companyName: data?.company?.name
						}) ?? ''
					}
				/>
			) : null}
			{error && !isLoading ? (
				<Result
					Icon={CircleWarnning}
					backgroundTransparent={true}
					hideFooter={false}
					button={button}
					header={`OOPS! ${errorReason ?? 'Something went wrong'}`}
				/>
			) : null}
		</>
	)
}
