import { Typography, Autocomplete, Popper } from '@mui/material'
import styled from 'styled-components'

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 16px;
`

export const Header = styled(Typography)`
	font-weight: 600px;
	font-size: 24px;
`

export const DocumentList = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 10px;
`

export const Subtext = styled(Typography)``

export const CustomAutocomplete = styled(Autocomplete)`
	width: 100%;
`
export const ErrorContainer = styled.div`
	position: absolute;
	top: 80px;
	right: 0px;
`

export const StyledPopper = styled(Popper)`
	& .MuiAutocomplete-paper > .MuiAutocomplete-listbox {
		max-height: 200px;
	}
`
export const StyledProvincePopper = styled(Popper)`
	& .MuiAutocomplete-paper > .MuiAutocomplete-listbox {
		max-height: 150px;
	}
`
