import { object, string } from 'yup'

export const useHelpers = () => {
	const requiredMsg = ''
	const schema = object({
		passphrase: string().default('').required(requiredMsg)
	}).required()

	return {
		schema
	}
}
