import { FC } from 'react';
import { Footer, Togggle } from './VerifiedFooter.styled'
import { IProps } from './types'
export const VerifiedFooter: FC<IProps> = ({ light }) => {

	return (
		<Footer>
			<svg width="70" height="15" viewBox="0 0 70 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M3.26368 11.0975L0.268799 3.41684H1.47248L3.99449 10.1517H3.60759L6.14393 3.41684H7.36194L4.3384 11.0975H3.26368Z" fill={light ? "#fff":"#050B14"} fillOpacity="0.6"/>
			<path d="M11.8244 11.2694C11.108 11.2694 10.4584 11.0975 9.87563 10.7536C9.30244 10.4001 8.84867 9.91769 8.51431 9.3063C8.17996 8.6949 8.01278 8.0023 8.01278 7.22851C8.01278 6.45471 8.17518 5.77167 8.49998 5.17938C8.82479 4.57753 9.26423 4.10466 9.81831 3.76075C10.3819 3.41684 11.0124 3.24488 11.7098 3.24488C12.2639 3.24488 12.7559 3.34997 13.1858 3.56013C13.6252 3.76075 13.9978 4.03779 14.3035 4.39125C14.6092 4.73516 14.8432 5.12683 15.0056 5.56628C15.168 5.99616 15.2492 6.44038 15.2492 6.89893C15.2492 6.99446 15.2444 7.10432 15.2349 7.22851C15.2253 7.34314 15.211 7.46256 15.1919 7.58675H8.7006V6.58367H14.5757L14.0599 7.01356C14.1458 6.48814 14.0837 6.02004 13.8736 5.60926C13.673 5.18893 13.3816 4.85935 12.9995 4.62052C12.6174 4.37214 12.1875 4.24795 11.7098 4.24795C11.2322 4.24795 10.7879 4.37214 10.3772 4.62052C9.97593 4.8689 9.66068 5.21759 9.43141 5.66658C9.20214 6.10602 9.11138 6.63144 9.15915 7.24284C9.11138 7.85423 9.20691 8.3892 9.44574 8.84775C9.69412 9.29674 10.0285 9.64543 10.4488 9.89381C10.8787 10.1422 11.3372 10.2664 11.8244 10.2664C12.3881 10.2664 12.861 10.1326 13.2431 9.86515C13.6252 9.59766 13.9357 9.26331 14.1745 8.86208L15.0916 9.34929C14.9387 9.69319 14.7047 10.0132 14.3894 10.3094C14.0742 10.596 13.6968 10.83 13.2574 11.0115C12.8275 11.1835 12.3499 11.2694 11.8244 11.2694Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
			<path d="M16.9948 11.0975V3.41684H18.0695V4.67784L17.9262 4.49156C18.1077 4.12854 18.38 3.84195 18.743 3.63178C19.1156 3.42161 19.5646 3.31653 20.09 3.31653H20.5772V4.39125H19.9037C19.3496 4.39125 18.9054 4.5632 18.5711 4.90711C18.2367 5.25102 18.0695 5.73823 18.0695 6.36873V11.0975H16.9948Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
			<path d="M21.6874 11.0975V3.41684H22.7621V11.0975H21.6874ZM21.6874 1.99821V0.421955H22.7621V1.99821H21.6874Z" fill={light ? "#fff" :"#050B14"} fillOpacity="0.6"/>
			<path d="M25.6008 11.0975V4.49156H24.0675V3.41684H25.6008V2.72902C25.6008 2.19405 25.7202 1.74505 25.959 1.38204C26.1978 1.00947 26.5131 0.727653 26.9048 0.536591C27.2964 0.34553 27.7168 0.25 28.1658 0.25C28.2613 0.25 28.3712 0.259554 28.4953 0.278659C28.6195 0.288213 28.7198 0.302543 28.7963 0.321648V1.29606C28.7294 1.27695 28.6386 1.2674 28.524 1.2674C28.4094 1.25785 28.3282 1.25307 28.2804 1.25307C27.8219 1.25307 27.4397 1.36293 27.134 1.58265C26.8283 1.80237 26.6755 2.18449 26.6755 2.72902V3.41684H28.5527V4.49156H26.6755V11.0975H25.6008Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
			<path d="M30.1489 11.0975V3.41684H31.2236V11.0975H30.1489ZM30.1489 1.99821V0.421955H31.2236V1.99821H30.1489Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
			<path d="M36.7706 11.2694C36.0541 11.2694 35.4045 11.0975 34.8218 10.7536C34.2486 10.4001 33.7948 9.91769 33.4605 9.3063C33.1261 8.6949 32.9589 8.0023 32.9589 7.22851C32.9589 6.45471 33.1213 5.77167 33.4461 5.17938C33.7709 4.57753 34.2104 4.10466 34.7645 3.76075C35.3281 3.41684 35.9586 3.24488 36.656 3.24488C37.21 3.24488 37.702 3.34997 38.1319 3.56013C38.5714 3.76075 38.9439 4.03779 39.2496 4.39125C39.5553 4.73516 39.7894 5.12683 39.9518 5.56628C40.1142 5.99616 40.1954 6.44038 40.1954 6.89893C40.1954 6.99446 40.1906 7.10432 40.181 7.22851C40.1715 7.34314 40.1572 7.46256 40.1381 7.58675H33.6468V6.58367H39.5219L39.006 7.01356C39.092 6.48814 39.0299 6.02004 38.8197 5.60926C38.6191 5.18893 38.3278 4.85935 37.9456 4.62052C37.5635 4.37214 37.1336 4.24795 36.656 4.24795C36.1783 4.24795 35.7341 4.37214 35.3233 4.62052C34.9221 4.8689 34.6068 5.21759 34.3776 5.66658C34.1483 6.10602 34.0575 6.63144 34.1053 7.24284C34.0575 7.85423 34.1531 8.3892 34.3919 8.84775C34.6403 9.29674 34.9746 9.64543 35.395 9.89381C35.8249 10.1422 36.2834 10.2664 36.7706 10.2664C37.3342 10.2664 37.8071 10.1326 38.1892 9.86515C38.5714 9.59766 38.8818 9.26331 39.1207 8.86208L40.0378 9.34929C39.8849 9.69319 39.6508 10.0132 39.3356 10.3094C39.0203 10.596 38.643 10.83 38.2036 11.0115C37.7737 11.1835 37.296 11.2694 36.7706 11.2694Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
			<path d="M45.4087 11.2694C44.6922 11.2694 44.0474 11.0975 43.4742 10.7536C42.9011 10.4001 42.4473 9.92247 42.1129 9.32063C41.7881 8.70923 41.6257 8.02141 41.6257 7.25717C41.6257 6.48337 41.7881 5.79555 42.1129 5.19371C42.4473 4.59186 42.8963 4.11899 43.4599 3.77508C44.0331 3.42161 44.6827 3.24488 45.4087 3.24488C46.0679 3.24488 46.6554 3.38818 47.1713 3.67477C47.6871 3.96136 48.0931 4.34826 48.3893 4.83547L48.2173 5.13639V0.25H49.292V11.0975H48.2173V9.37794L48.3893 9.53557C48.1218 10.0896 47.7206 10.5195 47.1856 10.8252C46.6602 11.1214 46.0679 11.2694 45.4087 11.2694ZM45.466 10.1947C45.9819 10.1947 46.4452 10.0658 46.856 9.80783C47.2764 9.5499 47.6059 9.20121 47.8448 8.76177C48.0931 8.31278 48.2173 7.81124 48.2173 7.25717C48.2173 6.70309 48.0931 6.20633 47.8448 5.76689C47.6059 5.3179 47.2764 4.96443 46.856 4.7065C46.4452 4.44857 45.9819 4.3196 45.466 4.3196C44.9597 4.3196 44.5012 4.44857 44.0904 4.7065C43.6796 4.96443 43.3548 5.31312 43.116 5.75256C42.8867 6.192 42.7721 6.69354 42.7721 7.25717C42.7721 7.81124 42.8867 8.31278 43.116 8.76177C43.3548 9.20121 43.6749 9.5499 44.0761 9.80783C44.4869 10.0658 44.9502 10.1947 45.466 10.1947Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
			<path d="M57.5184 11.2694C56.8592 11.2694 56.2621 11.1214 55.7272 10.8252C55.2018 10.5195 54.8053 10.0896 54.5378 9.53557L54.7098 9.37794V11.0975H53.6351V0.25H54.7098V5.13639L54.5378 4.83547C54.8435 4.34826 55.2495 3.96136 55.7558 3.67477C56.2717 3.38818 56.8592 3.24488 57.5184 3.24488C58.2444 3.24488 58.8892 3.42161 59.4529 3.77508C60.0261 4.11899 60.475 4.59186 60.7999 5.19371C61.1342 5.79555 61.3014 6.48337 61.3014 7.25717C61.3014 8.02141 61.1342 8.70923 60.7999 9.32063C60.475 9.92247 60.0261 10.4001 59.4529 10.7536C58.8892 11.0975 58.2444 11.2694 57.5184 11.2694ZM57.4611 10.1947C57.9769 10.1947 58.4355 10.0658 58.8367 9.80783C59.2475 9.5499 59.5675 9.20121 59.7968 8.76177C60.0356 8.31278 60.155 7.81124 60.155 7.25717C60.155 6.69354 60.0356 6.192 59.7968 5.75256C59.5675 5.31312 59.2475 4.96443 58.8367 4.7065C58.4355 4.44857 57.9769 4.3196 57.4611 4.3196C56.9452 4.3196 56.4771 4.44857 56.0568 4.7065C55.646 4.96443 55.3164 5.3179 55.068 5.76689C54.8292 6.20633 54.7098 6.70309 54.7098 7.25717C54.7098 7.81124 54.8292 8.31278 55.068 8.76177C55.3164 9.20121 55.646 9.5499 56.0568 9.80783C56.4771 10.0658 56.9452 10.1947 57.4611 10.1947Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
			<path d="M63.0612 14.25C62.9084 14.25 62.7507 14.2357 62.5883 14.207C62.4355 14.1879 62.2826 14.1497 62.1298 14.0924V13.0893C62.2253 13.0989 62.3447 13.1132 62.488 13.1323C62.6409 13.161 62.7937 13.1753 62.9466 13.1753C63.4147 13.1753 63.7729 13.075 64.0213 12.8744C64.2697 12.6833 64.5133 12.3107 64.7521 11.7567L65.2393 10.6103L65.2106 11.556L61.9578 3.41684H63.1185L65.7838 10.1374H65.4829L68.1339 3.41684H69.3232L65.8125 12.0719C65.6596 12.4445 65.4638 12.7979 65.225 13.1323C64.9957 13.4667 64.7043 13.7341 64.3509 13.9348C63.9974 14.1449 63.5675 14.25 63.0612 14.25Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
			</svg>
			<Togggle width="59" height="15" viewBox="0 0 59 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M47.9492 10.9577C47.9492 11.0349 48.0117 11.0975 48.0889 11.0975H49.6849C49.762 11.0975 49.8246 11.0349 49.8246 10.9577V0.389799C49.8246 0.312593 49.762 0.25 49.6849 0.25H48.0889C48.0117 0.25 47.9492 0.312593 47.9492 0.389799V10.9577Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
					<path d="M5.04979 11.1835C5.29658 11.1835 5.60713 11.1515 5.86235 11.1152C5.93038 11.1055 5.98034 11.0468 5.98034 10.978V9.65553C5.98034 9.56985 5.90399 9.50447 5.81896 9.514C5.69054 9.52838 5.57056 9.53557 5.47926 9.53557C4.56308 9.53557 4.16221 9.10568 4.16221 8.31756V4.97876H5.81202C5.88917 4.97876 5.95169 4.91617 5.95169 4.83896V3.442C5.95169 3.36479 5.88917 3.3022 5.81202 3.3022H4.16221V1.66513C4.16221 1.58793 4.09969 1.52533 4.02254 1.52533H2.42652C2.34938 1.52533 2.28685 1.58793 2.28685 1.66513V2.12718C2.28685 2.88664 1.84306 3.3022 1.08433 3.3022H1.08084C1.00369 3.3022 0.941162 3.36479 0.941162 3.442V4.83896C0.941162 4.91617 1.00369 4.97876 1.08084 4.97876H2.28685V8.41786C2.28685 10.1661 3.28895 11.1835 5.04979 11.1835Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
					<path fillRule="evenodd" clipRule="evenodd" d="M6.84783 7.19985C6.84783 4.95227 8.66812 3.13024 10.9135 3.13024H14.9803C17.2257 3.13024 19.046 4.95227 19.046 7.19985C19.046 9.44743 17.2257 11.2694 14.9803 11.2694H10.9135C8.66812 11.2694 6.84783 9.44743 6.84783 7.19985ZM14.9302 9.57847C16.2431 9.57847 17.3074 8.51316 17.3074 7.19903C17.3074 5.88489 16.2431 4.81958 14.9302 4.81958C13.6174 4.81958 12.5531 5.88489 12.5531 7.19903C12.5531 8.51316 13.6174 9.57847 14.9302 9.57847Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
					<path fillRule="evenodd" clipRule="evenodd" d="M27.9476 10.7106C27.9476 12.7597 26.3586 14.25 24.1396 14.25C22.4343 14.25 21.0513 13.4033 20.5106 12.1004C20.4812 12.0297 20.5181 11.9498 20.5897 11.9228L22.0687 11.3646C22.1438 11.3363 22.2267 11.3773 22.2576 11.4514C22.5299 12.1044 23.2158 12.5304 24.1396 12.5304C25.2705 12.5304 26.0722 11.857 26.0722 10.7106V9.86515C25.5282 10.467 24.7552 10.7822 23.8246 10.7822C21.6343 10.7822 20.0453 9.13434 20.0453 6.94191C20.0453 4.74949 21.62 3.13024 23.7817 3.13024C24.7838 3.13024 25.5855 3.48848 26.1724 4.20496V3.442C26.1724 3.36479 26.235 3.3022 26.3121 3.3022H27.808C27.8851 3.3022 27.9476 3.36479 27.9476 3.442V10.7106ZM21.9923 6.95624C21.9923 8.15993 22.8512 9.04836 24.0537 9.04836C25.2276 9.04836 26.0722 8.17426 26.0722 6.95624C26.0722 5.73823 25.2276 4.84979 24.0537 4.84979C22.8655 4.84979 21.9923 5.75256 21.9923 6.95624Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
					<path fillRule="evenodd" clipRule="evenodd" d="M33.3208 14.25C35.5397 14.25 37.1288 12.7597 37.1288 10.7106V3.442C37.1288 3.36479 37.0662 3.3022 36.9891 3.3022H35.4933C35.4161 3.3022 35.3536 3.36479 35.3536 3.442V4.20496C34.7667 3.48848 33.965 3.13024 32.9629 3.13024C30.8012 3.13024 29.2264 4.74949 29.2264 6.94191C29.2264 9.13434 30.8155 10.7822 33.0058 10.7822C33.9364 10.7822 34.7094 10.467 35.2534 9.86515V10.7106C35.2534 11.857 34.4517 12.5304 33.3208 12.5304C32.397 12.5304 31.7111 12.1044 31.4387 11.4514C31.4078 11.3773 31.3249 11.3363 31.2498 11.3646L29.7709 11.9228C29.6993 11.9498 29.6624 12.0297 29.6918 12.1004C30.2325 13.4033 31.6155 14.25 33.3208 14.25ZM33.2349 9.04836C32.0324 9.04836 31.1734 8.15993 31.1734 6.95624C31.1734 5.75256 32.0467 4.84979 33.2349 4.84979C34.4088 4.84979 35.2534 5.73823 35.2534 6.95624C35.2534 8.17426 34.4088 9.04836 33.2349 9.04836Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
					<path fillRule="evenodd" clipRule="evenodd" d="M46.3219 10.7106C46.3219 12.7597 44.7328 14.25 42.5138 14.25C40.8086 14.25 39.4256 13.4033 38.8848 12.1004C38.8555 12.0297 38.8923 11.9498 38.9639 11.9228L40.4429 11.3646C40.518 11.3363 40.6009 11.3773 40.6318 11.4514C40.9042 12.1044 41.5901 12.5304 42.5138 12.5304C43.6448 12.5304 44.4465 11.857 44.4465 10.7106V9.86515C43.9025 10.467 43.1294 10.7822 42.1989 10.7822C40.0086 10.7822 38.4195 9.13434 38.4195 6.94191C38.4195 4.74949 39.9943 3.13024 42.156 3.13024C43.1581 3.13024 43.9598 3.48848 44.5467 4.20496V3.442C44.5467 3.36479 44.6092 3.3022 44.6864 3.3022H46.1822C46.2593 3.3022 46.3219 3.36479 46.3219 3.442V10.7106ZM40.3665 6.95624C40.3665 8.15993 41.2254 9.04836 42.428 9.04836C43.6019 9.04836 44.4465 8.17426 44.4465 6.95624C44.4465 5.73823 43.6019 4.84979 42.428 4.84979C41.2398 4.84979 40.3665 5.75256 40.3665 6.95624Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
					<path fillRule="evenodd" clipRule="evenodd" d="M58.3618 9.48398C57.8511 10.5375 56.6122 11.2695 55.0808 11.2695C52.6901 11.2695 51.0724 9.46392 51.0724 7.18552C51.0724 4.8498 52.7473 3.13025 54.9377 3.13025C57.357 3.13025 58.7313 4.86413 58.7313 6.94192C58.7313 7.19745 58.7086 7.45298 58.6529 7.65773C58.6411 7.70128 58.6007 7.73004 58.5556 7.73004H53.0193C53.1339 8.87641 53.9642 9.62155 55.0951 9.62155C55.8996 9.62155 56.4781 9.26956 56.8305 8.73043C56.8709 8.66868 56.9504 8.64379 57.0166 8.67628L58.2978 9.30539C58.3643 9.33806 58.3942 9.41725 58.3618 9.48398ZM56.7271 6.29709C56.6412 5.337 55.9254 4.67784 54.9377 4.67784C53.9928 4.67784 53.277 5.26535 53.0622 6.29709H56.7271Z" fill={light ? "#fff" : "#050B14"} fillOpacity="0.6"/>
			</Togggle>
		</Footer>
	)
}
