import { useMemo, useState } from 'react'
import { Avatar } from '@mui/material'
import { useTheme } from 'styled-components'
import AssignmentIcon from '@mui/icons-material/Assignment'
import { useParams } from 'react-router-dom'
import { VerifiedFooter } from '../../Components/Shared/VerifiedFooter/VerifiedFooter'
import { Loading } from '../../Components/Loading'
import { ErrorAlert } from '../../Components/Shared/ErrorAlert'
import { useSlugDetails } from '../../hooks/useSlugDetails'
import { useShareAccessGrant } from '../../hooks/useShareAccessGrant'
import { useSession } from '../../hooks/useSession'
import {
	ActionContainer,
	ApproveButton,
	Card,
	CardBody,
	Content,
	DenyButton,
	Text,
	ErrorContainer
} from './ApproveRequest.styled'
import { Result } from '../../Components/Shared/Result/Result'
import {
	AS,
	Arrangment,
	ButtonVariant
} from '../../Components/Shared/Result/types'
import { ReactComponent as CircleWarnning } from '../../assets/svgs/warnning-circle.svg'
import { useI18n } from '../../hooks/useI18n'

export const ApproveRequest = () => {
	const { t } = useI18n()
	const theme = useTheme()
	const { company_id } = useParams()
	const [accessStatus, setAccessStatus] = useState<string|null>(null)
	const { getSessionItem: getSession } = useSession()
	const access_grant = getSession('TOGGGLE_ACCESS_GRANT')

	//if (!user?.kyc?.isCompleted) navigate('/select-country-and-document-type')

	const {
		data: requestDetailsData,
		isLoading: requestDetailsLoading,
		error: requestDetailsError,
	} = useSlugDetails()

	const {
		shareAccessGrant,
		loading: shareAcessGrantMutationLoading,
		error: shareAccessGrantError,
		data: shareAccessData
	} = useShareAccessGrant()

	let loading = useMemo(() => {
		return shareAcessGrantMutationLoading ||
			requestDetailsLoading
		
	}, [
		shareAcessGrantMutationLoading,
		requestDetailsLoading
	])

	const handleRequest = (status: boolean) => {
		if (company_id && access_grant) {
			shareAccessGrant({
				status: status
			})
			setAccessStatus(status ? "granted" : 'denied')
		}
	}


	const disable = useMemo(() => {
		return (
			shareAccessData !== undefined ||
			requestDetailsError !== null
		)
	}, [shareAccessData, requestDetailsError])

	// const buttonCallback = () => {
	// 	deleteItem('TOGGGLE_REFERER_ID')
	// 	navigate('/')
	// }

	// const button = {
	// 	text: t('close'),
	// 	variant: 'contained' as ButtonVariant,
	// 	as: 'button' as AS,
	// 	arrangment: 'column' as Arrangment,
	// 	callback: buttonCallback
	// }

	const showContent = useMemo(() => {
		return (
			shareAccessData === undefined &&
			!loading
		)
	}, [shareAccessData, loading])

	const externalLinkCB = () => {
		if (window && requestDetailsData) {
			let re = new RegExp(
				/(?:https|http?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/
			)
			let link = re.test(requestDetailsData?.company.website)
				? requestDetailsData?.company.website
				: `https://${requestDetailsData?.company.website}`
			window.location.replace(link)
		}
	}

	let SuccessButton = undefined
	if (requestDetailsData) {
		SuccessButton = {
			text: `Go to ${requestDetailsData?.company.name}`,
			variant: 'contained' as ButtonVariant,
			as: 'button' as AS,
			arrangment: 'column' as Arrangment,
			callback: externalLinkCB
		}
	}
	

	return (
		<>
			{requestDetailsData && !loading && typeof shareAccessData === 'string' && accessStatus === 'granted' ? (
				<Result
					backgroundTransparent={true}
					hideFooter={false}
					button={SuccessButton}
					header={t('accessGranted') ?? ''}
					subtext={
						t('approvedRequest_granted', {
							companyName:
								requestDetailsData?.company?.name
						}) ?? ''
					}
				/>
			) : null}
			{requestDetailsData && typeof shareAccessData === 'string' && !loading && accessStatus === 'denied'? (
				<Result
					Icon={CircleWarnning}
					backgroundTransparent={true}
					hideFooter={false}
					button={SuccessButton}
					header={t('accessDenied') ?? ''}
					subtext={
						t('approvedRequest_denied', {
							companyName:
								requestDetailsData?.company.name
						}) ?? ''
					}
				/>
			) : null}
			{shareAccessGrantError ? (
				<ErrorContainer>
					<ErrorAlert
						message={
							shareAccessGrantError.errors
								? Object.values(shareAccessGrantError?.errors)[0]
								: ''
						}
						severity={'error'}
					/>
				</ErrorContainer>
			) : null}

			{/* {requestDetailsData?.requestDetail?.isApproved ? (
				<ErrorContainer>
					<ErrorAlert
						message={
							t('approvedRequest_approved', {
								companyName:
									requestDetailsData?.requestDetail
										?.companyName
							}) ?? ''
						}
						severity={'info'}
					/>
				</ErrorContainer>
			) : null} */}
			{requestDetailsError ? (
				<ErrorContainer>
					<ErrorAlert
						message={
							requestDetailsError.errors
								? Object.values(requestDetailsError?.errors)[0]
								: ''
						}
						severity={'error'}
					/>
				</ErrorContainer>
			) : null}
			{loading ? <Loading /> : null}
			{showContent ? (
				<Card>
					<CardBody>
						<Content>
								<Avatar variant="rounded">
									<AssignmentIcon />
								</Avatar>
							
							<Text variant="body1">
								<strong>
									{
										requestDetailsData?.company
											?.name
									}
								</strong>{' '}
								{t('approvedRequest_line1')}
							</Text>
							<Text
								sx={{ color: theme.typography.body2 }}
								variant="body2"
							>
								{t('approvedRequest_line2')}
							</Text>
							<Text
								sx={{ color: theme.typography.body2 }}
								variant="body2"
							>
								{t('approvedRequest_line3')}
							</Text>
							<ActionContainer>
								<ApproveButton
									onClick={()=> handleRequest(true)}
								>
									{t('approve')}
								</ApproveButton>
								<DenyButton
									color="error"
									variant="outlined"
									disabled={disable}
									onClick={() => handleRequest(false)}
								>
									{t('deny')}
								</DenyButton>
							</ActionContainer>
						</Content>
					</CardBody>
					<VerifiedFooter />
				</Card>
			) : null}
		</>
	)
}
