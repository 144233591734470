import { Fragment } from 'react'
import { Loading } from './Components/Loading'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from './GlobalStyles'
import { router } from './Router'
import { NetworkRequestClientProvider, QueryProvider } from './services'
import { theme } from './Theme'
import { Provider } from 'react-redux'
import { store, persistor } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { WsProvider } from './Context/WebsocketProvider'

function App() {
	return (
		<Fragment>
			<GlobalStyle />
			<ThemeProvider theme={theme}>
				<NetworkRequestClientProvider>
					<QueryProvider>
						<Provider store={store}>
							<PersistGate
								loading={<Loading />}
								persistor={persistor}
							>
								<WsProvider>
									<RouterProvider router={router} />
								</WsProvider>
							</PersistGate>
						</Provider>
					</QueryProvider>
				</NetworkRequestClientProvider>
			</ThemeProvider>
		</Fragment>
	)
}

export default App
