import styled from 'styled-components'

export const MobileScanner = ()=>{
  return <SVG width="144" height="153" viewBox="0 0 144 153" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M96.6867 24.877L44.4442 24.6302C41.2347 24.6151 38.6205 27.2046 38.6054 30.4142L38.174 121.73C38.1588 124.94 40.7484 127.554 43.9579 127.569L96.2004 127.816C99.41 127.831 102.024 125.241 102.039 122.032L102.471 30.7159C102.486 27.5063 99.8963 24.8922 96.6867 24.877Z" fill="url(#paint0_linear_1111_33407)" fillOpacity="0.4"/>
<path d="M99.2102 24.8878L46.9677 24.641C43.7581 24.6258 41.144 27.2154 41.1288 30.4249L40.6974 121.741C40.6823 124.95 43.2718 127.565 46.4814 127.58L98.7239 127.827C101.933 127.842 104.548 125.252 104.563 122.043L104.994 30.7266C105.009 27.5171 102.42 24.9029 99.2102 24.8878Z" fill="#1066FF"/>
<path d="M96.5535 27.2951L49.6027 27.0734C46.3931 27.0582 43.779 29.6478 43.7638 32.8573L43.364 117.489C43.3489 120.698 45.9384 123.313 49.148 123.328L96.0988 123.55C99.3083 123.565 101.922 120.975 101.938 117.766L102.337 33.134C102.353 29.9244 99.763 27.3103 96.5535 27.2951Z" fill="#FAF5F0"/>
<path d="M64.059 34.8969L53.1991 34.8456C52.7402 34.8434 52.2992 35.0236 51.9732 35.3466C51.6472 35.6695 51.4629 36.1087 51.4607 36.5676L51.4094 47.4284C51.4083 47.6579 51.4984 47.8784 51.6599 48.0415C51.8215 48.2045 52.0411 48.2967 52.2706 48.2978C52.5 48.2988 52.7205 48.2086 52.8834 48.0471C53.0464 47.8856 53.1385 47.666 53.1396 47.4366L53.1827 38.3069C53.1849 37.8481 53.3692 37.409 53.6951 37.0861C54.021 36.7633 54.4619 36.5831 54.9206 36.5853L64.0504 36.6284C64.1642 36.6292 64.2771 36.6075 64.3826 36.5645C64.488 36.5216 64.584 36.4583 64.6649 36.3782C64.7458 36.2981 64.8102 36.2028 64.8542 36.0978C64.8982 35.9928 64.9211 35.8802 64.9216 35.7663C64.9221 35.6525 64.9002 35.5398 64.8571 35.4345C64.814 35.3292 64.7506 35.2335 64.6705 35.1527C64.5904 35.072 64.4952 35.0078 64.3902 34.9639C64.2853 34.92 64.1727 34.8972 64.059 34.8969Z" fill="#27277A"/>
<path d="M91.5696 35.0268L80.7092 34.9755C80.5956 34.975 80.483 34.9968 80.3778 35.0398C80.2727 35.0828 80.177 35.1461 80.0963 35.2261C80.0156 35.3061 79.9515 35.4012 79.9075 35.506C79.8636 35.6107 79.8408 35.7231 79.8403 35.8368C79.8392 36.0661 79.9293 36.2865 80.0907 36.4495C80.2521 36.6125 80.4717 36.7046 80.7011 36.7057L89.8308 36.7488C90.0581 36.7499 90.283 36.7957 90.4926 36.8836C90.7022 36.9716 90.8925 37.1 91.0524 37.2615C91.2124 37.4231 91.3389 37.6145 91.4249 37.825C91.5108 38.0354 91.5544 38.2608 91.5532 38.4881L91.5101 47.6178C91.5095 47.7314 91.5313 47.8441 91.5743 47.9492C91.6172 48.0544 91.6804 48.1501 91.7604 48.2309C91.8403 48.3116 91.9354 48.3758 92.0401 48.4198C92.1449 48.4638 92.2573 48.4867 92.3709 48.4872C92.6003 48.4882 92.8207 48.398 92.9837 48.2365C93.1466 48.075 93.2388 47.8554 93.2399 47.626L93.2912 36.7652C93.2923 36.538 93.2487 36.3128 93.1628 36.1025C93.0768 35.8922 92.9503 35.7008 92.7905 35.5394C92.6306 35.378 92.4405 35.2496 92.231 35.1617C92.0215 35.0737 91.7968 35.0279 91.5696 35.0268Z" fill="#27277A"/>
<path d="M63.6654 79.5858L54.5357 79.5427C54.3085 79.5416 54.0837 79.4959 53.8742 79.408C53.6647 79.3201 53.4746 79.1918 53.3146 79.0304C53.1547 78.869 53.0282 78.6777 52.9422 78.4674C52.8563 78.2571 52.8126 78.0319 52.8136 77.8047L52.8568 68.675C52.8574 68.5614 52.8356 68.4488 52.7926 68.3436C52.7497 68.2384 52.6864 68.1427 52.6065 68.062C52.5266 67.9812 52.4315 67.917 52.3267 67.873C52.222 67.8291 52.1096 67.8061 51.996 67.8056C51.8824 67.8051 51.7698 67.8269 51.6646 67.8699C51.5594 67.9129 51.4638 67.9762 51.3831 68.0562C51.3024 68.1362 51.2382 68.2313 51.1943 68.336C51.1504 68.4408 51.1275 68.5532 51.127 68.6668L51.0757 79.5268C51.0746 79.7539 51.1182 79.9791 51.2041 80.1895C51.29 80.3998 51.4165 80.5911 51.5764 80.7525C51.7363 80.914 51.9264 81.0423 52.1359 81.1303C52.3453 81.2182 52.5701 81.264 52.7973 81.2651L63.6572 81.3164C63.8833 81.3124 64.0988 81.22 64.2576 81.059C64.4164 80.8981 64.5059 80.6814 64.507 80.4553C64.5081 80.2292 64.4206 80.0117 64.2633 79.8493C64.106 79.6868 63.8914 79.5924 63.6654 79.5862L63.6654 79.5858Z" fill="#27277A"/>
<path d="M92.2822 67.9954C92.1685 67.9949 92.0559 68.0167 91.9508 68.0597C91.8456 68.1027 91.7499 68.166 91.6692 68.246C91.5885 68.326 91.5244 68.4211 91.4805 68.5259C91.4365 68.6306 91.4137 68.743 91.4132 68.8567L91.3701 77.9864C91.3678 78.4452 91.1834 78.8843 90.8574 79.2071C90.5314 79.53 90.0905 79.7101 89.6317 79.708L80.502 79.6648C80.2759 79.6689 80.0604 79.7613 79.9016 79.9222C79.7428 80.0831 79.6533 80.2998 79.6522 80.5259C79.6511 80.752 79.7386 80.9696 79.8959 81.132C80.0532 81.2944 80.2678 81.3888 80.4938 81.395L91.3542 81.4463C91.5814 81.4474 91.8065 81.4037 92.0168 81.3177C92.2271 81.2318 92.4184 81.1052 92.5798 80.9453C92.7412 80.7854 92.8695 80.5953 92.9574 80.3858C93.0453 80.1763 93.0911 79.9515 93.0921 79.7243L93.1434 68.8648C93.1445 68.6353 93.0543 68.4148 92.8928 68.2518C92.7313 68.0887 92.5117 67.9965 92.2822 67.9954Z" fill="#27277A"/>
<path d="M72.5635 77.304C82.8656 77.3526 91.2566 69.0406 91.3052 58.7385C91.3539 48.4364 83.0419 40.0455 72.7398 39.9968C62.4377 39.9481 54.0467 48.2602 53.9981 58.5623C53.9494 68.8644 62.2614 77.2553 72.5635 77.304Z" fill="white"/>
<path d="M72.6109 74.7703C81.4663 74.8121 88.6789 67.6673 88.7207 58.812C88.7625 49.9566 81.6178 42.744 72.7624 42.7022C63.9071 42.6604 56.6945 49.8051 56.6527 58.6605C56.6108 67.5158 63.7556 74.7284 72.6109 74.7703Z" fill="#1066FF"/>
<path d="M71.1447 40.6527L71.1448 40.6527L71.1645 40.6546L71.1839 40.6504C77.5258 39.285 82.5285 42.5748 85.2833 45.3911C85.9552 46.0779 86.4191 47.0458 86.7408 48.1757C87.0622 49.3048 87.239 50.5861 87.3429 51.8909C87.4427 53.1443 87.475 54.4147 87.5048 55.5882C87.506 55.6361 87.5072 55.6839 87.5084 55.7315C87.5393 56.9418 87.5703 58.0465 87.676 58.9051C87.8817 60.5763 87.3296 62.7861 86.7162 64.5933C86.4107 65.4937 86.0919 66.2884 85.8496 66.8581C85.7284 67.1428 85.6264 67.3712 85.5548 67.5282C85.519 67.6067 85.4908 67.6673 85.4715 67.7083L85.454 67.7455C85.4514 67.7476 85.4485 67.7499 85.4453 67.7524C85.4255 67.7684 85.3949 67.7921 85.3535 67.8222C85.2707 67.8824 85.1448 67.968 84.9746 68.067C84.6342 68.2651 84.117 68.5173 83.4147 68.7287C82.0112 69.1514 79.8638 69.413 76.9062 68.7496C73.9289 68.0818 71.1735 67.3241 68.9639 66.1369C66.7578 64.9516 65.1033 63.3423 64.3143 60.9715C63.5177 58.5776 63.2952 56.279 63.2723 54.5779C63.2608 53.7277 63.2991 53.0275 63.3403 52.5404C63.3609 52.2969 63.3822 52.1067 63.3982 51.9777C63.4063 51.9132 63.413 51.864 63.4177 51.8312L63.4231 51.7942L63.4245 51.7851L63.4249 51.7829L63.425 51.7824L63.425 51.7824L63.425 51.7823L63.4359 51.7144L63.3851 51.6681L63.385 51.6681L63.385 51.668L63.3849 51.668L63.3839 51.667L63.379 51.6624C63.3744 51.658 63.3673 51.6512 63.358 51.642C63.3394 51.6235 63.3119 51.5953 63.2773 51.5574C63.208 51.4816 63.1107 51.3674 63.0008 51.2157C62.7808 50.9122 62.5108 50.4595 62.313 49.8651C61.9186 48.6803 61.8066 46.9193 62.9768 44.6348C64.1396 42.365 66.1735 41.3682 67.9297 40.9392C68.8075 40.7248 69.6127 40.6531 70.1986 40.6352C70.4913 40.6262 70.7289 40.6307 70.8928 40.6374C70.9748 40.6407 71.0383 40.6446 71.081 40.6476C71.1024 40.6491 71.1186 40.6504 71.1293 40.6513L71.1412 40.6523L71.1441 40.6526L71.1447 40.6527L71.1447 40.6527Z" fill="#D0DCF0" stroke="#031131" strokeWidth="0.254801"/>
<path d="M62.0127 48.4203C62.0099 48.3623 62.0082 48.3303 62.0082 48.3303C62.0073 48.3604 62.0089 48.3905 62.0127 48.4203Z" fill="#27277A"/>
<path d="M83.9639 57.8023L83.9639 57.8024C84.6003 61.5205 86.3966 63.3877 87.8946 64.5753C88.2 64.8174 88.4934 65.0315 88.7599 65.2261C88.8186 65.2689 88.8759 65.3108 88.9318 65.3518C89.2437 65.5806 89.5076 65.7808 89.708 65.9737C89.9088 66.1672 90.0343 66.3426 90.0838 66.5184C90.1318 66.6883 90.1133 66.875 89.9906 67.1039C89.7377 67.5759 89.1035 68.1145 88.2343 68.6551C87.3713 69.1918 86.2974 69.7182 85.1915 70.1721C84.0859 70.6259 82.9517 71.0059 81.9694 71.2511C80.9806 71.4978 80.1649 71.6028 79.6858 71.5225C78.6797 71.3537 75.4191 70.5558 72.2091 69.4342C70.6046 68.8735 69.0171 68.2335 67.7324 67.5529C67.09 67.2126 66.5259 66.8635 66.0741 66.5107C65.6211 66.157 65.2877 65.8049 65.0986 65.4605C64.7453 64.8174 64.4303 64.2898 64.1538 63.8268C64.1149 63.7616 64.0767 63.6976 64.0393 63.6348C63.7357 63.1253 63.4838 62.6927 63.2792 62.255C62.8727 61.3856 62.6486 60.4881 62.601 58.9308C62.5234 56.3931 62.2655 51.0342 62.1702 49.0683C62.292 49.212 62.4643 49.3555 62.7077 49.4772C63.3597 49.8032 64.5064 49.9644 66.5314 49.5856L66.5315 49.5856C68.8657 49.1481 71.261 47.9056 73.0484 46.794C73.9434 46.2373 74.6887 45.7118 75.2011 45.3329C75.4382 45.1576 75.6255 45.0136 75.7548 44.9125C75.7705 44.9804 75.7936 45.0578 75.8246 45.1427C75.9208 45.4065 76.0972 45.756 76.381 46.1454C76.9495 46.9252 77.9511 47.8674 79.6116 48.6044C80.4316 48.9684 81.027 49.2661 81.4841 49.6204C81.9369 49.9713 82.2571 50.3803 82.5188 50.9731C82.7824 51.5702 82.9867 52.3537 83.204 53.4545C83.3693 54.2919 83.541 55.3068 83.7522 56.5557C83.8185 56.9476 83.8887 57.3626 83.9639 57.8023Z" fill="#D0DCF0" stroke="#031131" strokeWidth="0.254801"/>
<path d="M70.5016 61.7515C70.5016 61.7515 70.3225 66.8724 70.2693 67.1803C70.2162 67.4882 59.242 69.7657 59.242 69.7657C59.242 69.7657 70.6352 77.3114 70.8929 77.4453C71.1506 77.5792 80.8787 76.4356 81.0853 76.4366C81.292 76.4376 86.4694 71.2388 86.4694 71.2388L87.5324 69.0807L76.6815 67.2106L76.5993 61.2236L70.5016 61.7515Z" fill="#F3F7FF"/>
<path d="M76.9245 59.8692L68.4264 59.0608L69.4536 64.9533C69.4536 64.9533 73.4737 67.8169 77.4179 64.8876C78.1136 59.8748 76.9245 59.8692 76.9245 59.8692Z" fill="#FAF5F0"/>
<path d="M81.9522 56.5698C82.4005 55.5018 81.8982 54.2726 80.8302 53.8243C79.7622 53.3761 78.5331 53.8784 78.0848 54.9464C77.6365 56.0144 78.1388 57.2435 79.2068 57.6918C80.2748 58.1401 81.504 57.6378 81.9522 56.5698Z" fill="#FAF5F0"/>
<path d="M68.7204 56.8174C69.1687 55.7494 68.6664 54.5202 67.5984 54.0719C66.5304 53.6236 65.3013 54.126 64.853 55.194C64.4047 56.2619 64.907 57.4911 65.975 57.9394C67.043 58.3877 68.2721 57.8853 68.7204 56.8174Z" fill="#FAF5F0"/>
<path d="M66.0154 51.5991C66.0154 51.5991 72.937 48.6292 75.3335 46.0859C77.5097 48.5853 80.6353 51.1168 80.6353 51.1168C80.6353 51.1168 80.3663 56.9761 79.3549 59.419C78.3435 61.8619 75.9452 64.1433 73.6698 64.1326C71.3945 64.1218 68.7579 62.1618 67.5294 59.4669C66.3009 56.772 66.0154 51.5991 66.0154 51.5991Z" fill="white"/>
<g opacity="0.57">
<path d="M79.5368 59.3513C80.4864 59.3557 81.261 58.3465 81.2669 57.097C81.2728 55.8475 80.5078 54.831 79.5581 54.8265C78.6085 54.822 77.8338 55.8313 77.8279 57.0807C77.822 58.3302 78.5871 59.3468 79.5368 59.3513Z" fill="#FFCCE9"/>
</g>
<g opacity="0.57">
<path d="M67.0803 59.8979C68.03 59.9024 68.8046 58.8931 68.8105 57.6436C68.8164 56.3941 68.0514 55.3776 67.1017 55.3731C66.1521 55.3686 65.3774 56.3779 65.3715 57.6274C65.3656 58.8769 66.1307 59.8934 67.0803 59.8979Z" fill="#FFCCE9"/>
</g>
<path d="M70.0412 54.212C70.4982 54.2141 70.8711 53.7007 70.8741 53.0651C70.8771 52.4295 70.5091 51.9126 70.0521 51.9104C69.5951 51.9082 69.2222 52.4217 69.2192 53.0573C69.2162 53.6928 69.5842 54.2098 70.0412 54.212Z" fill="#031131"/>
<path d="M76.7374 54.2427C77.1944 54.2449 77.5673 53.7314 77.5703 53.0959C77.5733 52.4603 77.2052 51.9433 76.7482 51.9412C76.2912 51.939 75.9183 52.4525 75.9153 53.088C75.9123 53.7236 76.2804 54.2406 76.7374 54.2427Z" fill="#031131"/>
<path d="M73.421 57.9771L73.4435 53.2194C73.4435 53.2194 72.0005 55.7725 71.9969 56.5221C71.9934 57.2716 73.421 57.9771 73.421 57.9771Z" fill="#27277A"/>
<path d="M75.106 59.1338C73.4038 60.7495 71.8078 59.1877 71.7405 59.1205C71.703 59.0828 71.6521 59.0615 71.599 59.0613C71.5458 59.0611 71.4947 59.082 71.457 59.1194C71.4193 59.1569 71.3979 59.2078 71.3977 59.2609C71.3975 59.3141 71.4184 59.3651 71.4559 59.4029C71.7995 59.7265 72.2083 59.9731 72.6549 60.126C72.9155 60.2145 73.1884 60.2608 73.4635 60.2635C74.0331 60.2661 74.7021 60.0685 75.3803 59.4263C75.4002 59.4084 75.4164 59.3867 75.4278 59.3624C75.4392 59.3382 75.4456 59.3119 75.4467 59.2851C75.4477 59.2583 75.4434 59.2316 75.434 59.2065C75.4245 59.1814 75.4101 59.1585 75.3917 59.1391C75.3732 59.1197 75.351 59.1041 75.3264 59.0934C75.3019 59.0827 75.2754 59.0771 75.2486 59.0768C75.2218 59.0765 75.1952 59.0816 75.1704 59.0918C75.1456 59.1019 75.1231 59.117 75.1042 59.136L75.106 59.1338Z" fill="#031131"/>
<path d="M75.3986 46.5413L75.3053 46.4277L75.2062 46.5362C73.0563 48.8864 70.7098 50.3879 68.9007 51.3018C67.9963 51.7587 67.2267 52.0684 66.6838 52.2638C66.4124 52.3615 66.1978 52.4305 66.0514 52.475C66.0155 52.486 65.9837 52.4954 65.9562 52.5034L65.3295 49.5958L75.6512 44.0593L81.3953 50.8435C81.3379 50.8167 81.2763 50.7876 81.2107 50.7563C80.7803 50.5504 80.1822 50.2464 79.5118 49.8547C78.1691 49.0699 76.5459 47.9377 75.3986 46.5413Z" fill="#F3F7FF" stroke="#031131" strokeWidth="0.254801"/>
<path d="M64.7155 34.8998L53.8556 34.8485C53.3967 34.8463 52.9557 35.0265 52.6297 35.3495C52.3037 35.6724 52.1194 36.1116 52.1172 36.5705L52.0659 47.4313C52.0648 47.6607 52.1549 47.8812 52.3163 48.0442C52.4777 48.2073 52.6973 48.2995 52.9267 48.3007C53.1562 48.3018 53.3767 48.2117 53.5397 48.0502C53.7028 47.8887 53.795 47.669 53.7961 47.4395L53.8392 38.3098C53.8414 37.851 54.0257 37.4119 54.3516 37.0891C54.6775 36.7662 55.1184 36.586 55.5771 36.5882L64.7069 36.6313C64.9362 36.6324 65.1566 36.5423 65.3196 36.3809C65.4826 36.2195 65.5747 35.9999 65.5758 35.7705C65.5766 35.6569 65.5549 35.5441 65.5121 35.4388C65.4693 35.3335 65.4061 35.2377 65.3262 35.1568C65.2463 35.0759 65.1512 35.0116 65.0464 34.9675C64.9416 34.9234 64.8292 34.9004 64.7155 34.8998Z" fill="#1066FF"/>
<path d="M92.2324 35.0298L81.3725 34.9785C81.143 34.9774 80.9225 35.0675 80.7595 35.229C80.5964 35.3905 80.5042 35.6102 80.5031 35.8397C80.5021 36.0691 80.5923 36.2895 80.7538 36.4525C80.9153 36.6154 81.1349 36.7076 81.3644 36.7086L90.4941 36.7518C90.7214 36.7528 90.9463 36.7986 91.1558 36.8866C91.3654 36.9745 91.5556 37.103 91.7156 37.2645C91.8755 37.426 92.002 37.6175 92.0879 37.828C92.1738 38.0384 92.2173 38.2637 92.2161 38.491L92.173 47.6208C92.1719 47.8502 92.262 48.0706 92.4234 48.2337C92.5848 48.3967 92.8043 48.489 93.0338 48.4902C93.263 48.4909 93.4832 48.4006 93.646 48.2391C93.8087 48.0777 93.9007 47.8582 93.9018 47.6289L93.9531 36.7681C93.9553 36.3095 93.7753 35.8687 93.4526 35.5428C93.13 35.2168 92.6911 35.0323 92.2324 35.0298Z" fill="#1066FF"/>
<path d="M64.3287 79.5892L55.199 79.5461C54.7402 79.5439 54.3011 79.3596 53.9783 79.0337C53.6554 78.7077 53.4752 78.2669 53.4774 77.8082L53.5205 68.6784C53.5216 68.449 53.4316 68.2285 53.2701 68.0655C53.1087 67.9025 52.8892 67.8102 52.6598 67.809C52.4303 67.8079 52.2097 67.8981 52.0467 68.0596C51.8836 68.2211 51.7914 68.4408 51.7903 68.6703L51.739 79.5302C51.7369 79.989 51.9171 80.43 52.24 80.756C52.563 81.082 53.0022 81.2664 53.4611 81.2685L64.3214 81.3198C64.5475 81.3158 64.763 81.2234 64.9218 81.0625C65.0806 80.9015 65.1701 80.6848 65.1712 80.4587C65.1723 80.2326 65.0848 80.0151 64.9275 79.8527C64.7702 79.6903 64.5556 79.5958 64.3296 79.5896L64.3287 79.5892Z" fill="#1066FF"/>
<path d="M92.9411 67.9993C92.7117 67.9983 92.4913 68.0885 92.3283 68.25C92.1654 68.4115 92.0732 68.6311 92.0721 68.8605L92.029 77.9902C92.0268 78.449 91.8425 78.8881 91.5166 79.2109C91.1907 79.5338 90.7498 79.714 90.2911 79.7118L81.1614 79.6687C81.0461 79.6655 80.9314 79.6854 80.8239 79.7272C80.7165 79.769 80.6185 79.8318 80.5357 79.912C80.4528 79.9922 80.3869 80.0882 80.3417 80.1942C80.2964 80.3003 80.2729 80.4143 80.2723 80.5296C80.2718 80.6449 80.2943 80.7591 80.3385 80.8656C80.3827 80.9721 80.4478 81.0686 80.5298 81.1496C80.6119 81.2306 80.7093 81.2943 80.8163 81.3371C80.9234 81.38 81.0379 81.4009 81.1532 81.3989L92.0131 81.4502C92.472 81.4523 92.9129 81.2721 93.2389 80.9492C93.5649 80.6262 93.7493 80.187 93.7515 79.7281L93.8028 68.8687C93.8033 68.755 93.7814 68.6423 93.7384 68.5371C93.6954 68.4319 93.6321 68.3362 93.552 68.2554C93.472 68.1747 93.3769 68.1105 93.2721 68.0666C93.1672 68.0226 93.0548 67.9997 92.9411 67.9993Z" fill="#1066FF"/>
<path d="M87.8292 94.6494L52.7007 94.4834C49.9877 94.4706 47.7781 96.6595 47.7652 99.3724L47.7619 100.075C47.7491 102.788 49.938 104.998 52.651 105.011L87.7795 105.177C90.4924 105.189 92.7021 103.001 92.7149 100.288L92.7182 99.5848C92.731 96.8718 90.5421 94.6622 87.8292 94.6494Z" fill="#27277A"/>
<path d="M89.2787 94.0539L54.1502 93.888C51.4372 93.8751 49.2275 96.064 49.2147 98.777L49.2114 99.4797C49.1986 102.193 51.3875 104.402 54.1004 104.415L89.2289 104.581C91.9419 104.594 94.1516 102.405 94.1644 99.6921L94.1677 98.9893C94.1805 96.2764 91.9916 94.0667 89.2787 94.0539Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_1111_33407" x1="98.7502" y1="76.3573" x2="38.3897" y2="76.0722" gradientUnits="userSpaceOnUse">
<stop stopColor="#1066FF"/>
<stop offset="1" stopColor="#1066FF" stopOpacity="0.54"/>
</linearGradient>
</defs>
</SVG>

}

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  display: block;
`
