import { Loading } from '../../Components/Loading'
import { Veridas } from '../../pages/Veridas'
import { useCompleteKYC } from '../../hooks/useCompleteKYC'
import { Fragment, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSession } from '../../hooks/useSession'

export const NoAuthMobileKYC = () => {
	const navigate = useNavigate()
	const { closeSession } = useSession();
	const { slug } = useParams()
	const { isLoading, error } = useCompleteKYC()
	useEffect(()=>{
		closeSession();
	}, [closeSession])
	
	useEffect(() => {
		if (error) {
			navigate(`/${slug}/kyc-error`)
		}
	}, [error, navigate, slug])
	
	const showWidget = useMemo(() => !isLoading, [isLoading])

	return (
		<Fragment>
			{isLoading ? <Loading /> : null}
			{showWidget ? <Veridas qrKYC={true} /> : null}
		</Fragment>
	)
}
