import { DialogActions, DialogContent, DialogContentText } from "@mui/material"
import { FC, useState } from "react";
import { FormButton } from "../Shared/Button";
import { useI18n } from "../../hooks/useI18n";
import { IProps } from "./types";
import { CustomDialog, CustomDialogTitle, Text } from "./RestartAgain.styled";
import { ReactComponent as CircleWarning } from '../../assets/svgs/warnning-circle.svg';
import { useTheme } from "styled-components";

export const RestartAgain: FC<IProps> = ({ reason, header,handleError, isKYC })=>{
    const message = 'There was an error processing your KYC. Please try again';
    const { translation } = useI18n();
    const theme = useTheme()
    const [open, setOpen] = useState<boolean>(true);
    const handleClose = ()=>{
        handleError();
        setOpen(open);
    }
    return <CustomDialog maxWidth="xs" fullWidth={true} open={open} keepMounted >
        <CustomDialogTitle>
            <CircleWarning />
            <Text variant="h5">{header ? header : 'Oops!'}</Text>
        </CustomDialogTitle>
        <DialogContent>
            <DialogContentText>
                <Text variant="subtitle1" sx={{ color: theme.typography.body2, fontWeight: '300', fontSize: 16}}>{reason ?  reason : message }</Text>
                </DialogContentText>
        </DialogContent>
        <DialogActions>
            <FormButton onClick={handleClose}>{isKYC ? translation('close') : translation('tryAgain')}</FormButton>
        </DialogActions>
    </CustomDialog>
}