import { FC, useEffect, useMemo } from 'react'
import { Card } from '../../Components/Shared/Card/Card'
import { SubHeader } from '../../Components/Shared/SubHeader/SubHeader'
import { Col, Content, Subtext, TextContent } from './Success.styled'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import { ReactComponent as CircleCheck } from '../../assets/svgs/check-circle.svg'
import { ReactComponent as PhoneIcon } from '../../assets/svgs/phone.svg'
import { IProps } from './types'
import { useI18n } from '../../hooks/useI18n'
import { setVeridasToken } from '../../store/Reducers/Login'

export const Success: FC<IProps> = ({ qrKyc }) => {
	const { translation } = useI18n()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { kycStatus } = useSelector((state: RootState) => state.user)
	useEffect(()=>{
		dispatch(setVeridasToken(null))
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	useEffect(() => {
		if (!kycStatus || !kycStatus?.success) {
			if (qrKyc) {
				navigate('/otp/login')
			} else {
				navigate('/select-country-and-document-type')
			}
		}
	}, [kycStatus, navigate, dispatch, qrKyc])

	const header = useMemo(() => {
		if (qrKyc) {
			return translation('qrKYCSuccessHeader')
		}
		return translation('successDefaultHeader')
	}, [qrKyc, translation])

	const message = useMemo(() => {
		if (qrKyc) {
			return translation('qrKYCSuccessSubtext')
		}
		return translation('KYCSuccessSubtext')
	}, [qrKyc, translation])

	return (
		<>
			<Card fullScreen={true} noBtmRadius>
				<Content>
					<Col>
						{qrKyc ?  <PhoneIcon /> : <CircleCheck />}
						<TextContent>
							<SubHeader text={header} />
							<Subtext>{message}</Subtext>
						</TextContent>
					</Col>
				</Content>
			</Card>
		</>
	)
}
