import { FC, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useSession } from '../../hooks/useSession'
import { RootState } from '../../store'
import { AuthContext } from './AuthContext'
import { logout as logoutAction } from '../../store/Reducers/Login'
import { IProps } from './types'
import {
	redirect,
	useNavigate,
	useLocation,
	useSearchParams
} from 'react-router-dom'
import { unauthenticatedURL } from './utils'

export const AuthProvider: FC<IProps> = ({ children }) => {
	const { user } = useSelector((state: RootState) => state.user)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const [searchParams] = useSearchParams()
	const { closeSession, getSessionItem: getSession } = useSession()
	const auth = getSession('TOGGGLE_AUTH_TOKEN')

	const value = useMemo(() => {
		return {
			user: { ...user },
			isLoggedin: user && auth ? true : false
		}
	}, [user, auth])

	const logout = () => {
		closeSession()
		dispatch(logoutAction())
		redirect('/otp/login')
	}

	useEffect(() => {
		if (value.isLoggedin && unauthenticatedURL.indexOf(pathname) >= 0) {
			
			let companyId = searchParams.get('company_id')
			let path = companyId
				? `/approve-request/${companyId}`
				: user?.kyc?.isCompleted
				? '/'
				: '/select-country-and-document-type'
			navigate(path)
		}
	})
	return (
		<AuthContext.Provider value={{ ...value, logout }}>
			{children}
		</AuthContext.Provider>
	)
}
