import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { IResponse, IError, IVariables } from './types'
import { RootState } from '../../store'
import { useEffect, useState } from 'react'
import {
	useNavigate,
	useLocation,
	useParams,
	useSearchParams,
	createSearchParams
} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateKYCStatus } from '../../store/Reducers/Login'
import { useRequestClientContext } from '../../Context/RequestClientProvider'
import { AxiosResponse, AxiosError } from 'axios'

export const useVeridasAlert = () => {
	const URL = '/kyc/alert'
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { pathname } = useLocation()
	const { slug } = useParams()
	const [searchParams] = useSearchParams()
	const request = useRequestClientContext()
	const [variables, setVariables] = useState<IVariables | null>(null)
	const { veridasAccessToken } = useSelector((state: RootState) => state.user)

	const { mutate, isLoading, data, error } = useMutation<
		IResponse,
		IError,
		IVariables,
		string
	>(
		async (
			variables: Omit<IVariables, 'isValidScore'>
		): Promise<AxiosResponse<IResponse> | AxiosError<IError> | any> => {
			return await request?.post<IResponse, Omit<IVariables, 'isValidScore'>, IError>(
				variables,
				URL
			)
		}
	)

	const handleVeridasAlert = (status: string, isValidScore: boolean) => {
		const validationId = veridasAccessToken?.validation_id
		if (validationId) {
			setVariables({ validationId, status, isValidScore })
			mutate({ validationId, status })
		}
	}

	useEffect(() => {
		let next = ''
		let email = searchParams.get('email')
		if (slug && slug.length > 0) {
			if (error || (data && !variables?.isValidScore)) {
				dispatch(updateKYCStatus({ error: true, success: false }))
				next =
					pathname === `/${slug}/mobile/kyc`
						? `/${slug}/mobile/error`
						: `/${slug}/error`
			}
			if (data && variables?.isValidScore) {
				if (pathname === `/${slug}/mobile/kyc`) {
					dispatch(updateKYCStatus({ error: false, success: true }))
				}
				next =
					pathname === `/${slug}/mobile/kyc`
						? `/${slug}/mobile/kyc-success`
						: `/${slug}/process-kyc`
			}
		}else{
			if (error || (data && variables?.status !== 'success')) {
					dispatch(updateKYCStatus({ error: true, success: false }))
					next = pathname === '/mobile/kyc' ? '/kyc-error' : '/error'
				}
			if (data && variables?.status === 'success') {
				if (pathname === '/mobile/kyc') {
					dispatch(updateKYCStatus({ error: false, success: true }))
				}
				next = pathname === '/mobile/kyc' ? '/kyc-success' : '/process-kyc'
			}
		}
		if(next.length > 0){
		navigate({ pathname: next, search: email ? createSearchParams({email}).toString(): ''}, {
			state: variables?.status
		})
	}
	}, [
		error,
		data,
		navigate,
		pathname,
		dispatch,
		variables,
		slug,
		searchParams
	])

	return {
		handleVeridasAlert,
		isLoading,
		error,
		data
	}
}
