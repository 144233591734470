import styled from 'styled-components';

export const Message = ()=>{
  return <SVG width="49" height="75" viewBox="0 0 49 75" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M28.4306 2.71077H28.4176C25.2344 2.71466 22.0969 3.46901 19.2599 4.91257C16.4228 6.35614 13.966 8.44827 12.089 11.0192C10.2119 13.5901 8.96742 16.5673 8.45651 19.7092C7.9456 22.8512 8.18267 26.0693 9.14846 29.1025L6.49106 32.0766C6.28822 32.3033 6.15663 32.5847 6.11269 32.8858C6.06874 33.1868 6.11439 33.4941 6.24394 33.7694C6.3735 34.0447 6.58122 34.2757 6.84121 34.4337C7.1012 34.5917 7.40196 34.6696 7.70596 34.6578L11.8107 34.4958C13.675 37.1762 16.1598 39.366 19.0533 40.8786C21.9468 42.3911 25.1631 43.1815 28.4281 43.1824C33.7951 43.1824 38.9423 41.0504 42.7373 37.2553C46.5323 33.4603 48.6643 28.3132 48.6643 22.9462C48.6643 17.5792 46.5323 12.432 42.7373 8.63701C38.9423 4.84198 33.7951 2.70996 28.4281 2.70996L28.4306 2.71077ZM42.372 36.8017C40.3331 38.8582 37.8649 40.4386 35.144 41.4298C32.4231 42.4211 29.5167 42.7986 26.6329 42.5355C23.749 42.2723 20.9589 41.375 18.4623 39.9077C15.9658 38.4404 13.8244 36.4394 12.1914 34.0479L12.1023 33.9192L7.6849 34.0933C7.49205 34.101 7.30121 34.0517 7.13622 33.9515C6.97122 33.8514 6.83938 33.7049 6.75713 33.5303C6.67489 33.3557 6.64588 33.1607 6.67375 32.9698C6.70162 32.7788 6.78513 32.6002 6.91384 32.4564L9.78911 29.2386L9.73807 29.083C8.76585 26.1298 8.50806 22.988 8.98586 19.9157C9.46366 16.8434 10.6634 13.9283 12.4866 11.4098C14.3098 8.89122 16.7044 6.84107 19.4738 5.42771C22.2432 4.01434 25.3084 3.27806 28.4176 3.27935H28.4306C32.3155 3.27679 36.1141 4.42558 39.3466 6.58063C42.5791 8.73567 45.1004 11.8003 46.5922 15.3875C48.084 18.9746 48.4792 22.9234 47.7281 26.7351C46.977 30.5467 45.1131 34.0503 42.372 36.8033V36.8017Z" fill="#1066FF"/>
  <path d="M21.4995 15.0997L19.2114 17.0006L19.3823 18.1645L37.1725 17.5854L37.8634 17.1505L35.1185 15.0997H21.4995Z" fill="white"/>
  <path d="M35.8285 16.0942H21.1808C19.8331 16.0942 18.7405 17.1868 18.7405 18.5346V28.1582C18.7405 29.506 19.8331 30.5985 21.1808 30.5985H35.8285C37.1763 30.5985 38.2688 29.506 38.2688 28.1582V18.5346C38.2688 17.1868 37.1763 16.0942 35.8285 16.0942Z" fill="#27277A"/>
  <g opacity="0.33">
  <path d="M19.2971 31.674C19.2971 31.674 21.7059 32.6839 21.7812 32.3511C21.8565 32.0182 29.9478 14.9051 29.9478 14.9051L27.0968 14.5665L19.2971 31.674Z" fill="#FAF5F0"/>
  </g>
  <g opacity="0.33">
  <path d="M23.2976 32.27C23.2976 32.27 28.1694 33.2792 28.2447 32.9463C28.32 32.6134 36.4121 15.5003 36.4121 15.5003L31.0973 15.1626L23.2976 32.27Z" fill="#FAF5F0"/>
  </g>
  <path d="M28.16 6.71521C26.2224 6.71585 24.3645 7.486 22.9946 8.85631C21.6248 10.2266 20.8552 12.0849 20.8552 14.0224V15.5978C20.8552 15.7015 20.8757 15.8043 20.9154 15.9001C20.9552 15.996 21.0134 16.0831 21.0868 16.1564C21.1602 16.2298 21.2474 16.2879 21.3433 16.3276C21.4392 16.3672 21.542 16.3876 21.6457 16.3874H22.9092C23.013 16.3876 23.1157 16.3672 23.2115 16.3275C23.3074 16.2879 23.3945 16.2297 23.4679 16.1564C23.5412 16.083 23.5994 15.9959 23.639 15.9001C23.6787 15.8042 23.699 15.7015 23.6989 15.5978V13.9277C23.6989 12.7439 24.1692 11.6085 25.0063 10.7715C25.8433 9.93439 26.9786 9.46413 28.1624 9.46413C28.7486 9.46413 29.329 9.57958 29.8706 9.8039C30.4121 10.0282 30.9042 10.357 31.3187 10.7715C31.7331 11.1859 32.0619 11.678 32.2862 12.2196C32.5105 12.7611 32.626 13.3415 32.626 13.9277V15.5978C32.626 15.8072 32.7092 16.0081 32.8573 16.1562C33.0054 16.3042 33.2063 16.3874 33.4157 16.3874H34.68C34.7837 16.3876 34.8865 16.3672 34.9823 16.3275C35.0782 16.2879 35.1653 16.2297 35.2386 16.1564C35.312 16.083 35.3701 15.9959 35.4098 15.9001C35.4494 15.8042 35.4698 15.7015 35.4697 15.5978V14.0224C35.4697 13.0626 35.2806 12.1122 34.9132 11.2255C34.5458 10.3388 34.0074 9.53316 33.3286 8.85459C32.6498 8.17602 31.844 7.63782 30.9571 7.27074C30.0703 6.90366 29.1198 6.71489 28.16 6.71521Z" fill="#FAF5F0"/>
  <path d="M31.2232 27.571L30.0561 22.8265C30.5065 22.4935 30.84 22.0267 31.0089 21.4928C31.1778 20.9588 31.1735 20.3851 30.9966 19.8537C30.8197 19.3224 30.4793 18.8606 30.024 18.5344C29.5687 18.2083 29.0219 18.0345 28.4619 18.038C27.9019 18.0415 27.3573 18.222 26.9061 18.5538C26.4549 18.8856 26.1202 19.3515 25.9499 19.8851C25.7796 20.4186 25.7824 20.9923 25.958 21.5241C26.1336 22.056 26.4729 22.5186 26.9273 22.8459L25.9554 27.6245C25.9149 27.822 25.9189 28.0261 25.967 28.2219C26.0151 28.4177 26.1063 28.6004 26.2337 28.7566C26.3612 28.9128 26.5218 29.0388 26.704 29.1252C26.8862 29.2117 27.0853 29.2565 27.2869 29.2565H29.9071C30.113 29.2559 30.3161 29.2085 30.501 29.1179C30.686 29.0273 30.8479 28.8959 30.9747 28.7336C31.1014 28.5713 31.1897 28.3823 31.2327 28.1809C31.2758 27.9795 31.2725 27.771 31.2232 27.571Z" fill="white"/>
  <path d="M30.5488 22.8263C30.9994 22.4934 31.3332 22.0264 31.5023 21.4923C31.6714 20.9581 31.6672 20.3842 31.4902 19.8526C31.3133 19.321 30.9728 18.859 30.5173 18.5327C30.0619 18.2064 29.5149 18.0326 28.9546 18.0361C28.3944 18.0395 27.8496 18.2202 27.3982 18.5521C26.9469 18.884 26.6121 19.3502 26.4418 19.884C26.2715 20.4177 26.2744 20.9917 26.4501 21.5237C26.6258 22.0557 26.9654 22.5184 27.4201 22.8457L26.4433 27.6244C26.4029 27.8219 26.407 28.026 26.4553 28.2218C26.5035 28.4176 26.5947 28.6003 26.7222 28.7565C26.8498 28.9127 27.0104 29.0386 27.1926 29.1251C27.3748 29.2115 27.574 29.2564 27.7756 29.2564H30.3958C30.602 29.2563 30.8054 29.2094 30.9908 29.1191C31.1762 29.0288 31.3386 28.8976 31.4658 28.7353C31.593 28.573 31.6816 28.3839 31.7249 28.1823C31.7683 27.9807 31.7652 27.7719 31.716 27.5717L30.5488 22.8263Z" fill="#1066FF"/>
  <path d="M12.5131 66.9806C12.5479 68.0011 11.9259 68.9171 10.9531 69.7222C11.5654 70.6893 12.1389 71.679 12.573 72.7441C12.6883 73.028 12.7886 73.3179 12.8735 73.6123C14.1542 73.4567 15.4239 73.2213 16.6753 72.9077C14.8967 68.5146 12.5131 66.9806 12.5131 66.9806Z" fill="white"/>
  <path d="M8.68349 72.0185C8.55309 71.7755 8.4073 71.5325 8.27852 71.2896C4.83711 73.1192 0.0803684 74.1065 0.000994842 74.1065C-0.0783787 74.1065 4.60384 74.539 9.75179 74.16C9.6303 73.8684 9.50882 73.5792 9.36384 73.2949C9.15083 72.8616 8.90946 72.4445 8.68349 72.0185Z" fill="white"/>
  <path d="M35.8393 47.0879C35.3553 46.9311 34.8319 46.9469 34.3583 47.1327C33.8847 47.3185 33.4901 47.6628 33.2419 48.1069C32.9937 48.551 32.9071 49.0674 32.997 49.5682C33.0868 50.0689 33.3475 50.523 33.7347 50.8531C34.1218 51.1832 34.6114 51.3688 35.1201 51.3784C35.6288 51.3879 36.125 51.2208 36.5242 50.9055C36.9235 50.5902 37.2011 50.1462 37.3097 49.6492C37.4183 49.1521 37.3511 48.6328 37.1198 48.1797C36.989 47.9222 36.8085 47.6931 36.5887 47.5057C36.3689 47.3183 36.1142 47.1763 35.8393 47.0879ZM37.0218 49.7826C36.9101 50.125 36.706 50.4299 36.432 50.6636C36.158 50.8973 35.8248 51.0508 35.4691 51.1071C35.1134 51.1634 34.749 51.1203 34.4163 50.9825C34.0835 50.8448 33.7952 50.6178 33.5833 50.3266C33.3715 50.0354 33.2442 49.6913 33.2155 49.3323C33.1868 48.9733 33.2579 48.6134 33.4209 48.2922C33.5839 47.9711 33.8325 47.7012 34.1392 47.5124C34.4459 47.3236 34.7988 47.2232 35.1589 47.2224C35.3644 47.2228 35.5685 47.2553 35.7639 47.3188C36.0084 47.3978 36.2348 47.5243 36.4304 47.691C36.6259 47.8576 36.7866 48.0612 36.9035 48.29C37.0203 48.5188 37.0908 48.7684 37.1111 49.0245C37.1314 49.2806 37.1011 49.5382 37.0218 49.7826Z" fill="#031131"/>
  <path d="M25.0859 63.021L31.6164 62.9335L28.8699 58.5656L25.0859 63.021ZM25.5962 62.778L28.8416 58.9568L31.1969 62.7027L25.5962 62.778Z" fill="#031131"/>
  </SVG>
}

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  display: block
`
