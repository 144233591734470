import styled from 'styled-components'

export const Footer = styled.footer`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 2px;
`

export const Togggle = styled.svg`
	margin-top: -2px;
`
