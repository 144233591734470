import { createContext, useContext } from 'react'
import { IAuth } from './types'

export const initialState = {
	user: null,
	isLoggedin: false,
	logout: () => {}
}

export const AuthContext = createContext<IAuth>(initialState)
export const useAuthContext = () => useContext(AuthContext)
