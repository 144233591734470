import { Loading } from '../../Components/Loading'
import { Veridas } from '../Veridas'
import { useCompleteKYC } from '../../hooks/useCompleteKYC'
import { Fragment, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

export const MobileKYC = () => {
	const navigate = useNavigate()
	const { isLoading, error } = useCompleteKYC()

	useEffect(() => {
		if (error) {
			navigate('/kyc-error')
		}
	}, [error, navigate])

	const showWidget = useMemo(() => !isLoading, [isLoading])
	return (
		<Fragment>
			{isLoading ? <Loading /> : null}
			{showWidget ? <Veridas qrKYC={true} /> : null}
		</Fragment>
	)
}
