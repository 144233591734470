import { useEffect } from 'react'
import { useAvailableDocuments } from '../../hooks/useAvailableDocuments'
import { useVeridasAccessToken } from '../../hooks/useGetVeridasAccessToken'
import { useNavigate } from 'react-router-dom'
import { setVeridasToken, setDocumentId } from '../../store/Reducers/Login/userSlice'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import {
	CountryAndDocumentType,
	IDocumentData,
	IVariables
} from '../../Components/CountryAndDocumentType'

export const SelectCountryAndDocumentType = () => {
	const { data, loading, error, handleVariables } = useAvailableDocuments()
	const { getAccessToken, veridasAccessToken } = useVeridasAccessToken()
	const { veridasAccessToken: accessToken } = useSelector(
		(state: RootState) => state.user
	)

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleVariablesChange = (variables: IVariables) => {
		handleVariables(variables)
	}

	const handleDocumentSelection = (documentData: IDocumentData) => {
		const variables = {
			documentId: documentData.id
		}
		getAccessToken(variables)
		setDocumentId(documentData.id)
	}

	useEffect(() => {
		if (veridasAccessToken) {
			dispatch(setVeridasToken({ ...veridasAccessToken }))
		}
	}, [veridasAccessToken, dispatch])

	useEffect(() => {
		if (accessToken?.access_token) {
			navigate('/perform-kyc')
		}
	}, [navigate, accessToken])

	return (
		<CountryAndDocumentType
			loading={loading}
			data={data ? data : []}
			handleVariablesChange={handleVariablesChange}
			error={error}
			handleDocumentSelection={handleDocumentSelection}
		/>
	)
}
