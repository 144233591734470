import { FC } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { IProps } from './types'
import { FormButton } from '../Button'
import { Loader, Text } from './LoadingButton.styled'

export const LoadingButton: FC<IProps> = ({
	type,
	disabled,
	loading,
	text
}) => {
	return (
		<FormButton type={'submit'} disabled={disabled}>
			<Loader>
				{loading ? (
					<CircularProgress color={'inherit'} size={20} />
				) : null}
			</Loader>
			<Text loading={loading ? loading : undefined}>{text}</Text>
		</FormButton>
	)
}
