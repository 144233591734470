import { useMemo, useEffect } from 'react'
import { AS, Arrangment, Button, ButtonVariant } from '../../Components/Shared/Result/types'
import { Result } from '../../Components/Shared/Result/Result'
import { sendEmailProps } from '../../Utils/emailState'
import { useI18n } from '../../hooks/useI18n'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useLoginOTP } from '../../hooks/useLoginOTP'
import { Loading } from '../../Components/Loading'
import { ErrorAlert } from '../../Components/Shared/ErrorAlert'
import { ErrorContainer } from './OTPSent.styled';
import { useLocalStorage } from '../../hooks/useLocalStorage'

export const OTPSent = ()=>{
	const { translation } = useI18n()
	const [searchParams] = useSearchParams()
	const { error , loading, login } = useLoginOTP();
	const { storeItem } = useLocalStorage();
	const navigate = useNavigate()
	
	const redirect = ()=>{
		let email = searchParams.get('user')
		if(email){
			login({email})
		}else{	
			navigate({
				pathname: '/otp/login'
			})
		}
	}

	useEffect(()=>{
		const companyId = searchParams.get('referer');
		if(companyId){
			storeItem("TOGGGLE_REFERER_ID", JSON.stringify({companyId}));
		}

	},[searchParams, storeItem])
	
	const button: Button = {
		subtext: "Didn't receive a link?",
		text: 'Resend',
		arrangment: 'column' as Arrangment,
		variant: 'text' as ButtonVariant, 
		as: 'button' as AS,
		callback: redirect
	}
	
	const errorReason = useMemo(()=>{
		if(error && error.errors){
			return Object.values(error?.errors)[0]
		}
		
		return null
	}, [error])
	return <>
	{loading? <Loading light={true} />: null}
	{errorReason ? <ErrorContainer><ErrorAlert message={errorReason} /> </ErrorContainer>:null}
	{!loading ? <Result
					{...{
						...sendEmailProps,
						subtext: translation("You should receive an email with instructions to access your account"),
						header: translation(sendEmailProps.header),
						button
					}}
				/>: null}
			</>
}
