import { useMemo } from 'react'
import { Container, Header, Main } from './Layout.styled'
import { Link } from 'react-router-dom'
import { Logo } from '../Components/Logo'
import { Outlet } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { AuthProvider } from '../Context/AuthProvider'

export const PublicLayout = () => {
	const redirect = useMemo(()=>{
		return '/otp/login'
	},[])
	
	return (
		<Container>
			<AuthProvider>
				<Header>
					<Link to={redirect}>
						<Logo light={true} />
					</Link>
				</Header>
				<Main isMobile={isMobile}>
					<Outlet />
				</Main>
			</AuthProvider>
		</Container>
	)
}
