import { useLocation } from "react-router-dom";
import { Result } from "../../Components/Shared/Result";
import { Arrangment } from "../../Components/Shared/Result/types";
import { ReactComponent as CircleWarnning } from '../../assets/svgs/warnning-circle.svg'

export const LoginError = ()=>{
    const { state } = useLocation();
    const header = "Oops! Login failed";
    const subtext = "Login failed. Togggle was not able to access the identity data";
    const button = {
        text: 'Login',
        isLink: {
            isButton: true,
        },
        link: '/otp/login',
        arrangment: 'row' as Arrangment
    }
    return <Result 
            Icon={CircleWarnning}
			header={state ?? header}
			subtext={subtext}
			noBtmRadius={true}
            button={button}
        />
}