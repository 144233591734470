import { FC } from 'react'
import { RequestClientContext } from './useRequestClientContext'
import { IProps } from './types'
import { get, post, put } from './helpers'

export const RequestClientProvider: FC<IProps> = ({ children }) => {
	return (
		<RequestClientContext.Provider value={{ get, put, post }}>
			{children}
		</RequestClientContext.Provider>
	)
}
