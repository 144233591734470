import { useI18n } from '../../../hooks/useI18n'
import { IconButton } from '@mui/material'
import {
	Container,
	List,
	CustomRow,
	Icon,
	Text
} from './KYCInstructions.styled'
import { SubHeader } from '../SubHeader/SubHeader'

export const KYCInstructions = () => {
	const { translation } = useI18n()
	const instructions = [
		translation('kycStep_one'),
		translation('kycStep_two'),
		translation('kycStep_three')
	]
	return (
		<Container>
			<SubHeader text={translation('kycStep_header')} />
			<List>
				{instructions.map((text, index) => {
					return (
						<CustomRow key={index}>
							<IconButton>
								<Icon />
							</IconButton>
							<Text variant="body2">{text}</Text>
						</CustomRow>
					)
				})}
			</List>
		</Container>
	)
}
