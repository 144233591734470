import { useEffect, useRef } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useRequestClientContext } from '../../Context/RequestClientProvider'
import { IError, IResponse, IVariables } from './types'
import { AxiosResponse, AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setVeridasToken } from '../../store/Reducers/Login'
import { useLocalStorage } from '../useLocalStorage'

export const useVeridasAccessToken = () => {
	const URL = '/kyc/token'
	const storeChange = useRef(false);
	const dispatch = useDispatch()
	const request = useRequestClientContext()
	const { storeItem } = useLocalStorage();
	const { slug } = useParams()
	const { mutate, isLoading, error, data } = useMutation<
		IResponse,
		IError,
		IVariables,
		unknown
	>(
		async ({
			documentId,
			customData
		}: IVariables): Promise<
			AxiosResponse<IResponse> | AxiosError<IError> | any
		> => {
			const variables = {
				documentId,
				customData
			}
			storeChange.current = false;
			const response = await request?.post<IResponse, IVariables, IError>(
				variables,
				slug ? `/${slug}${URL}` : URL
			)
			return response
		}
	)

	useEffect(() => {
		if (data && !storeChange.current) {
			storeChange.current = true;
			dispatch(setVeridasToken({ ...data }));
			storeItem('TOGGGLE_REFERER_ID', JSON.stringify({ companyId: slug }))
		}
	}, [data, dispatch, storeItem, slug])
	return {
		loading: isLoading,
		error,
		veridasAccessToken: data,
		getAccessToken: mutate
	}
}
