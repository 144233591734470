import styled from 'styled-components'

export const SelfieIcon = ()=>{
  return<Container> <SVG width="343" height="222" viewBox="0 0 343 222" fill="#F9FCFF" xmlns="http://www.w3.org/2000/svg">
  <rect width="100%" height="222" rx="16" fill="#F9FCFF" />
  <path d="M308.205 61.6444L314.907 66.5074C314.907 66.5074 324.07 74.6764 322.499 94.626C320.928 114.576 297.837 152.592 253.224 133.112C208.612 113.633 203.585 87.871 202.014 74.9896C200.443 62.1081 195.421 46.0854 195.421 46.0854C195.421 46.0854 213.643 38.5459 215.528 38.5459C217.414 38.5459 246.002 61.6433 246.002 61.6433L286.95 56.6628L308.205 61.6444Z" fill="#031131"/>
  <path d="M315.794 78.9038C304.214 68.7549 281.593 71.7159 263.18 75.4296C257.665 76.5416 252.363 76.7349 247.421 76.0054C243.561 75.4502 239.817 74.2744 236.333 72.5235C230.536 69.5866 227.635 66.0092 227.593 65.9564C227.508 65.8672 227.392 65.8132 227.269 65.805C227.146 65.7969 227.024 65.8351 226.927 65.9123C226.831 65.9895 226.767 66.0999 226.748 66.222C226.729 66.344 226.756 66.4688 226.824 66.5717C226.945 66.7233 229.857 70.3381 235.841 73.3838C239.423 75.191 243.274 76.4054 247.245 76.9799C252.31 77.7337 257.739 77.5381 263.38 76.4009C281.57 72.7323 303.892 69.7866 315.146 79.6488C317.701 81.888 319.593 84.737 320.772 88.1188C322.053 91.7952 322.53 96.2099 322.19 101.242C322.182 101.373 322.225 101.502 322.311 101.601C322.398 101.7 322.52 101.76 322.651 101.769H322.684C322.809 101.769 322.93 101.722 323.021 101.636C323.113 101.55 323.169 101.433 323.177 101.308C323.525 96.1439 323.031 91.5952 321.706 87.7936C320.465 84.2458 318.479 81.2562 315.794 78.9038Z" fill="#27277A"/>
  <path d="M283.38 55.617C269.866 55.7268 232.904 55.5071 232.904 55.5071L215.52 51.1122L216.48 56.0268C216.48 56.0268 220.86 65.7736 238.14 70.9057C255.42 76.0379 277.061 64.0618 287.394 62.2994C297.726 60.5371 304.325 61.0612 314.903 66.5064C305.375 59.9097 296.89 55.5148 283.38 55.617Z" fill="#27277A"/>
  <path d="M189.574 51.2287L189.537 51.2192L189.5 51.2185L189.5 51.2185L189.5 51.2185L189.499 51.2185L189.497 51.2185L189.481 51.2179C189.466 51.2173 189.442 51.2161 189.411 51.214C189.349 51.2097 189.256 51.2017 189.137 51.1865C188.898 51.156 188.555 51.097 188.147 50.983C187.331 50.7549 186.259 50.3083 185.234 49.4347C183.202 47.7013 181.28 44.2174 182.057 37.1673C182.831 30.1519 186.17 26.8406 189.283 25.2651C190.848 24.4728 192.368 24.1142 193.497 23.9541C194.061 23.8741 194.526 23.8438 194.849 23.8335C195.01 23.8284 195.135 23.8282 195.22 23.8293C195.262 23.8298 195.293 23.8306 195.314 23.8313L195.337 23.8322L195.342 23.8324L195.343 23.8324L195.343 23.8324L195.343 23.8324L195.561 23.8438L195.648 23.6433C197.262 19.9093 203.976 12.1731 220.495 14.8752C228.715 16.2198 234.149 20.8427 238.36 26.3671C240.468 29.1319 242.266 32.1182 243.954 35.0308C244.261 35.56 244.564 36.0872 244.865 36.6102C246.216 38.9591 247.519 41.2247 248.875 43.2258C252.216 48.157 255.791 50.3805 260.103 51.3286C263.684 52.1159 267.776 52.0209 272.626 51.9082C273.565 51.8864 274.533 51.8639 275.531 51.8471C287.412 51.6463 302.205 52.9994 313.391 65.2524C302.417 59.0043 291.889 57.4052 275.69 59.7038C258.713 62.1128 236.972 64.4901 219.406 55.2802L219.278 55.2134L219.145 55.2661C218.478 55.5287 217.517 55.6757 216.324 55.7194C215.136 55.7629 213.741 55.7037 212.219 55.5685C209.175 55.2982 205.645 54.7267 202.287 54.0833C198.93 53.4401 195.751 52.7264 193.411 52.1733C192.242 51.8968 191.282 51.6605 190.614 51.4935C190.281 51.4099 190.02 51.3437 189.844 51.2983C189.755 51.2757 189.687 51.2582 189.642 51.2465L189.591 51.2332L189.578 51.2298L189.575 51.229L189.574 51.2288C189.574 51.2287 189.574 51.2287 189.494 51.5328L189.574 51.2287Z" fill="#D0DCF0" stroke="#27277A" strokeWidth="0.628612"/>
  <path d="M64.6137 103.789L62.9909 98.3969C62.9909 98.3969 59.9012 95.2018 57.5972 90.909C55.2932 86.6163 55.1888 82.3214 52.1519 78.656C49.1151 74.9907 41.9942 65.4603 41.9942 65.4603L27.0186 92.689C27.0186 92.689 34.7679 106.303 39.1661 108.607C43.5643 110.911 45.3189 111.317 56.393 114.42C63.148 108.816 64.6137 103.789 64.6137 103.789Z" fill="white"/>
  <path d="M49.2912 103.835C48.4536 103.441 47.6701 102.942 46.9597 102.349C45.5467 101.183 44.4326 99.6967 43.4657 98.097C42.4988 96.4972 41.7077 94.7612 40.9859 92.9802C40.264 91.1992 39.6158 89.3654 38.9532 87.4866C38.9197 87.9858 38.9153 88.4866 38.9401 88.9863C38.9587 89.4852 39.0016 89.9829 39.0565 90.4795C39.1721 91.4748 39.3516 92.4617 39.5938 93.434C40.073 95.4085 40.8369 97.3027 41.8616 99.0573C42.3886 99.948 43.0056 100.782 43.703 101.547C44.4092 102.323 45.2197 102.998 46.1114 103.551C47.0025 104.103 47.983 104.495 49.0088 104.71C49.5132 104.809 50.0254 104.863 50.5393 104.872C51.0443 104.875 51.5485 104.829 52.0446 104.734C51.5556 104.625 51.0799 104.503 50.6162 104.356C50.1649 104.208 49.7225 104.034 49.2912 103.835Z" fill="#031131"/>
  <path d="M127.476 108.765C127.476 108.765 89.0679 105.779 62.9918 98.397C62.2062 101.538 56.3994 114.42 56.3994 114.42C56.3994 114.42 72.6847 118.87 81.7954 121.802C90.906 124.735 116.405 132.118 121.59 132.694C126.775 133.269 134.786 130.733 134.786 130.733L127.476 108.765Z" fill="white"/>
  <path d="M59.2742 95.412L52.1533 112.482C52.1533 112.482 52.8906 118.142 60.1125 120.23C69.7472 105.15 67.8618 96.9821 67.8618 96.9821L59.2742 95.412Z" fill="#27277A"/>
  <path d="M128.813 114.42C128.813 114.42 128.587 123.071 122.977 125.876C117.367 128.681 61.0376 117.123 61.0376 117.123C61.0376 117.123 120.62 138.328 120.958 138.328C121.295 138.328 132.797 140.329 131.702 136.645C130.608 132.961 135.997 121.926 128.813 114.42Z" fill="#FAF5F0"/>
  <path d="M224.409 65.4887C224.409 65.4887 225.578 76.4561 227.149 80.8543C228.695 85.1855 228.955 87.3215 233.433 89.442C237.91 91.5625 256.681 98.0824 258.252 100.595C259.823 103.108 258.905 104.522 259.05 107.428C259.195 110.334 260.609 135.469 264.378 155.261C268.148 175.054 269.561 180.709 269.561 180.709C269.561 180.709 257.937 188.137 240.343 176.961C237.673 158.089 233.275 144.894 233.275 144.894C233.275 144.894 221.179 164.529 217.879 171.598C214.58 178.667 217.8 206.612 217.8 206.612C217.8 206.612 200.421 208.92 181.572 207.821C162.722 206.722 149.25 202.78 149.25 202.78C149.25 202.78 152.217 176.311 154.099 166.572C155.981 156.832 158.969 148.978 161.325 143.951C157.713 139.396 155.356 135.626 158.812 126.514C149.701 127.928 126.766 133.269 126.766 133.269C126.766 133.269 126.452 109.866 126.766 107.979C127.08 106.091 129.751 107.036 133.678 106.093C137.605 105.15 169.497 95.4114 172.479 93.8402C175.461 92.269 175.463 88.9487 180.961 88.5279C186.459 88.107 205.792 87.2325 205.792 87.2325C205.792 87.2325 206.795 84.2406 205.4 72.1799C209.327 66.0413 213.254 60.0719 213.254 60.0719L220.968 58.3436L224.409 65.4887Z" fill="#F3F7FF"/>
  <g opacity="0.64">
  <path d="M158.226 124.141C158.26 124.084 158.292 124.028 158.326 123.968C158.326 123.965 158.286 124.029 158.226 124.141Z" fill="#27277A"/>
  <path d="M205.788 143.381C188.621 141.474 192.211 138.781 181.326 134.516C170.441 130.252 168.759 134.068 162.139 131.15C156.296 128.576 157.795 124.952 158.227 124.137C151.201 136.259 157.314 142.705 157.314 142.705C157.314 142.705 164.159 145.062 170.666 144.501C177.174 143.939 180.542 145.174 184.244 147.531C187.947 149.888 192.66 154.824 207.472 152.467C222.284 150.111 228.007 160.21 227.221 162.95C226.436 165.691 228.119 170.532 228.904 169.3C229.69 168.068 233.281 158.972 233.281 158.972L232.423 138.503C232.423 138.503 222.957 145.288 205.788 143.381Z" fill="#27277A"/>
  </g>
  <path d="M215.52 54.0401L203.974 61.1665L204.175 82.496C204.175 82.496 213.635 76.0905 229.422 83.1553C229.815 75.6938 230.6 65.9547 230.6 65.9547L227.066 53.1523L215.52 54.0401Z" fill="#FAF5F0"/>
  <path d="M158.655 121.645C154.651 124.63 146.953 125.49 142.004 126.589C137.056 127.688 126.767 130.909 125.51 127.06C123.313 131.455 123.469 136.485 123.469 136.485L154.729 129.259L158.813 126.512C158.813 126.512 160.776 121.8 164.861 117.873C168.946 113.946 174.364 106.484 174.364 106.484C174.364 106.484 162.661 118.661 158.655 121.645Z" fill="#031131"/>
  <path d="M209.954 148.269C209.71 148.321 209.471 148.386 209.226 148.43C208.25 148.625 207.286 148.819 206.315 148.951L205.591 149.054L204.866 149.134L204.505 149.174L204.142 149.201L203.419 149.257C203.177 149.257 202.937 149.275 202.696 149.285C202.575 149.285 202.454 149.297 202.335 149.298H201.974C201.734 149.298 201.493 149.308 201.254 149.298L200.534 149.271C198.615 149.179 196.715 148.847 194.879 148.282C193.95 147.993 193.041 147.643 192.158 147.234C191.251 146.821 190.379 146.337 189.549 145.786C189.841 146.208 190.174 146.601 190.543 146.958C190.911 147.32 191.301 147.657 191.712 147.969C192.542 148.595 193.437 149.131 194.381 149.568C196.292 150.432 198.35 150.926 200.445 151.026L201.228 151.046C201.359 151.052 201.489 151.046 201.619 151.046L202.011 151.037L202.401 151.022C202.53 151.015 202.66 151.001 202.79 150.992C203.049 150.968 203.308 150.95 203.566 150.921L204.335 150.811C204.464 150.792 204.593 150.775 204.72 150.752L205.1 150.673L205.858 150.512L206.604 150.309C207.592 150.03 208.558 149.678 209.494 149.258C209.959 149.045 210.413 148.809 210.863 148.575C211.303 148.322 211.742 148.059 212.168 147.78C211.665 147.879 211.179 148 210.688 148.117C210.446 148.16 210.198 148.216 209.954 148.269Z" fill="#27277A"/>
  <path d="M243.729 109.432C242.758 110.315 241.84 111.255 240.982 112.247C239.258 114.243 237.762 116.425 236.523 118.753C236.369 119.044 236.226 119.342 236.076 119.632C235.927 119.922 235.787 120.225 235.651 120.526C235.373 121.126 235.126 121.735 234.882 122.351C234.765 122.66 234.662 122.973 234.545 123.283C234.491 123.439 234.435 123.594 234.38 123.75L234.235 124.224C234.14 124.54 234.038 124.854 233.95 125.172L233.706 126.131C233.62 126.448 233.56 126.774 233.494 127.097C233.429 127.42 233.357 127.741 233.31 128.067C233.213 128.718 233.107 129.367 233.058 130.023L233.016 130.514C233.004 130.677 232.984 130.843 232.981 131.004C232.971 131.333 232.958 131.663 232.953 131.986C232.953 132.15 232.941 132.316 232.947 132.477L232.963 132.967C232.979 133.297 232.989 133.626 233.012 133.946C233.308 132.671 233.561 131.409 233.909 130.174C234.06 129.553 234.26 128.944 234.434 128.332C234.476 128.179 234.532 128.028 234.58 127.877L234.728 127.423C234.827 127.121 234.922 126.819 235.033 126.521L235.355 125.625C235.464 125.329 235.583 125.034 235.697 124.739L235.87 124.299L236.054 123.86C236.179 123.569 236.296 123.275 236.425 122.981C236.553 122.686 236.685 122.404 236.815 122.116L236.911 121.896L237.015 121.683L237.224 121.257L237.427 120.828C237.494 120.686 237.571 120.547 237.641 120.405C237.787 120.125 237.928 119.842 238.081 119.563C239.275 117.321 240.608 115.156 242.071 113.08C242.804 112.036 243.568 111.011 244.363 110.004C245.157 108.993 245.977 107.999 246.84 107.02C246.284 107.365 245.749 107.743 245.237 108.15C244.717 108.565 244.215 108.992 243.729 109.432Z" fill="#27277A"/>
  <path d="M232.589 136.777C229.343 144.632 222.118 155.314 215.52 165.157C208.922 175.001 218.557 208.186 218.557 208.186C218.557 208.186 222.153 197.413 221.821 196.994C221.489 196.576 233.008 150.079 233.008 150.079C233.532 148.613 233.271 144.895 233.271 144.895C233.271 144.895 231.845 142.625 232.589 136.777Z" fill="#031131"/>
  <g opacity="0.64">
  <path d="M205.06 84.0223L201.805 87.0482C201.805 87.0482 216.785 91.8167 220.88 87.1043C206.91 87.2757 205.06 84.0223 205.06 84.0223Z" fill="#27277A"/>
  </g>
  <path d="M219.99 123.678C220.498 121.411 217.323 118.769 212.899 117.777C208.475 116.785 204.477 117.818 203.968 120.085C203.46 122.351 206.634 124.993 211.059 125.985C215.483 126.977 219.481 125.944 219.99 123.678Z" fill="white"/>
  <path d="M273.405 169.807C268.586 172.785 263.64 174.619 258.702 175.257C254.777 175.768 250.79 175.519 246.96 174.523C240.378 172.82 236.317 169.469 236.277 169.434C236.109 169.294 235.893 169.225 235.675 169.245C235.457 169.264 235.256 169.369 235.115 169.536C234.975 169.704 234.907 169.92 234.926 170.138C234.945 170.356 235.05 170.557 235.218 170.698C235.391 170.843 239.552 174.292 246.47 176.1C250.511 177.16 254.718 177.431 258.862 176.899C264.05 176.24 269.235 174.322 274.272 171.208C274.364 171.152 274.444 171.077 274.507 170.989C274.57 170.901 274.616 170.802 274.641 170.697C274.665 170.591 274.669 170.482 274.652 170.375C274.635 170.268 274.596 170.166 274.539 170.074C274.483 169.982 274.408 169.902 274.32 169.839C274.232 169.776 274.133 169.73 274.028 169.705C273.922 169.68 273.813 169.677 273.706 169.694C273.599 169.711 273.497 169.75 273.405 169.807Z" fill="#031131"/>
  <path d="M270.85 161.061C267.815 163.818 264.12 165.689 259.863 166.624C256.433 167.378 252.63 167.529 248.561 167.074C241.583 166.293 236.184 163.968 236.13 163.945C235.93 163.858 235.703 163.854 235.5 163.933C235.296 164.013 235.133 164.171 235.045 164.371C234.958 164.571 234.954 164.798 235.034 165.002C235.113 165.205 235.271 165.369 235.471 165.456C235.697 165.555 241.116 167.892 248.326 168.707C249.885 168.886 251.452 168.976 253.021 168.979C255.429 168.99 257.83 168.742 260.185 168.239C264.733 167.244 268.691 165.24 271.948 162.281C272.028 162.208 272.093 162.12 272.139 162.022C272.185 161.924 272.211 161.818 272.217 161.71C272.222 161.602 272.206 161.494 272.169 161.392C272.132 161.29 272.076 161.197 272.003 161.117C271.93 161.036 271.843 160.972 271.745 160.925C271.647 160.879 271.541 160.853 271.433 160.848C271.325 160.843 271.217 160.859 271.115 160.895C271.013 160.932 270.919 160.988 270.839 161.061H270.85Z" fill="#031131"/>
  <path d="M132.441 114.846C132.563 107.617 133.596 102.045 133.606 101.99C133.628 101.883 133.628 101.773 133.607 101.666C133.586 101.558 133.543 101.456 133.482 101.366C133.421 101.275 133.343 101.197 133.252 101.137C133.16 101.077 133.058 101.035 132.951 101.015C132.843 100.995 132.733 100.996 132.626 101.019C132.519 101.042 132.418 101.086 132.328 101.149C132.238 101.211 132.162 101.291 132.103 101.383C132.044 101.475 132.005 101.578 131.986 101.686C131.976 101.742 130.92 107.427 130.794 114.788C130.72 119.12 130.976 123.184 131.557 126.867C132.285 131.499 133.534 135.547 135.263 138.89C135.367 139.077 135.54 139.216 135.745 139.277C135.949 139.339 136.17 139.318 136.36 139.22C136.55 139.122 136.694 138.954 136.763 138.751C136.831 138.549 136.818 138.327 136.726 138.134C134.66 134.135 132.24 126.85 132.441 114.846Z" fill="#031131"/>
  <path d="M138.462 112.414C138.151 106.168 138.58 101.966 138.584 101.924C138.595 101.816 138.585 101.707 138.555 101.604C138.524 101.5 138.473 101.403 138.405 101.319C138.337 101.235 138.253 101.165 138.158 101.113C138.063 101.062 137.958 101.029 137.851 101.018C137.743 101.007 137.634 101.017 137.53 101.047C137.427 101.078 137.33 101.129 137.246 101.197C137.162 101.265 137.092 101.349 137.04 101.444C136.988 101.539 136.956 101.644 136.945 101.751C136.926 101.926 136.5 106.096 136.814 112.46C137.104 118.33 138.114 127.226 141.277 136.276C141.334 136.436 141.439 136.576 141.578 136.674C141.718 136.772 141.884 136.825 142.055 136.825C142.147 136.825 142.239 136.81 142.326 136.779C142.533 136.707 142.702 136.556 142.797 136.359C142.892 136.162 142.905 135.936 142.833 135.729C139.74 126.883 138.749 118.167 138.462 112.414Z" fill="#031131"/>
  <path d="M167.576 155.099C165.533 151.878 165.378 149.679 162.862 150.229C160.346 150.78 160.035 154.235 161.135 156.199C162.235 158.162 163.57 161.303 163.57 161.303L175.744 162.951C175.744 162.951 169.617 158.319 167.576 155.099Z" fill="#FAF5F0"/>
  <path d="M183.528 182.647C183.528 182.647 213.323 193.434 223.533 196.575C233.744 199.716 252.909 205.372 260.92 202.387C268.93 199.402 267.989 189.978 267.989 187.15C267.989 184.322 268.023 181.473 268.023 181.473C268.023 181.473 258.29 187.418 240.834 177.27C230.835 174.976 212.8 172.28 204.422 170.604C196.044 168.929 184.053 164.215 184.053 164.215C184.053 164.215 166.877 160.809 163.972 159.71C161.067 158.612 154.154 157.93 151.064 157.513C147.975 157.095 146.142 157.827 140.592 158.141C135.043 158.456 132.057 158.351 131.743 159.922C131.429 161.494 133.052 163.328 136.717 163.273C140.383 163.219 143.786 162.854 144.781 162.959C145.775 163.065 153.421 164.686 153.578 164.686C153.735 164.686 143.105 167.462 141.382 167.88C139.66 168.299 132.585 177.097 132.742 178.353C132.899 179.61 134.156 180.448 136.093 179.924C138.03 179.399 143.372 173.85 144.052 173.378C144.732 172.907 154.211 171.494 154.211 171.494C154.211 171.494 147.246 176.782 146.566 177.672C145.886 178.562 143.738 186.941 144 188.564C144.261 190.187 146.828 192.282 150.074 190.501C153.321 188.72 155.048 184.584 155.363 184.06C155.677 183.536 160.651 177.829 163.112 179.401C165.573 180.972 176.879 185.998 183.528 182.647Z" fill="white"/>
  <path d="M39.7397 47.0544L22.9841 54.2599C21.336 54.9685 20 56.8792 20 58.5273V97.3805C20 99.0286 21.336 99.79 22.9841 99.0813L39.7397 91.877C41.3878 91.1683 42.725 89.2565 42.725 87.6084V48.7574C42.725 47.1083 41.3889 46.3468 39.7397 47.0544Z" fill="#1066FF"/>
  <path d="M41.5231 47.8608L24.762 55.0663C23.1139 55.775 21.7778 57.6857 21.7778 59.3338V98.1869C21.7778 99.835 23.1139 100.596 24.762 99.8878L41.5187 92.6834C43.1668 91.9747 44.5028 90.0629 44.5028 88.4149V49.5628C44.5072 47.9136 43.1712 47.1522 41.5231 47.8608Z" fill="#27277A"/>
  <path d="M40.674 90.7761L25.6214 97.2509C24.1404 97.8882 22.9395 97.2037 22.9395 95.7226V60.8084C22.9395 59.3273 24.1404 57.61 25.6214 56.9727L40.674 50.499C42.1551 49.8617 43.356 50.5462 43.356 52.0273V86.9383C43.356 88.4227 42.1551 90.1389 40.674 90.7761Z" fill="#FAF5F0"/>
  <path d="M40.4061 50.6134C40.4061 50.6134 42.4123 50.4145 42.4123 52.7998V85.8452C42.4123 88.6227 41.3751 90.418 38.4009 92.0453H44.5076V46.7909L39.2985 49.8816L40.4061 50.6134Z" fill="#1066FF"/>
  <path d="M196.514 35.9283C196.514 35.9283 189.341 41.6879 188.346 49.4899C187.352 57.292 187.979 67.9211 188.975 72.5291C189.97 77.1372 191.54 81.5387 197.457 81.5387C203.373 81.5387 214.841 75.9879 215.993 71.904C217.146 67.82 217.25 67.453 217.25 67.453C217.25 67.453 220.759 66.7729 221.596 62.7933C222.433 58.8137 220.967 55.3571 216.988 54.7814C211.49 51.9511 200.494 47.3958 196.514 35.9283Z" fill="white"/>
  <path d="M199.93 50.0131C198.912 50.0131 198.088 51.3315 198.088 52.9587C198.088 54.586 198.912 55.9044 199.93 55.9044C200.947 55.9044 201.771 54.586 201.771 52.9587C201.771 51.3315 200.947 50.0131 199.93 50.0131Z" fill="#031131"/>
  <g opacity="0.57">
  <path d="M209.237 70.8364C212.365 70.8364 214.901 68.3006 214.901 65.1725C214.901 62.0444 212.365 59.5085 209.237 59.5085C206.109 59.5085 203.573 62.0444 203.573 65.1725C203.573 68.3006 206.109 70.8364 209.237 70.8364Z" fill="#FFCCE9"/>
  </g>
  <path d="M201.323 68.3818C197.808 72.3988 194.615 70.7089 194.475 70.632C194.361 70.5728 194.229 70.5601 194.106 70.5967C193.983 70.6334 193.879 70.7164 193.816 70.8282C193.753 70.9401 193.736 71.072 193.769 71.1961C193.801 71.3203 193.881 71.4269 193.991 71.4934C194.03 71.5165 194.959 72.0307 196.37 72.0307C196.424 72.0307 196.48 72.0307 196.535 72.0307C199.002 71.9615 200.905 70.3628 202.067 69.0345C202.113 68.9861 202.148 68.929 202.172 68.8666C202.195 68.8042 202.205 68.7378 202.202 68.6714C202.199 68.6049 202.182 68.5398 202.153 68.4798C202.124 68.4199 202.083 68.3665 202.033 68.3227C201.983 68.2789 201.925 68.2456 201.862 68.2249C201.798 68.2043 201.732 68.1965 201.665 68.2023C201.599 68.208 201.535 68.227 201.476 68.2583C201.417 68.2895 201.365 68.3323 201.323 68.384V68.3818Z" fill="#031131"/>
  <path d="M186.137 64.1517C186.017 66.2701 191.043 66.5074 191.043 66.5074C191.043 66.5074 191.303 59.5777 191.153 54.4478C191.155 54.4478 186.258 62.0334 186.137 64.1517Z" fill="#27277A"/>
  <path d="M261.04 99.3693C253.687 95.8622 247.426 96.9257 247.162 96.9719C247.034 96.9957 246.92 97.0693 246.846 97.1766C246.771 97.2839 246.743 97.4163 246.765 97.5448C246.788 97.6733 246.861 97.7875 246.968 97.8625C247.075 97.9376 247.207 97.9674 247.336 97.9453C247.397 97.9355 253.562 96.8983 260.614 100.261C261.906 100.878 262.605 101.707 262.751 102.798C262.971 104.403 261.965 106.607 259.856 109.171C258.815 110.427 257.682 111.602 256.465 112.687C256.414 112.729 256.373 112.78 256.342 112.839C256.312 112.897 256.293 112.961 256.287 113.026C256.282 113.092 256.289 113.158 256.31 113.22C256.33 113.283 256.362 113.341 256.405 113.39C256.448 113.44 256.501 113.481 256.559 113.51C256.618 113.54 256.682 113.557 256.748 113.561C256.814 113.566 256.879 113.557 256.942 113.535C257.004 113.514 257.061 113.48 257.11 113.436C257.405 113.182 264.332 107.158 263.731 102.669C263.54 101.239 262.633 100.13 261.04 99.3693Z" fill="#F3F7FF"/>
  <path d="M118.372 130.041C118.319 130.004 118.259 129.978 118.195 129.965C118.131 129.951 118.066 129.951 118.002 129.962C117.938 129.974 117.877 129.999 117.823 130.034C117.768 130.069 117.721 130.115 117.685 130.169C116.586 131.766 113.376 132.182 108.391 131.37C104.216 130.689 98.7666 129.164 92.2094 126.835C81.0167 122.86 70.3393 117.861 70.2349 117.81C70.1162 117.754 69.9801 117.748 69.8567 117.793C69.7333 117.837 69.6326 117.929 69.5768 118.047C69.521 118.166 69.5146 118.302 69.5591 118.426C69.6036 118.549 69.6953 118.65 69.8141 118.706C69.924 118.756 80.6376 123.773 91.8743 127.765C98.4887 130.114 103.992 131.655 108.232 132.345C109.747 132.609 111.281 132.752 112.818 132.772C115.691 132.772 117.567 132.094 118.503 130.728C118.577 130.619 118.604 130.486 118.58 130.357C118.555 130.229 118.481 130.115 118.372 130.041Z" fill="#FAF5F0"/>
  <path d="M106.531 51.9002C107.044 51.9002 107.461 51.484 107.461 50.9707C107.461 50.4573 107.044 50.0411 106.531 50.0411C106.018 50.0411 105.602 50.4573 105.602 50.9707C105.602 51.484 106.018 51.9002 106.531 51.9002Z" fill="#1066FF"/>
  <path d="M114.193 50.0461C114.009 50.0459 113.829 50.1004 113.676 50.2025C113.522 50.3047 113.403 50.45 113.332 50.6201C113.262 50.7902 113.243 50.9774 113.279 51.158C113.315 51.3387 113.404 51.5046 113.534 51.6348C113.664 51.765 113.83 51.8537 114.011 51.8896C114.191 51.9254 114.378 51.9069 114.548 51.8363C114.719 51.7658 114.864 51.6463 114.966 51.4931C115.068 51.3399 115.123 51.1598 115.122 50.9757C115.122 50.7291 115.025 50.4927 114.85 50.3184C114.676 50.1441 114.439 50.0461 114.193 50.0461Z" fill="#1066FF"/>
  <path d="M121.846 50.0461C121.662 50.0459 121.482 50.1004 121.329 50.2025C121.176 50.3047 121.056 50.45 120.986 50.6201C120.915 50.7902 120.897 50.9774 120.932 51.158C120.968 51.3387 121.057 51.5046 121.187 51.6348C121.317 51.765 121.483 51.8537 121.664 51.8896C121.845 51.9254 122.032 51.9069 122.202 51.8363C122.372 51.7658 122.517 51.6463 122.619 51.4931C122.722 51.3399 122.776 51.1598 122.776 50.9757C122.776 50.7292 122.677 50.493 122.503 50.3187C122.329 50.1444 122.093 50.0464 121.846 50.0461Z" fill="#1066FF"/>
  <path d="M129.504 50.0461C129.32 50.0459 129.14 50.1004 128.987 50.2025C128.833 50.3047 128.714 50.45 128.643 50.6201C128.573 50.7902 128.554 50.9774 128.59 51.158C128.626 51.3387 128.715 51.5046 128.845 51.6348C128.975 51.765 129.141 51.8537 129.322 51.8896C129.502 51.9254 129.689 51.9069 129.86 51.8363C130.03 51.7658 130.175 51.6463 130.277 51.4931C130.379 51.3399 130.434 51.1598 130.434 50.9757C130.433 50.7292 130.335 50.493 130.161 50.3187C129.987 50.1444 129.75 50.0464 129.504 50.0461Z" fill="#1066FF"/>
  <path d="M106.531 59.5065C107.044 59.5065 107.461 59.0903 107.461 58.577C107.461 58.0636 107.044 57.6475 106.531 57.6475C106.018 57.6475 105.602 58.0636 105.602 58.577C105.602 59.0903 106.018 59.5065 106.531 59.5065Z" fill="#1066FF"/>
  <path d="M114.193 57.6461C114.009 57.6459 113.829 57.7003 113.676 57.8024C113.522 57.9046 113.403 58.0498 113.332 58.2199C113.262 58.3899 113.243 58.577 113.279 58.7576C113.315 58.9382 113.403 59.1042 113.533 59.2344C113.664 59.3647 113.829 59.4534 114.01 59.4894C114.19 59.5254 114.378 59.5071 114.548 59.4366C114.718 59.3662 114.863 59.2469 114.966 59.0939C115.068 58.9408 115.122 58.7608 115.122 58.5767C115.123 58.4546 115.099 58.3336 115.052 58.2207C115.005 58.1078 114.937 58.0052 114.851 57.9188C114.764 57.8323 114.662 57.7638 114.549 57.717C114.436 57.6702 114.315 57.6461 114.193 57.6461Z" fill="#1066FF"/>
  <path d="M121.846 57.6461C121.662 57.6459 121.482 57.7003 121.329 57.8024C121.176 57.9046 121.056 58.0498 120.986 58.2199C120.915 58.3899 120.897 58.577 120.932 58.7576C120.968 58.9382 121.057 59.1042 121.187 59.2344C121.317 59.3647 121.483 59.4534 121.663 59.4894C121.844 59.5254 122.031 59.5071 122.201 59.4366C122.371 59.3662 122.517 59.2469 122.619 59.0939C122.721 58.9408 122.776 58.7608 122.776 58.5767C122.776 58.3301 122.678 58.0936 122.504 57.9191C122.329 57.7446 122.093 57.6464 121.846 57.6461Z" fill="#1066FF"/>
  <path d="M129.504 57.6461C129.32 57.6459 129.14 57.7003 128.987 57.8024C128.833 57.9046 128.714 58.0498 128.643 58.2199C128.573 58.3899 128.554 58.577 128.59 58.7576C128.626 58.9382 128.714 59.1042 128.844 59.2344C128.975 59.3647 129.14 59.4534 129.321 59.4894C129.501 59.5254 129.689 59.5071 129.859 59.4366C130.029 59.3662 130.174 59.2469 130.277 59.0939C130.379 58.9408 130.434 58.7608 130.434 58.5767C130.434 58.3301 130.336 58.0936 130.161 57.9191C129.987 57.7446 129.751 57.6464 129.504 57.6461Z" fill="#1066FF"/>
  <path d="M106.531 67.1116C107.044 67.1116 107.461 66.6955 107.461 66.1821C107.461 65.6687 107.044 65.2526 106.531 65.2526C106.018 65.2526 105.602 65.6687 105.602 66.1821C105.602 66.6955 106.018 67.1116 106.531 67.1116Z" fill="#1066FF"/>
  <path d="M114.193 65.2516C114.009 65.2514 113.829 65.3058 113.676 65.4079C113.522 65.51 113.403 65.6553 113.332 65.8253C113.262 65.9954 113.243 66.1825 113.279 66.3631C113.315 66.5437 113.403 66.7096 113.533 66.8399C113.664 66.9701 113.829 67.0589 114.01 67.0949C114.19 67.1309 114.378 67.1125 114.548 67.0421C114.718 66.9717 114.863 66.8524 114.966 66.6994C115.068 66.5463 115.122 66.3663 115.122 66.1822C115.123 66.0601 115.099 65.9391 115.052 65.8262C115.005 65.7133 114.937 65.6107 114.851 65.5242C114.764 65.4378 114.662 65.3692 114.549 65.3225C114.436 65.2757 114.315 65.2516 114.193 65.2516Z" fill="#1066FF"/>
  <path d="M121.846 65.2516C121.662 65.2514 121.482 65.3058 121.329 65.4079C121.176 65.51 121.056 65.6553 120.986 65.8253C120.915 65.9954 120.897 66.1825 120.932 66.3631C120.968 66.5437 121.057 66.7096 121.187 66.8399C121.317 66.9701 121.483 67.0589 121.663 67.0949C121.844 67.1309 122.031 67.1125 122.201 67.0421C122.371 66.9717 122.517 66.8524 122.619 66.6994C122.721 66.5463 122.776 66.3663 122.776 66.1822C122.776 65.9356 122.678 65.699 122.504 65.5246C122.329 65.3501 122.093 65.2519 121.846 65.2516Z" fill="#1066FF"/>
  <path d="M129.504 65.2516C129.32 65.2514 129.14 65.3058 128.987 65.4079C128.833 65.51 128.714 65.6553 128.643 65.8253C128.573 65.9954 128.554 66.1825 128.59 66.3631C128.626 66.5437 128.714 66.7096 128.844 66.8399C128.975 66.9701 129.14 67.0589 129.321 67.0949C129.501 67.1309 129.689 67.1125 129.859 67.0421C130.029 66.9717 130.174 66.8524 130.277 66.6994C130.379 66.5463 130.434 66.3663 130.434 66.1822C130.434 65.9356 130.336 65.699 130.161 65.5246C129.987 65.3501 129.751 65.2519 129.504 65.2516Z" fill="#1066FF"/>
  <path d="M258.364 40.7225L266.592 40.6116L263.131 35.108L258.364 40.7225Z" fill="#FAF5F0"/>
  </SVG>
  </Container>
}

const SVG = styled.svg`
  width: 100%;

`

const Container = styled.div`
  background-color: #F9FCFF;
  border-radius: 16px;

`;
