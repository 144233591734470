import { Link } from 'react-router-dom'
import { Logo } from '../Components/Logo'
import { useAuthContext } from '../Context/AuthProvider'
import { Profile } from '../Components/Shared/Profile/Profile'
import { Header } from './styles/DefaultHeader.styled'


export const DefaultHeader = () => {
	const { isLoggedin, logout } = useAuthContext()
	return (
		<Header>
			<Link to={isLoggedin ? '/' : '/otp/login'}>
				<Logo />
			</Link>
			{isLoggedin ? <Profile logout={logout} /> : null}
		</Header>
	)
}
