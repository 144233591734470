import { FC, Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthContext } from '../../Context/AuthProvider'
import { useSession } from '../../hooks/useSession'
import { RootState } from '../../store'
import { IProps } from './types'

export const ProtectedRoute: FC<IProps> = ({ children }) => {
	const { pathname } = useLocation()
	const { isLoggedin, logout } = useAuthContext()
	const { getSessionItem: getSession } = useSession()
	const auth = getSession('TOGGGLE_AUTH_TOKEN')

	if (isLoggedin && auth) {
		
		return <Fragment>{children}</Fragment>
	}
	let loginPath = pathname.length > 1 ? `/otp/login?next=${pathname}` : '/otp/login'

	logout()
	return <Navigate to={loginPath} replace={true} />
}

export const ProtectedVeridasRoute: FC<IProps> = ({ children }) => {
	const { veridasAccessToken } = useSelector((state: RootState) => state.user)
	const { isLoggedin, logout } = useAuthContext()
	const { getSessionItem: getSession } = useSession()
	const auth = getSession('TOGGGLE_AUTH_TOKEN')

	if (isLoggedin && auth && veridasAccessToken) {
		return <Fragment>{children}</Fragment>
	}

	logout()
	return <Navigate to={'/otp/login'} replace={true} />
}
