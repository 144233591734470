import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const DEBUG = false

const DETECT_OPTIONS = {
	order: [
		'querystring',
		'cookie',
		'localStorage',
		'sessionStorage',
		'navigator',
		'htmlTag',
		'path',
		'subdomain'
	],
	lookupQuerystring: 'lng',
	lookupCookie: 'i18n',
	lookupLocalStorage: 'i18n',
	lookupSessionStorage: 'i18n',
	lookupFromPathIndex: 0,
	lookupFromSubdomainIndex: 0,
	caches: ['localStorage', 'cookie'],
	exclude: ['cimode'],
	cookieMinutes: 10,
	htmlTag: document.documentElement,
	cookieOptions: { path: '/' }
}

i18n.use(
	resourcesToBackend((language: string, namespace: string) => {
		return import(`../_locales/${language}/translations.json`)
	})
)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init(
		{
			debug: DEBUG,
			detection: DETECT_OPTIONS,
			keySeparator: false,
			returnEmptyString: false,
			fallbackLng: 'en',
			defaultNS: 'en',
			interpolation: {
				escapeValue: false,
				prefix: '{{',
				suffix: '}}'
			}
		},
		() => {}
	)

export default i18n
