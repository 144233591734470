import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { useRequestClientContext } from '../../Context/RequestClientProvider'
import { useNavigate, useParams, useLocation, createSearchParams } from 'react-router-dom'
import { IError, IKYCData, IResponse, IVariables } from './types'
import { setVeridasToken, updateKYCStatus } from '../../store/Reducers/Login'
import { RootState } from '../../store'
import { AxiosResponse, AxiosError } from 'axios'

export const useProcessingKYC = () => {
	let URL = '/kyc';
	const sentRef = useRef(false);
	const [error, setError] = useState<IError | null>(null)
	const [data, setData] = useState<IResponse | undefined>(undefined);
	const dispatch = useDispatch()
	const request = useRequestClientContext()
	const navigate = useNavigate()
	const location = useLocation()
	const { slug } = useParams()
	const { veridasAccessToken } = useSelector((state: RootState) => state.user)

	const { mutate, isLoading, isError} = useMutation<
		IResponse,
		IError,
		IVariables,
		unknown
	>(
		{
			mutationFn: async (
			variables: IVariables
		): Promise<AxiosResponse<IResponse> | AxiosError<IError> | any> => {
			if(!variables.isValid){
				URL = `${URL}/failure`;
			}else{
				URL = `${URL}/success`;
			}
			const response = await request?.post<IResponse, Omit<IVariables, 'isValid'>, IError>(
				variables,
				slug ? `/${slug}${URL}` : URL
			)
			return response
		},
		onError: (error: IError)=>{
			setError(error);
		},
		onSuccess: (data: IResponse)=>{
			setData(data);
		}
	},
		
	)

	useEffect(() => {
		if (veridasAccessToken && !sentRef.current && !error && data === undefined && typeof data !== 'string' && !isLoading) {
			sentRef.current = true;
			let eventData = {};
			let isValidScore= false;
			if(location.state !== 'success'){
				let kycData: IKYCData = JSON.parse(location.state);
				eventData = {
					type: kycData.data.type,
					code: kycData.data.code,
					message: kycData.data.message,
					additionalData: kycData.data.additionalData,
					errorData: kycData.data.type === 'error' ? kycData.data.errorData ? kycData.data.errorData : "" :""
				}
				isValidScore = kycData.isValid;
			}else{
				eventData = {message: location.state};
			}
			mutate({
				accessGrant: veridasAccessToken?.access_token,
				validationId: veridasAccessToken?.validation_id,
				eventData: eventData,
				isValid: isValidScore
			})
		}
	})

	useEffect(() => {
		if (error) {
			dispatch(updateKYCStatus({ error: true, success: false }))
			dispatch(setVeridasToken(null));
			let next = '/error'
			if(slug && slug.length > 0){
				next = `/${slug}/error`
			}
			navigate({
				pathname: next,
				search: createSearchParams(location.search).toString()
			})
		}
	}, [error, navigate, dispatch, slug, location, data, isError])

	return {
		processingKYC: mutate,
		isLoading,
		isError,
		error,
		data
	}
}
