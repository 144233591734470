import { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios'
import axios from 'axios'
import { IRequestUtil } from './types'

const BASE_URL = process.env.REACT_APP_REQUEST_URI || 'https://localhost:4000'

const axiosInstance = axios.create({
	baseURL: BASE_URL
})

axiosInstance.interceptors.response.use(
	(res: AxiosResponse<any, any>) => {
		return res.data
	},
	(error) => {
		throw error?.response.data
	}
)

export const makeRequest = async <TData, TVariables, TError>(
	config: IRequestUtil<TVariables>
): Promise<AxiosResponse<any, TData> | AxiosError<any, TError>> => {
let auth = localStorage.getItem('TOGGGLE_AUTH_TOKEN') ? JSON.parse(localStorage.getItem('TOGGGLE_AUTH_TOKEN') ?? '') : ''
	let requestConfig: AxiosRequestConfig = {
		...config,
		headers: {
			'content-type': 'application/json',
			Authorization: `Bearer ${auth}`,
			accept: 'application/json'
		}
	}
	
	let response = await axiosInstance<
		AxiosResponse<TData> | AxiosError<TError>
	>(requestConfig)
	return response
}

export const get = async <TData, TVariables, TError>(
	payload: TVariables,
	url: string
): Promise<AxiosResponse<TData> | AxiosError<TError>> => {
	let config = {
		url,
		method: 'GET',
		params: payload
	}

	return await makeRequest<TData, TVariables, TError>(config)
}

export const post = async <TData, TVariables, TError>(
	payload: TVariables,
	url: string
): Promise<AxiosResponse<TData> | AxiosError<TError>> => {
	let config = {
		url,
		method: 'POST',
		data: payload
	}
	return await makeRequest<TData, TVariables, TError>(config)
}

export const put = async <TData, TVariables, TError>(
	payload: TVariables,
	url: string
): Promise<AxiosResponse<TData> | AxiosError<TError>> => {
	let config = {
		url,
		method: 'PUT',
		data: payload
	}
	return await makeRequest<TData, TVariables, TError>(config)
}
