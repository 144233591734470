import { useMutation } from '@tanstack/react-query'
import { IVariables, IResponse, IError } from './types'
import { useRequestClientContext } from '../../Context/RequestClientProvider'
import { AxiosResponse, AxiosError } from 'axios'

export const useLoginOTP = () => {
	const URL = '/auth/otp/login'
	const request = useRequestClientContext()

	const { isLoading, error, data, mutate } = useMutation<
		IResponse,
		IError,
		IVariables,
		string
	>(
		async (
			variables: IVariables
		): Promise<AxiosResponse<IResponse> | AxiosError<IError> | any> => {
			return await request?.post<IResponse, IVariables, IError>(
				variables,
				URL
			)
		}
	)

	return {
		login: mutate,
		data,
		loading: isLoading,
		error: error
	}
}
