import { IDocumentType } from '../../hooks/useAvailableDocuments/types'
import { IDropdownData } from './types'

export const useHelpers = () => {
	const splitByCapitalLetters = (label: string): string[] => {
		const splitStrings: string[] = []
		let currentString = ''
		for (let i = 0; i < label.length; i++) {
			const currentChar = label[i]
			if (currentChar === currentChar.toUpperCase()) {
				splitStrings.push(currentString)
				currentString = ''
			}
			currentString += currentChar
		}
		splitStrings.push(currentString)
		return splitStrings
	}

	const filterValidData = (data: IDocumentType[], countryCode: string) => {
		let hasPassport = false
		const invalidDoc = 'XX_XX_XXXX'
		let result: IDropdownData[] = []
		data.filter((doc) => doc.type !== invalidDoc).forEach(
			(documentType) => {
				let short_desc = documentType.type
					.replace(`${countryCode} `, '')
					.split(' ')
				let label = ''
				if (short_desc.length > 0) {
					label = short_desc[0].replace('Card', '')
					if (label.length > 2) {
						label = splitByCapitalLetters(label).join(' ')
					}

					if (label.trim() === 'ID') {
						label = `${label} Card`
					}
				} else {
					label = short_desc[0]
				}

				if (hasPassport) return

				if (label === 'Passport') hasPassport = true

				result.push({
					label,
					data: {
						id: documentType.id,
						required_document_sides:
							documentType.required_document_sides
					}
				})
			}
		)
		return result
	}

	const mapDataToLabel = (
		data: IDocumentType[],
		countryCode: string
	): IDropdownData[] => {
		return filterValidData(data, countryCode)
	}

	return {
		mapDataToLabel
	}
}
