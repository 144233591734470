import { object, string } from 'yup'

export const useHelpers = () => {
	const requiredMsg = ''
	const schema = object({
		email: string()
			.default('')
			.email('Enter a valid email')
			.required(requiredMsg)
	})

	return {
		schema
	}
}
