import { Result } from '../../Components/Shared/Result/Result'
import { ReactComponent as CircleWarnning } from '../../assets/svgs/warnning-circle.svg'
import { useLocation } from 'react-router-dom'

export const ApproveRequestError = () => {
	const { state } = useLocation()

	return (
		<>
			<Result
				Icon={CircleWarnning}
				backgroundTransparent={true}
				hideFooter={false}
				header={`Approve Request`}
				subtext={state.msg === '' ? 'Something went wrong' : state.msg}
			/>
		</>
	)
}
