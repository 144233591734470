import styled from 'styled-components'
import { CSSProps } from './types'

export const Content = styled.div<CSSProps>`
	display: flex;
	flex-direction: column;
	width: ${(props) => (props.fullScreen ? '100%' : 'auto')};
	max-width: ${(props) => (props.fullScreen ? '100%' : '375px')};
	row-gap: 32px;
	align-items: center;
	flex-grow: 1;
	position: relative;
`
