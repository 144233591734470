import { Avatar, Menu, MenuItem } from '@mui/material'
import styled from 'styled-components'

export const CustomAvatar = styled(Avatar)`
	cursor: pointer;
`

export const Actions = styled.div`
	position: relative;
	z-index: 1;
`

export const CustomMenu = styled(Menu)``

export const CustomMenuItem = styled(MenuItem)`
	min-width: 120px;
`
