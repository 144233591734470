import { Typography } from '@mui/material'
import styled from 'styled-components'

interface CssProps {
	isMobile: boolean
	frameLoading?: boolean
	isMandarin?: boolean
}

export const Content = styled.div`
	width: 100%;
	display: flex;
	flex-grow: 1;
`

export const IFrameContent = styled.div`
	display: flex;
	flex-grow: 1;
	/* position: relative; */
`

export const Wrapper = styled.div`
	display: flex;
	flex-grow: 1;
	overflow: hidden;
	position: relative;
`

export const HeaderContainer = styled.div<CssProps>`
	width: 100%;
	@media (max-width: 600px) {
		display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};
	}
`

export const IFrame = styled.iframe<CssProps>`
	width: 100%;
	border: none;
	flex: 1;
	display: ${({ frameLoading }) => (frameLoading ? 'none' : 'flex')};
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	margin-top: ${({ isMandarin }) => (isMandarin ? '-250px' : '-320px')};
	margin-left: 0px;
	@media (max-width: 1024px) {
		position: ${({ isMobile }) => (isMobile ? 'fixed' : 'absolute')};
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		z-index: 999;
		margin-top: 0px;
	}
`

export const Subtext = styled(Typography)`
	color: ${(props) => props.theme.typography.body2};
	font-weight: 500;
`

export const Container = styled.div<CssProps>`
	position: absolute;
	width: 100%;
	display: flex;
	flex: 1;
	height: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const ErrorContainer = styled.div`
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 999;
`

export const Col = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 24px;
`
