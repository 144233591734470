import { FC } from 'react';
import styled from 'styled-components'
import { IProps } from './types'

export const Logo: FC<IProps> = ({ light }) => {

  return  <SVG
        width="97"
        height="24"
        viewBox="0 0 97 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M77.9345 18.356C77.9345 18.4884 78.0418 18.5957 78.1742 18.5957H80.9129C81.0452 18.5957 81.1525 18.4884 81.1525 18.356V0.239655C81.1525 0.107303 81.0452 0 80.9129 0H78.1742C78.0418 0 77.9345 0.107303 77.9345 0.239655V18.356Z"
            fill={light ? "#fff" : "#031131"}
        />
        <path
            d="M7.05013 18.7431C7.4736 18.7431 8.00648 18.6883 8.44444 18.6261C8.56116 18.6094 8.64688 18.5088 8.64688 18.3909V16.1238C8.64688 15.9769 8.51589 15.8648 8.36998 15.8811C8.14962 15.9058 7.94373 15.9181 7.78707 15.9181C6.21497 15.9181 5.52711 15.1812 5.52711 13.8301V8.10645H8.35806C8.49044 8.10645 8.59773 7.99915 8.59773 7.86679V5.47201C8.59773 5.33965 8.49044 5.23234 8.35806 5.23234H5.52711V1.96848C5.52711 1.83612 5.41981 1.72882 5.28744 1.72882H2.54878C2.4164 1.72882 2.30911 1.83612 2.30911 1.96848V3.21802C2.30911 4.51996 1.5476 5.23234 0.24567 5.23234H0.239673C0.107293 5.23234 0 5.33965 0 5.472V7.86679C0 7.99915 0.107293 8.10645 0.239673 8.10645H2.30911V14.0021C2.30911 16.999 4.02866 18.7431 7.05013 18.7431Z"
            fill={light ? "#fff" : "#031131"}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.73317 11.914C9.73317 8.06103 12.8567 4.93756 16.7097 4.93756H23.6879C27.5409 4.93756 30.6644 8.06103 30.6644 11.914C30.6644 15.767 27.5409 18.8905 23.6879 18.8905H16.7097C12.8567 18.8905 9.73317 15.767 9.73317 11.914ZM23.602 15.9917C25.8548 15.9917 27.6811 14.1654 27.6811 11.9126C27.6811 9.65981 25.8548 7.83356 23.602 7.83356C21.3492 7.83356 19.523 9.65981 19.523 11.9126C19.523 14.1654 21.3492 15.9917 23.602 15.9917Z"
            fill={light ? "#fff" : "#031131"}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.4225 17.9324C45.4225 21.4452 42.6959 24 38.8882 24C35.9621 24 33.5889 22.5486 32.6611 20.315C32.6107 20.1937 32.674 20.0569 32.7969 20.0105L35.3347 19.0536C35.4635 19.0051 35.6058 19.0753 35.6588 19.2024C36.1262 20.3217 37.3031 21.0522 38.8882 21.0522C40.8289 21.0522 42.2045 19.8976 42.2045 17.9324V16.4831C41.2711 17.5148 39.9446 18.0553 38.3478 18.0553C34.5894 18.0553 31.8627 15.2303 31.8627 11.4719C31.8627 7.7134 34.5648 4.93756 38.2742 4.93756C39.9937 4.93756 41.3694 5.55168 42.3765 6.77993V5.472C42.3765 5.33964 42.4838 5.23234 42.6161 5.23234H45.1829C45.3152 5.23234 45.4225 5.33964 45.4225 5.472V17.9324ZM35.2035 11.4964C35.2035 13.5599 36.6774 15.0829 38.7409 15.0829C40.7552 15.0829 42.2045 13.5844 42.2045 11.4964C42.2045 9.40839 40.7552 7.88536 38.7409 7.88536C36.702 7.88536 35.2035 9.43296 35.2035 11.4964Z"
            fill={light ? "#fff" : "#031131"}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.9459 24C57.7536 24 60.4802 21.4452 60.4802 17.9324V5.472C60.4802 5.33964 60.3729 5.23234 60.2406 5.23234H57.6739C57.5415 5.23234 57.4342 5.33964 57.4342 5.472V6.77993C56.4271 5.55168 55.0514 4.93756 53.3319 4.93756C49.6225 4.93756 46.9204 7.7134 46.9204 11.4719C46.9204 15.2303 49.6471 18.0553 53.4055 18.0553C55.0023 18.0553 56.3288 17.5148 57.2622 16.4831V17.9324C57.2622 19.8976 55.8866 21.0522 53.9459 21.0522C52.3608 21.0522 51.1839 20.3217 50.7165 19.2024C50.6635 19.0753 50.5213 19.0051 50.3924 19.0536L47.8546 20.0105C47.7317 20.0569 47.6684 20.1937 47.7188 20.315C48.6467 22.5486 51.0198 24 53.9459 24ZM53.7986 15.0829C51.7351 15.0829 50.2612 13.5599 50.2612 11.4964C50.2612 9.43296 51.7597 7.88536 53.7986 7.88536C55.8129 7.88536 57.2622 9.40839 57.2622 11.4964C57.2622 13.5844 55.8129 15.0829 53.7986 15.0829Z"
            fill={light ? "#fff" : "#031131"}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.5379 17.9324C75.5379 21.4452 72.8113 24 69.0037 24C66.0776 24 63.7044 22.5486 62.7765 20.315C62.7261 20.1937 62.7894 20.0569 62.9123 20.0105L65.4501 19.0536C65.579 19.0051 65.7212 19.0753 65.7742 19.2024C66.2416 20.3217 67.4185 21.0522 69.0037 21.0522C70.9443 21.0522 72.3199 19.8976 72.3199 17.9324V16.4831C71.3865 17.5148 70.06 18.0553 68.4632 18.0553C64.7048 18.0553 61.9781 15.2303 61.9781 11.4719C61.9781 7.7134 64.6802 4.93756 68.3896 4.93756C70.1091 4.93756 71.4848 5.55168 72.4919 6.77993V5.472C72.4919 5.33964 72.5992 5.23234 72.7316 5.23234H75.2983C75.4307 5.23234 75.5379 5.33964 75.5379 5.472V17.9324ZM65.319 11.4964C65.319 13.5599 66.7928 15.0829 68.8563 15.0829C70.8706 15.0829 72.3199 13.5844 72.3199 11.4964C72.3199 9.40839 70.8706 7.88536 68.8563 7.88536C66.8174 7.88536 65.319 9.43296 65.319 11.4964Z"
            fill={light ? "#fff" : "#031131"}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95.4582 15.8297C94.5817 17.6357 92.4559 18.8905 89.8281 18.8905C85.7258 18.8905 82.95 15.7953 82.95 11.8895C82.95 7.88537 85.8241 4.93757 89.5826 4.93757C93.734 4.93757 96.0923 7.90993 96.0923 11.4719C96.0923 11.9099 96.0532 12.348 95.9576 12.699C95.9374 12.7736 95.8681 12.8229 95.7907 12.8229H86.2908C86.4874 14.7881 87.9121 16.0655 89.8527 16.0655C91.2332 16.0655 92.2258 15.4621 92.8305 14.5379C92.8998 14.432 93.0363 14.3894 93.1499 14.4451L95.3483 15.5235C95.4625 15.5795 95.5137 15.7153 95.4582 15.8297ZM92.6532 10.3664C92.5057 8.72058 91.2775 7.59059 89.5826 7.59059C87.9612 7.59059 86.7329 8.59775 86.3645 10.3664H92.6532Z"
            fill={light ? "#fff" : "#031131"}
        />
    </SVG>
}

const SVG = styled.svg`
    height: 100%;
`
