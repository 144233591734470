import { useTranslation } from 'react-i18next'

export const useI18n = () => {
	const { t, i18n } = useTranslation()
	const translation = (text: string, extraArgs?: any) => {
		return t(text)
	}
	return {
		translation,
		t,
		i18n
	}
}
