import { LoginOTP } from '../pages/LoginOTP'
import { Layout } from '../Layout/Layout'
import { SelectCountryAndDocumentType } from '../pages/SelectCountryAndDocumentType'
import { ApproveRequest } from '../pages/ApproveRequest'
import {
	ProtectedRoute,
	ProtectedVeridasRoute
} from '../Components/ProtectedRoute'
import { Veridas } from '../pages/Veridas'
import Success from '../pages/Success'
import Error from '../pages/Error'
import { MobileKYC } from '../pages/MobileKYC'
import { ProcessingKYC } from '../pages/ProcessingKYC'
import { ApproveRequestSuccess } from '../pages/ApproveRequestSuccess'
import { ApproveRequestError } from '../pages/ApproveRequestError'
import { DefaultError } from '../pages/DefaultError'
import { Home } from '../pages/Home'
import { OTPVerify } from '../pages/OTPVerify'
import { PublicLayout } from '../PublicLayout'

import { NoAuthSelectCountryAndDocumentType } from '../noauthPages/NoAuthSelectCountryAndDocumentType'
import { NoAuthMobileKYC } from '../noauthPages/NoAuthMobileKYC'
import { NoAuthProcessingKYC } from '../noauthPages/NoAuthProcessingKYC'
import { KycComplete } from '../noauthPages/KycComplete'
import { OTPSent } from '../pages/OTPSent/OTPSent'
import { LoginError } from '../pages/LoginError'

export const routes = [
	{
		element: <PublicLayout />,
		errorElement: <DefaultError />,
		children: [
			{
				path: '/otp/login',
				element: <LoginOTP />
			},
			{
				path: '/otp/login/error',
				element: <LoginError />
			},
			{
				path: '/otp/email',
				element: <OTPSent />
			},
			{
				path: '/otp/verify',
				element: <OTPVerify />
			}
		]
	},
	{
		element: <Layout />,
		errorElement: <DefaultError />,
		children: [
			{
				path: '/:slug/kyc',
				element: <NoAuthSelectCountryAndDocumentType />
			},
			{
				path: '/:slug/perform-kyc',
				element: <Veridas />
			},
			{
				path: '/:slug/error',
				element: <Error />
			},
			{
				path: '/:slug/process-kyc',
				element: <NoAuthProcessingKYC />
			},
			{
				path: '/:slug/success',
				element: <KycComplete />
			},
			{
				path: '/:slug/mobile/kyc-success',
				element: <Success qrKyc={true} />
			},
			{
				path: '/:slug/mobile/kyc',
				element: <NoAuthMobileKYC />
			},
			{
				path: '/:slug/mobile/error',
				element: <Error qrKyc={true} />
			},
			{
				path: '/',
				element: (
					<ProtectedRoute>
						<Home />
					</ProtectedRoute>
				)
			},
			{
				path: '/select-country-and-document-type',
				element: (
					<ProtectedRoute>
						<SelectCountryAndDocumentType />
					</ProtectedRoute>
				)
			},
			{
				path: '/approve-request',
				children: [
					{
						path: ':company_id',
						element: (
							<ProtectedRoute>
								<ApproveRequest />
							</ProtectedRoute>
						)
					},
					{
						path: '/approve-request/success',
						element: (
							<ProtectedRoute>
								<ApproveRequestSuccess />
							</ProtectedRoute>
						)
					},
					{
						path: '/approve-request/error',
						element: (
							<ProtectedRoute>
								<ApproveRequestError />
							</ProtectedRoute>
						)
					}
				]
			},
			{
				path: '/perform-kyc',
				element: (
					<ProtectedVeridasRoute>
						<Veridas />
					</ProtectedVeridasRoute>
				)
			},
			{
				path: '/process-kyc',
				element: (
					<ProtectedVeridasRoute>
						<ProcessingKYC />
					</ProtectedVeridasRoute>
				)
			},
			{
				path: '/success',
				element: (
					<ProtectedVeridasRoute>
						<Success />
					</ProtectedVeridasRoute>
				)
			},
			{
				path: '/error',
				element: (
					<ProtectedVeridasRoute>
						<Error />
					</ProtectedVeridasRoute>
				)
			},
			{
				path: '/mobile/kyc',
				element: <MobileKYC />
			},
			{
				path: '/kyc-success',
				element: <Success qrKyc={true} />
			},
			{
				path: '/kyc-error',
				element: <Error qrKyc={true} />
			},
			
		]
	}
]
