import { FC } from 'react'
import { IProps } from '../SharedTypes'
import { RequestClientProvider } from '../../Context/RequestClientProvider'

const NetworkRequestClientProvider: FC<IProps> = ({ children }) => {
	return (
			<RequestClientProvider>{children}</RequestClientProvider>
	)
}

export default NetworkRequestClientProvider
