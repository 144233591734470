export const validateKYCProcessData = (eventData: any)=>{
    if(eventData && eventData.globalScores){
        const { globalScores: {biometryScores, documentScores, integrityScores }} = eventData;
        
        let validateBiometry = biometryScores.every((entity: any)=>entity.value >= 0.7);
        let validateDocument = documentScores.every((entity: any)=> entity.value >= 0.7);
        let validateIntegrity = integrityScores.every((entity: any)=> entity.value === 1);
        
        return {isValidScore: validateBiometry && validateDocument && validateIntegrity };
    }
    return { isValidScore: false };
}