import styled from 'styled-components'
import { Paper, Typography } from '@mui/material'
export const Row = styled.span`
	display: flex;
	column-gap: 5px;
	width: 100%;
`

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	width: 100%;
`

export const Wrapper = styled(Paper)`
	width: 100%;
	backround: #fff;
	display: grid;
	grid-template-columns: 1fr 1px 1fr;
	grid-template-rows: 1fr;
	padding: 50px;
	column-gap: 60px;
	border-radius: 16px;
	@media (max-width: 600px) {
		width: 100%;
		backround: transparent;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		border-radius: 32px 32px 0px 0px;
		padding: 32px 20px 0px 20px;
	}
`

export const VerticalLine = styled.div`
	width: 1px;
	background: rgba(0, 0, 0, 0.12);
	border-radius: 16px;
	@media (max-width: 600px) {
		display: none;
	}
`

export const Card = styled(Paper)`
	position: relative;
	max-width: 1000px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background: transparent;
	align-items: center;
	min-height: inherit;
	padding: 0px 50px;
	@media (max-width: 600px) {
		min-height: inherit;
		background-color: #fff;
		border-radius: 32px 32px 0px 0px;
		padding: 0px;
	}
`

export const Footer = styled.div`
	width: 100%;
	padding: 20px 0px;
`
export const Text = styled(Typography)`
	font-weight: 400;
	line-height: 24px;
	font-size: 16px;
	letter-spacing: 0.15px;
	color: ${(props) => props.theme.typography.body2};
	@media (min-width: 600px) {
		display: none;
	}
`

export const FormIcon = styled.div`
	margin-left: 100px;
	height: 60px;
	width: 50px;
	@media (max-width: 600px) {
		position: absolute;
		top: 5px;
		width: 36px;
		height: 50px;
	}
`

export const ErrorContainer = styled.div`
	position: absolute;
	width: 100%;
`
