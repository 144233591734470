import { FC } from 'react'
import { Loading } from '../Loading'
import { Card } from '../Shared/Card/Card'
import { IProps } from './types'
import { useI18n } from '../../hooks/useI18n'

export const Middleware: FC<IProps> = ({ loading }) => {
	const { translation } = useI18n()
	return (
		<>
			{loading ? (
				<Card>
					<Loading header={translation('middleware_header')} />
				</Card>
			) : null}
		</>
	)
}
