import { Paper, Typography } from '@mui/material'
import styled from 'styled-components'
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
`
export const List = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 16px;
`
export const CustomRow = styled(Paper)`
	width: 100%;
	display: flex;
	padding: 16px;
	align-items: center;
	column-gap: 16px;
	box-shadow: none;
	border: 1px solid ${({ theme }) => theme.palette.input};
`

export const Icon = styled(BadgeOutlinedIcon)`
	color: ${({ theme }) => theme.palette.primary};
`
export const Text = styled(Typography)`
	color: ${({ theme }) => theme.typography.body2};
	font-weight: 400;
`
