import { useEffect, useMemo, useState } from 'react'
import { useAvailableDocuments } from '../../hooks/useAvailableDocuments'
import { useVeridasAccessToken } from '../../hooks/useGetVeridasAccessToken'
import { useFindUser } from '../../hooks/useFindUser'
import {
	useNavigate,
	useParams,
	useSearchParams,
	createSearchParams
} from 'react-router-dom'
import { setVeridasToken, setDocumentId } from '../../store/Reducers/Login/userSlice'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import {
	CountryAndDocumentType,
	IDocumentData,
	IVariables
} from '../../Components/CountryAndDocumentType'
import { Loading } from '../../Components/Loading'
import { useLoginOTP } from '../../hooks/useLoginOTP'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { UserEmail } from '../../Components/UserEmail'
import { customDataFromQuery } from './helpers'


export const NoAuthSelectCountryAndDocumentType = () => {
	const { loading: findUserLoading, data: userExist } = useFindUser()
	const { data, loading, error, handleVariables } = useAvailableDocuments()
	const { getAccessToken, error: accessTokenError } = useVeridasAccessToken()
	const { data: otpSent, loading: otpSentLoading, login, error: otpSentError } = useLoginOTP()
	const [userEmail, setUserEmail] = useState<string>('');
	const [searchParams, setSearchParams] = useSearchParams()
	const { storeItem, getItem } = useLocalStorage()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { slug } = useParams()
	let email = searchParams.get('email')

	const { veridasAccessToken: accessToken, user } = useSelector(
		(state: RootState) => state.user
	)

	const handleEmail = (email: string)=>{
		setUserEmail(email)
		setSearchParams({email, ...Object.fromEntries(searchParams)})
	}

	const handleVariablesChange = (variables: IVariables) => {
		handleVariables(variables)
	}

	const handleDocumentSelection = (documentData: IDocumentData) => {
		let customData = customDataFromQuery(searchParams, userEmail);
		const variables = {
			documentId: documentData.id,
			slug,
			customData
		}
		getAccessToken(variables)
		dispatch(setDocumentId(documentData.id))
	}

	useEffect(()=>{
		let query = getItem('TOGGGLE_KYC_QUERY')?.query;
		let previousSlug = getItem('TOGGGLE_REFERER_ID')?.companyId;
		if(query){
			const previouseEmail = new URLSearchParams(query).get('email');
			const email = searchParams.get('email');
			query = email !== null && slug && slug.length >0 && previousSlug && previousSlug.length > 0 && slug === previousSlug && email !== previouseEmail ? new URLSearchParams({email, ...Object.fromEntries(searchParams)}) : slug !== previousSlug ? searchParams : query;
			
		}else {
			query = searchParams.toString().length > 0 ? searchParams : '';
		}
		if(query){
			storeItem('TOGGGLE_KYC_QUERY', JSON.stringify({query:query.toString()}))
		}
	},[searchParams, storeItem, getItem, slug])

	useEffect(() => {
		const previousEmail = getItem('TOGGGLE_USER_EMAIL')?.email;
		const auth = getItem('TOGGGLE_AUTH_TOKEN');
		let next = null;
	
		let isUserAlreadyLoggedin = auth !== null ;
		let isUserLoggedinBefore = email && ( email === previousEmail || previousEmail !== email);
		let isOtpRequestAlreadyMade = otpSent === undefined &&  !otpSentLoading && otpSentError === null;
		let sendOtp = (!isUserAlreadyLoggedin && userExist && email && isOtpRequestAlreadyMade && isUserLoggedinBefore && isOtpRequestAlreadyMade) || (isUserAlreadyLoggedin && isOtpRequestAlreadyMade )
		if (email && sendOtp && slug !== 'dlabs' && slug !== 'test') {
			storeItem('TOGGGLE_USER_EMAIL', JSON.stringify({email}));
			storeItem('TOGGGLE_REFERER_ID', JSON.stringify({companyId: slug}));
			login({ email });
		}else if(user && (!email || (email === previousEmail))){
			next = `/approve-request/${slug}`
		}
		
		if(next){
			let queryParams = customDataFromQuery(searchParams);
			navigate({
				pathname: next,
				search: createSearchParams(queryParams).toString()
			})
		}
	}, [userExist, email, otpSent, otpSentError, otpSentLoading,login, searchParams, storeItem, user, getItem, slug, navigate])

	useEffect(() => {
		let previousSlug = getItem('TOGGGLE_REFERER_ID')?.companyId
		if (
			slug &&
			slug.length > 0 &&
			slug !== 'undefined' &&
			previousSlug &&
			previousSlug !== slug &&
			accessToken
		) {
			dispatch(setVeridasToken(null))
			
		} else if (accessToken?.access_token) {
			let customData = customDataFromQuery(searchParams);
			
			let next = {
				pathname: `/${slug}/perform-kyc`,
				search:new URLSearchParams(customData).toString()			
			}
			navigate(next);
		}
		
	}, [navigate, accessToken, slug, searchParams,dispatch, getItem])
	
	useEffect(() => {
		if (otpSent !== undefined && typeof otpSent === 'string') {
			let queryParams: Record<string, string> = {}
			if ((email || userEmail) && slug) {
				queryParams = {
					referer: slug,
					user: email ?? userEmail
				 }
			}
			navigate({
				pathname: '/otp/email',
				search: createSearchParams(queryParams).toString()
			})
		}
	}, [navigate, otpSent, email, slug, userEmail]);

	const showKYCSteps = useMemo(()=>{
		if((email && !findUserLoading && !otpSentLoading && otpSent === undefined) || slug === 'dlabs' || slug === 'test'){
			return true;
		}
		return false;
	}, [email ,findUserLoading ,otpSentLoading ,otpSent, slug])
	
	return (
		<>
			{!email && slug !== 'dlabs' && slug !== 'test' ? <UserEmail email={userEmail} handleEmail={handleEmail}/>: null}
			{findUserLoading || otpSentLoading ? <Loading /> : null}
			{ showKYCSteps ? (
				<CountryAndDocumentType
					loading={loading}
					data={data ? data : []}
					handleVariablesChange={handleVariablesChange}
					error={error || accessTokenError}
					handleDocumentSelection={handleDocumentSelection}
				/>
			) : null}
		</>
	)
}
