import { PopperProps, createFilterOptions } from '@mui/material'
import { useTheme } from 'styled-components'
import { FC, Fragment, useMemo, useState } from 'react'
import countries from '../../Utils/countries.json'
import provinces from '../../Utils/provinces.json'
import { Input } from '../Shared/Input'
import { Card } from '../Shared/Card/Card'
import { CardBody } from '../Shared/CardBody/CardBody'
import { Loading } from '../Loading'
import { ErrorAlert } from '../Shared/ErrorAlert'
import { DocumentCard } from '../Shared/DocumentCard/DocumentCard'
import { IDocumentData, IDropdownData, IVariables } from './types'
import { useHelpers } from './useHelpers'
import { IProps } from './types'
import {
	HeaderContainer,
	Header,
	DocumentList,
	Subtext,
	CustomAutocomplete,
	StyledPopper,
	StyledProvincePopper,
	ErrorContainer
} from './CountryAndDocumentType.styled'
import { useI18n } from '../../hooks/useI18n'

export const CountryAndDocumentType: FC<IProps> = ({
	handleVariablesChange,
	data,
	loading,
	error,
	handleDocumentSelection
}) => {
	const { translation } = useI18n()
	const theme = useTheme()
	const [countryCode, setCountryCode] = useState<string>('')
	const { mapDataToLabel } = useHelpers()

	const handleCountrySelect = (
		event: React.SyntheticEvent<Element, Event>,
		newValue: any
	) => {
		setCountryCode(newValue ? newValue.code : '')
		let variables: IVariables = {
			contryCode: newValue ? newValue.code : ''
		}
		handleVariablesChange(variables)
	}

	const handleProvinceSelect = (
		event: React.SyntheticEvent<Element, Event>,
		newValue: any
	) => {
		let variables: IVariables = {
			contryCode: countryCode,
			provinceCode: newValue.code
		}
		handleVariablesChange(variables)
	}

	const documentSelection = (document: IDocumentData) => {
		handleDocumentSelection(document)
	}

	const dropdownData: IDropdownData[] | null = useMemo(() => {
		if (data && countryCode.length > 0) {
			const dropdownLabels = mapDataToLabel(data, countryCode)
			return dropdownLabels
		} else {
			return null
		}
	}, [data, countryCode, mapDataToLabel])

	const toggleProvinces = useMemo(() => {
		if (countryCode.length > 0) {
			return (
				countryCode === 'AU' ||
				countryCode === 'CA' ||
				countryCode === 'US'
			)
		}
		return false
	}, [countryCode])

	const CountryPopper = (props: PopperProps) => {
		return <StyledPopper {...props} placement={'bottom-start'} />
	}

	const ProvincePopper = (props: PopperProps) => {
		return <StyledProvincePopper {...props} placement={'bottom-start'} />
	}

	const filterOption = createFilterOptions({
		matchFrom: 'start'
	})

	const errorMessage = useMemo(() => {
		if (error && error.errors) {
			return Object.values(error?.errors)[0] as string
		}
		return 'Something went wrong'
	}, [error])

	return (
		<>
			{error ? (
				<ErrorContainer>
					<ErrorAlert message={errorMessage} />
				</ErrorContainer>
			) : null}
			<Card fullScreen={false}>
				<CardBody fullScreen={countryCode ? true : false}>
					<Fragment>
						<HeaderContainer>
							<Header variant="h4">
								{translation('documentSelection')}
							</Header>
							<Subtext
								sx={{
									color: theme.typography.body2
								}}
								variant="body2"
							>
								{translation('countryAndDocumentSubtext')}
							</Subtext>
						</HeaderContainer>
						<CustomAutocomplete
							disablePortal={true}
							PopperComponent={CountryPopper}
							options={countries}
							size={'small'}
							filterOptions={filterOption}
							getOptionLabel={(option: any) => option.label}
							renderInput={(params) => (
								<Input
									{...params}
									error={false}
									variant="filled"
									label={translation('country')}
								/>
							)}
							onChange={handleCountrySelect}
						/>
						{toggleProvinces ? (
							<CustomAutocomplete
								options={
									provinces[
										countryCode as keyof typeof provinces
									]
								}
								autoHighlight
								PopperComponent={ProvincePopper}
								getOptionLabel={(option: any) => option.label}
								renderInput={(params) => (
									<Input
										{...params}
										error={false}
										variant="filled"
										label={translation('provinces')}
									/>
								)}
								onChange={handleProvinceSelect}
							/>
						) : null}
						<DocumentList>
							{loading ? <Loading /> : null}
							{!loading &&
								dropdownData &&
								dropdownData.map((item, index) => (
									<DocumentCard
										key={index}
										document={item}
										handleDocumentSelection={
											documentSelection
										}
									/>
								))}
						</DocumentList>
					</Fragment>
				</CardBody>
			</Card>
		</>
	)
}
