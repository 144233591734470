import { useMemo, FC, useState } from 'react'
import { Alert, AlertTitle, IconButton, AlertColor } from '@mui/material'
import { IProps } from './types'
import { CustomCollapse } from './ErrorAlert.styled'
import CloseIcon from '@mui/icons-material/Close'
import { useI18n } from '../../../hooks/useI18n'

export const ErrorAlert: FC<IProps> = ({
	resetError,
	title,
	message,
	severity
}) => {
	const [open, setOpen] = useState<boolean>(true)
	const { translation } = useI18n()

	const errorMessage = useMemo(() => {
		if (typeof message === 'string') {
			return translation(message)
		} else {
			return Array.isArray(message)
				? translation(message[0].message.split(' ').join(''))
				: translation('defaultError')
		}
	}, [message, translation])

	const handleToggle = () => {
		setOpen(false)
		if (resetError) {
			resetError(null)
		}
	}

	return (
		<CustomCollapse in={open}>
			<Alert
				action={
					<IconButton
						aria-label="close"
						color="inherit"
						size="small"
						onClick={handleToggle}
					>
						<CloseIcon fontSize="inherit" />
					</IconButton>
				}
				severity={severity ? (severity as AlertColor) : 'error'}
			>
				<AlertTitle>{title ? title : 'Error'}</AlertTitle>
				{errorMessage}
			</Alert>
		</CustomCollapse>
	)
}
