import styled from 'styled-components'
import { CSSProps } from './types'
import { Button, Typography } from '@mui/material'

export const Wrapper = styled.div<CSSProps>`
	width: 100%;
	min-height: inherit;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 0px 24px;
	border-radius: ${({ noBtmRadius }) =>
		noBtmRadius ? '32px 32px 0px 0px' : '32px'};
	background: ${(props) =>
		props.backgroundTransparent ? props.theme.palette.background : '#fff'};
	@media (min-width: 600px) {
		width: 100%;
		background: ${(props) =>
			props.backgroundTransparent
				? props.theme.palette.background
				: '#fff'};
		border-radius: 32px 32px 0px 0px;
	}
`

export const Content = styled.div`
	width: 350px;
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	row-gap: 16px;
	padding: 0px 15px;
	@media (max-width: 600px) {
		width: 100%;
	}
`

export const Row = styled.div<CSSProps>`
	display: flex;
	flex-direction: ${(props) => props.arrangment ?? 'row'};
	column-gap: 5px;
	align-items: center;
`

export const CustomButton = styled(Button)`
	font-weight: 600;
	border-radius: 26px;
	padding: ${(props) => (props.variant === 'text' ? '0px' : '8px 22px')};
	font-size: 15px;
	box-shadow: none;
	background: ${(props) =>
		props.variant === 'text' ? 'none' : props.theme.palette.primary};
	color: ${(props) =>
		props.variant === 'text' ? props.theme.palette.primary : '#fff'};
	'.MuiButtonBase-root' {
	}
	&:hover {
		background-color: ${(props) =>
			props.variant === 'text'
				? 'transparent'
				: props.theme.palette.primary};
	}
	text-transform: none;
`

export const Text = styled(Typography)`
	text-align: center;
`

export const SubText = styled(Typography)`
	text-align: center;
	font-size: 16px;
`
