import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
	html {
		min-height: 100%;
	}
	body {
		margin: 0;
		padding: 0;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		width: 100%;
		min-height: 100%;
	}
	*, ::after, ::before {
		box-sizing: border-box;
	}
	a {
		text-decoration: none;
	}

	p, button, h6, div, input {
		font-family: 'Inter', sans-serif !important;
	}

	input {
	 -webkit-text-fill-color: rgba(5,11,20,0.95) !important;
	 -webkit-background-clip: text !important;
	 background-clip:  text !important;
	}

	h3 {
		font-family: 'Plus Jakarta Sans', sans-serif !important;
	}
`;
