import { useMutation } from "@tanstack/react-query";
import { useRequestClientContext } from "../../Context/RequestClientProvider";
import { IVariables, IResponse, IError } from "./types";
import { useParams } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";

export const useShareAccessGrant = ()=>{
	const URL = '/kyc/grant'
	const client = useRequestClientContext();
	const {company_id} = useParams()
	const {data, isLoading, error, reset, mutate} = useMutation<IResponse, IError ,IVariables, unknown>(async (variables: IVariables):Promise<AxiosResponse<IResponse> | AxiosError<IError> | any>=>{
		let slug = company_id;
		return await client?.post(variables, `/${slug}${URL}`)
	});
	return {shareAccessGrant: mutate, data, loading: isLoading, error, reset};
}
