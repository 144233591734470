import { useQuery } from '@tanstack/react-query'
import { useState, useEffect, useMemo } from 'react'
import { useRequestClientContext } from '../../Context/RequestClientProvider'
import { IResponse, IVariables, IError } from './types'
import { AxiosResponse, AxiosError } from 'axios'

export const useAvailableDocuments = () => {
	const URL = '/kyc/document/types'
	const PROVINCE_REQUIRED = useMemo(() => ['US', 'CA', 'AU'], [])
	const request = useRequestClientContext()

	const fetchRequest = async (): Promise<
		AxiosResponse<IResponse> | AxiosError<IError> | any
	> => {
		return await request?.get<IResponse, IVariables, IError>(variables, URL)
	}
	const [variables, setVariables] = useState<IVariables>({
		contryCode: null
	})
	const { data, error, isFetching, refetch } = useQuery<IResponse, IError>({
		queryKey: ['availableDocumentTypes', variables],
		queryFn: fetchRequest,
		enabled: false,
		retry: false,
		cacheTime: 0
	})

	const handleVariables = (newVariables: IVariables) => {
		setVariables({
			...newVariables
		})
	}

	useEffect(() => {
		if (variables.contryCode && !data && !error) {
			if (
				PROVINCE_REQUIRED.indexOf(variables.contryCode) === -1 ||
				(PROVINCE_REQUIRED.indexOf(variables.contryCode) >= 0 &&
					variables.provinceCode &&
					variables.provinceCode.length > 0)
			) {
				refetch()
			}
		}
	}, [variables, refetch, data, error, PROVINCE_REQUIRED])
	return { data, error, loading: isFetching, handleVariables }
}
