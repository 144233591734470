import styled from 'styled-components'
import { Typography } from '@mui/material'

export const Form = styled.form`
	width: 100%;
	display: flex;
	flex-direction: column;
	row-gap: 16px;
`
export const Row = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-end;
	width: 100%;
`

export const IconWrapper = styled.div`
	position: absolute;
	top: -15px;
	right: 0px;
	@media (min-width: 600px) {
		display: none;
	}
`

export const SubText = styled(Typography)``
