import { FC } from 'react'
import { VerifiedFooter } from '../VerifiedFooter/VerifiedFooter'
import { IProps } from './types'
import { Content, Wrapper } from './Card.styled'

export const Card: FC<IProps> = ({
	children,
	fullScreen,
	backgroundTransparent,
	noBtmRadius,
	lightFooter
}) => (
	<Wrapper>
		<Content
			backgroundTransparent={backgroundTransparent}
			fullScreen={fullScreen}
			style={{ ...(noBtmRadius && { borderRadius: '32px 32px 0 0' }) }}
		>
			{children}
			<VerifiedFooter light={lightFooter} />
		</Content>
	</Wrapper>
)
