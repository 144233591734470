import { Button, Typography } from '@mui/material'
import styled from 'styled-components'
import { FormButton } from '../../Components/Shared/Button'

export const Card = styled.div`
	width: 100%;
	min-height: inherit;
	background: ${(props) => props.theme.palette.background};
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	padding: 32px 24px;
	@media (min-width: 600px) {
		background: #fff;
		border-radius: 32px 32px 0px 0px;
	}
`
export const CardBody = styled.div`
	flex-grow: 1;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	align-items: center;
	max-width: 345px;
	background: #fff;
	border-radius: 32px;
	padding: 32px;
`

export const Text = styled(Typography)`
	text-align: center;
`

export const ApproveButton = styled(FormButton)`
	flex-grow: 1;
	@media (max-width: 600px) {
		order: 2;
	}
`

export const DenyButton = styled(Button)`
	flex-grow: 1;
	font-weight: 600;
	border-radius: 24px;
	@media (max-width: 600px) {
		order: 1;
	}
`

export const ActionContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-around;
	gap: 24px;
	@media (min-width: 600px) {
		flex-direction: column;
	}
`

export const ErrorContainer = styled.div`
	position: absolute;
	top: 80px;
	right: 0px;
`
