import { createContext, useContext } from "react";
import { IWebsocket } from "./types";


const SOCKET_INITIAL_VALUE = {
	socket: null
}

export const WsContext = createContext<IWebsocket>(SOCKET_INITIAL_VALUE);

export const useWs = ()=> useContext(WsContext);
