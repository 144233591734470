import styled from 'styled-components'
import { Typography } from '@mui/material'

export const Content = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	row-gap: 20px;
`

export const Subtext = styled(Typography)`
	color: ${(props) => props.theme.typography.body2};
	text-align: center;
`
export const Row = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`
export const TextContent = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	row-gap: 10px;
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 20px;
`
