import { FC } from 'react'
import {
	BannerContainer,
	Text,
	Row,
	LinkText,
	BannerContent
} from './Banner.styled'
import { SelfieIcon, ScanIcon } from '../Icons'
import { Header } from '../Shared/Header'
import { ReactComponent as MobileScanner } from '../../assets/svgs/mobileScanner.svg'
import { IProps } from './types'
import { useI18n } from '../../hooks/useI18n'

export const Banner: FC<IProps> = ({ isSignup }) => {
	const { translation } = useI18n()
	return (
		<BannerContainer>
			{isSignup ? <ScanIcon /> : <SelfieIcon />}
			<Header variant="h3">{translation('bannerHeader')}</Header>
			<BannerContent>
				<Text variant={'body1'}>
					{translation('bannerParagraph_one')}
				</Text>
				<Text variant="body1">
					{translation('bannerParagraph_two')}
				</Text>
			</BannerContent>
			<LinkText target={'_blank'} href="https://togggle.io">
				{translation('landingPageLink')}
			</LinkText>
			{!isSignup ? (
				<Row>
					<MobileScanner />
				</Row>
			) : null}
		</BannerContainer>
	)
}
