import { useEffect } from 'react'
import { useProcessingKYC } from '../../hooks/useProcessingKYC'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useLocation, createSearchParams } from 'react-router-dom'
import {
	updateKyc,
	updateKYCStatus,
	setVeridasToken
} from '../../store/Reducers/Login/userSlice'
import { Middleware } from '../../Components/Middleware'

export const NoAuthProcessingKYC = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const { slug } = useParams()

	const { data } = useProcessingKYC()

	useEffect(() => {
		if (data && data.result === 'failed') {
			dispatch(setVeridasToken(null))
			navigate({pathname: `/${slug}/error`, search: createSearchParams(location.search).toString()})
		}
		if (typeof data === 'string') {
			dispatch(updateKyc(true))
			dispatch(setVeridasToken(null))
			dispatch(updateKYCStatus({ error: false, success: true }))
			navigate({pathname: `/${slug}/success`, search: createSearchParams(location.search).toString()})
		}
	}, [data, dispatch, navigate, slug, location])

	return <Middleware loading={true} />
}
