import styled from 'styled-components'
import { CSSProps } from './types'
export const Container = styled.div`
	display: grid;
	grid-template-rows: 80px auto;
	grid-template-columns: 1fr;
	background-color: ${(props) => props.theme.palette.primary};
`
export const Header = styled.header`
	height: 80px;
	display: flex;
	align-items: center;
	padding: 0px 50px;
	justify-content: space-between;
	@media (max-width: 600px) {
		padding: 0px 20px;
	}
`

export const Main = styled.main<CSSProps>`
	min-height: calc(100vh - 80px);
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 600px) {
		min-height: ${({ isMobile }) =>
			isMobile ? 'calc(95vh - 80px)' : 'calc(100vh - 80px)'};
	}
`
