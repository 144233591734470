import { FC, Fragment, useEffect, useMemo } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { Header } from '../../Components/Shared/Header'
import { CardBody } from '../../Components/Shared/CardBody/CardBody'
import { LoginOTPForm } from '../../Components/LoginOTPForm'
import { useLoginOTP } from '../../hooks/useLoginOTP'
import { Typography } from '@mui/material'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import {
	Card,
	Footer,
	Text,
	HeaderContainer,
	Wrapper,
	FormIcon,
	VerticalLine,
	ErrorContainer
} from './LoginOTP.styled'
import { Banner } from '../../Components/Banner'
import { Message } from '../../Components/Icons'
import { VerifiedFooter } from '../../Components/Shared/VerifiedFooter/VerifiedFooter'
import { useMediaQuery } from '@mui/material'
import { ErrorAlert } from '../../Components/Shared/ErrorAlert'
import { useI18n } from '../../hooks/useI18n'

export const LoginOTP: FC = () => {
	const { translation } = useI18n()
	const theme = useTheme()
	const navigate = useNavigate()
	const match = useMediaQuery('(max-width: 600px)')
	const { storeItem } = useLocalStorage()
	const { login, error, loading, data } = useLoginOTP()
	const [searchParams] = useSearchParams()
	const companyId = searchParams.get('company_id')

	useEffect(() => {
		const itemToStore = JSON.stringify({ companyId })
		storeItem('TOGGGLE_REFERER_ID', itemToStore)
	}, [storeItem, companyId])

	const errorMessage = useMemo(() => {
		if (error && error.errors) {
			return Object.values(error?.errors)[0]
		}
		return 'Something went wrong'
	}, [error])

	const handleLogin = (payload: any) => {
		login(payload)
	}

	useEffect(()=>{
		if(data !== undefined && typeof data === 'string'){
			navigate('/otp/email')
		}
	},[navigate, data])

	return (
		<Fragment>
			<Card elevation={0}>
				<Wrapper elevation={0}>
					<CardBody fullScreen={true}>
						{error ? (
							<ErrorContainer>
								<ErrorAlert message={errorMessage} />
							</ErrorContainer>
						) : null}
						<HeaderContainer>
							<FormIcon>
								<Message />
							</FormIcon>
							<Header variant="h3">{translation('login')}</Header>
							<Text variant={'body1'}>
								{translation('mobileSubtext')}
							</Text>
							<Typography
								sx={{
									color: theme.typography.body2
								}}
								variant="body1"
							>
								{translation('loginSubtext')}
							</Typography>
							<Typography variant="body1">
								Please input the email address for OTP
								verification
							</Typography>
						</HeaderContainer>
						<LoginOTPForm
							loading={loading}
							handleLogin={handleLogin}
						/>
					</CardBody>
					<VerticalLine />
					<Banner />
				</Wrapper>
				<Footer>
					<VerifiedFooter light={true && !match} />
				</Footer>
			</Card>
		</Fragment>
	)
}
