import { FormButton } from '../../Components/Shared/Button'
import { SubHeader } from '../../Components/Shared/SubHeader/SubHeader'
import { Content, Subtext, Card } from './DefaultError.styled'
import { logout } from '../../store/Reducers/Login'
import { useDispatch } from 'react-redux'
import { useSession } from '../../hooks/useSession'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as CircleWarnning } from '../../assets/svgs/warnning-circle.svg'
import { isMobile } from 'react-device-detect'
import { VerifiedFooter } from '../../Components/Shared/VerifiedFooter/VerifiedFooter'
import { useI18n } from '../../hooks/useI18n'

export const DefaultError = () => {
	const { translation } = useI18n()
	const navigate = useNavigate()
	const { closeSession } = useSession()
	const dispatch = useDispatch()

	const redirect = () => {
		closeSession()
		dispatch(logout())
		navigate('/otp/login')
	}

	return (
		<Card isMobile={isMobile}>
			<Content>
				<CircleWarnning />
				<SubHeader text={translation('pageNotFound')} />
				<Subtext variant={'body1'}>
					{translation('defaultErrorSubtext')}
				</Subtext>
				<FormButton onClick={redirect}>
					{translation('startOver')}
				</FormButton>
			</Content>
			<VerifiedFooter />
		</Card>
	)
}
