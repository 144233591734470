import { useTheme } from 'styled-components'
import { SubHeader } from '../SubHeader/SubHeader'
import { IProps, ButtonVariant } from './types'
import { FC, useCallback } from 'react'
import { VerifiedFooter } from '../VerifiedFooter/VerifiedFooter'
import { Link } from 'react-router-dom'
import {
	Content,
	CustomButton,
	Row,
	SubText,
	Wrapper,
	Text
} from './Result.styled'
import { ReactComponent as CircleCheck } from '../../../assets/svgs/check-circle.svg'
import { Phone } from '../../Icons'

export const Result: FC<IProps> = ({
	SVGIcon,
	Icon,
	button,
	header,
	subtext,
	backgroundTransparent,
	hideFooter,
	noBtmRadius,
	subHeader,
	kycError,
	extraComponent
}) => {
	const theme = useTheme()
	const getButton = useCallback(() => {
		if (button) {
			if (button?.isLink) {
				if (button?.isLink?.isButton) {
					return (
						<Link to={button.link ?? ''}>
							<CustomButton>{button.text}</CustomButton>
						</Link>
					)
				} else {
					return <Link to={button.link ?? ''}>{button.text}</Link>
				}
			} else {
				return (
					<CustomButton
						variant={
							button.variant || button.filled
								? (button.variant as ButtonVariant)
								: 'text'
						}
						onClick={button.callback}
					>
						{button.text}
					</CustomButton>
				)
			}
		} else {
			return null
		}
	}, [button])

	return (
		<Wrapper {...{ backgroundTransparent, noBtmRadius }}>
			<Content>
				{kycError ? <Phone isError={kycError} /> : Icon? <Icon /> : SVGIcon ? <SVGIcon /> : <CircleCheck />}
				{header ? (
					<SubHeader text={header ? header : 'Successfull'} />
				) : null}
				{subHeader ? (
					<Text sx={{ color: theme.typography.body2 }} variant="h5">
						{subHeader}
					</Text>
				) : null}
				{subtext ? (
					<SubText
						sx={{ color: theme.typography.body2 }}
						variant="body1"
					>
						{subtext}
					</SubText>
				) : null}
				<Row arrangment={button?.arrangment}>
					{button?.subtext ? (
						<SubText sx={{ color: theme.typography.body2 }}>
							{button?.subtext}
						</SubText>
					) : null}
					{getButton()}
				</Row>
				{extraComponent ? extraComponent : null}
			</Content>
			{!hideFooter ? (
				<div style={{ paddingBottom: 45 }}>
					<VerifiedFooter />
				</div>
			) : null}
		</Wrapper>
	)
}
