import { FC, Fragment, useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Card } from '../../Components/Shared/Card/Card'
import { useTheme } from 'styled-components'
import { Header } from '../../Components/Shared/Header'
import { CardBody } from '../../Components/Shared/CardBody/CardBody'
import { useVerifyOTP } from '../../hooks/useVerifyOTP'
import { ErrorAlert } from '../../Components/Shared/ErrorAlert'
import {
	HeaderContainer,
	SubText,
	ErrorContainer,
	Form,
	ButtonContainer
} from './OTPVerify.styled'
import { useI18n } from '../../hooks/useI18n'
import { useHelpers } from './useHelper'
import { yupResolver } from '@hookform/resolvers/yup'
import { IForm } from './types'
import { Input } from '../../Components/Shared/Input'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { LoadingButton } from '../../Components/Shared/LoadingButton'
import { FormButton } from '../../Components/Shared/Button'
import { FormControl, FormHelperText } from '@mui/material'
import { useLocalStorage } from '../../hooks/useLocalStorage'

export const OTPVerify: FC = () => {
	const { translation } = useI18n()
	const theme = useTheme()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const tokenErrorMsg = translation('invalidLink')
	const [tokenError, setTokenError] = useState<boolean>(false)
	const { verify, error, loading, data } = useVerifyOTP()
	const { schema } = useHelpers()
	const token = searchParams.get('token')
	const { getItem } = useLocalStorage()
	const {
		watch,
		formState: { errors },
		control,
		handleSubmit
	} = useForm<IForm>({
		resolver: yupResolver(schema),
		mode: 'all'
	})

	let passphrase = watch('passphrase')

	const onSubmit: SubmitHandler<IForm> = (data) => {
		if (token) {
			let payload = {
				passphrase: data.passphrase,
				otp: token
			}
			verify(payload)
		}
	}

	useEffect(() => {
		if (!token) {
			setTokenError(true)
		}
	}, [token])

	useEffect(() => {
		if (data) {
			const slug = getItem('TOGGGLE_REFERER_ID')?.companyId
			let next = '/'
			
			if (slug) {
				next = `/approve-request/${slug}`
			}
			
			navigate(next)
		}
	}, [data, navigate, getItem])

	useEffect(() => {
		if(error){
			let reason = null;
			if(error?.statusCode === 422 || error.status === 422){
				reason = 'The verification link is expired';
			}
			navigate( '/otp/login/error', {
				state: reason
			});
		}
	}, [error, navigate])

	const redirect = () => {
		navigate('/otp/login')
	}

	return (
		<Fragment>
			{tokenError ? (
				<ErrorContainer>
					<ErrorAlert message={tokenErrorMsg} />
				</ErrorContainer>
			) : null}

			<Card fullScreen={false}>
				<CardBody fullScreen={true}>
					<HeaderContainer>
						<Header>{translation('Enter passphrase')}</Header>
						<SubText sx={{ color: theme.typography.body2 }}>
							{translation(
								'Enter your encryption passphrase to access data in the browser'
							)}
						</SubText>
					</HeaderContainer>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<FormControl>
							<Controller
								name="passphrase"
								control={control}
								rules={{ required: true }}
								render={({
									field: { ref, value, onChange, ...props }
								}) => (
									<Input
										error={errors.passphrase ? true : false}
										{...props}
										inputRef={ref}
										value={value}
										onChange={onChange}
										variant="filled"
										label="Passphrase"
										type="text"
										multiline
										maxRows={3}
									/>
								)}
							/>
							{errors.passphrase ? (
								<FormHelperText
									sx={{ color: theme.palette.error }}
								>
									{errors.passphrase?.message}
								</FormHelperText>
							) : null}
						</FormControl>
						<LoadingButton
							disabled={
								loading ||
								!passphrase ||
								passphrase.length === 0 ||
								errors.passphrase
									? true
									: false
							}
							loading={loading}
							type="submit"
							text={translation('Continue')}
						/>
					</Form>
					{tokenError ? (
						<ButtonContainer>
							<FormButton
								sx={{ width: '100%' }}
								onClick={redirect}
							>
								Login
							</FormButton>
						</ButtonContainer>
					) : null}
				</CardBody>
			</Card>
		</Fragment>
	)
}
