import { useEffect, useMemo, useCallback } from 'react'
import { useProcessingKYC } from '../../hooks/useProcessingKYC'
import { useDispatch } from 'react-redux'
import { useShareAccessGrant } from '../../hooks/useShareAccessGrant'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { useNavigate } from 'react-router-dom'
import {
	setVeridasToken,
	updateKyc,
	updateKYCStatus
} from '../../store/Reducers/Login/userSlice'
import { Middleware } from '../../Components/Middleware'

export const ProcessingKYC = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const {
		data: processingKYCData,
		isLoading
	} = useProcessingKYC()
	const { shareAccessGrant, loading, data, error } = useShareAccessGrant()
	const { getItem } = useLocalStorage()
	let companyId = getItem('TOGGGLE_REFERER_ID')?.companyId

	const approveRequest = useCallback(() => {
		
		if (companyId) {
			shareAccessGrant({
				status: true
			})
		}
	}, [shareAccessGrant, companyId])

	useEffect(() => {
		if (
			processingKYCData &&
			processingKYCData?.result !== 'failed' &&
			!data &&
			!error
		) {
			approveRequest()
		}
		if (processingKYCData?.result === 'failed') {
			navigate('/error')
		}

		if (processingKYCData && !companyId) {
			dispatch(updateKyc(true))
			dispatch(updateKYCStatus({ error: false, success: true }))
			navigate('/success')
		}
	}, [
		processingKYCData,
		companyId,
		approveRequest,
		data,
		error,
		dispatch,
		navigate
	])

	useEffect(() => {
		if (data) {
			dispatch(updateKyc(true))
			dispatch(updateKYCStatus({ error: false, success: true }))
			navigate('/approve-request/success')
		}
	}, [data, navigate, dispatch])

	const errorReason = useMemo(() => {
		if (typeof error?.errors === 'string') {
			return error?.errors
		} else if (error) {
			return error?.errors
				? Object.values(error?.errors)[0]
				: 'Something went wrong'
		} else {
			return null
		}
	}, [error])

	const loader = useMemo(() => {
		return isLoading || (loading && !data && !error)
	}, [isLoading, loading, data, error])

	useEffect(() => {
		if (errorReason) {
			dispatch(setVeridasToken(null))
			navigate('/approve-request/error', {
				state: {
					msg: errorReason
				}
			})
		}
	}, [errorReason, navigate, dispatch])

	return <Middleware loading={loader} />
}
