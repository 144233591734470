import { WsContext } from './useWsContext'
import { FC, useMemo} from 'react'
import { IProps } from './types'
import { io, Socket } from 'socket.io-client'
import { useSession } from '../../hooks/useSession'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

export const WsProvider: FC<IProps> = ({ children }) => {
	const { veridasAccessToken } = useSelector((state: RootState)=> state.user);
	// const [socket, setSocket] = useState<Socket|null>(null);
	const { getSessionItem: getSession } = useSession()
	const socketURI =  process.env.REACT_APP_SOCKET_URI || 'https://dev1-kyc.api.togggle.io'

	const socket = useMemo(() => {
		const auth = getSession('TOGGGLE_AUTH_TOKEN')
		
		let _socket: Socket|null = null;
		if (veridasAccessToken && socketURI) {
			_socket = io(socketURI, {
				extraHeaders: {
					authorization: `Bearer ${auth}`
				},
				withCredentials: true,
				transports: ['websocket', 'polling']
			})
			
			_socket.on('connect', () => {
				// if(!socket && _socket){
				// 	setSocket(_socket);
				// }
			})
		}
		return _socket;
	}, [veridasAccessToken, socketURI, getSession])																																																																																																																																																																															

	return (
		<WsContext.Provider value={{ socket }}>{children}</WsContext.Provider>
	)
}
