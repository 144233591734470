import { FormControl, FormHelperText } from '@mui/material'
import { FC } from 'react'
import { useTheme } from 'styled-components'
import { Input } from '../Shared/Input'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { IForm, IProps } from './types'
import { Form, Row, IconWrapper } from './LoginOTPForm.styled'
import { ReactComponent as MobileScanner } from '../../assets/svgs/mobileScanner.svg'
import { LoadingButton } from '../Shared/LoadingButton'
import { useI18n } from '../../hooks/useI18n'

export const LoginOTPForm: FC<IProps> = ({ loading, handleLogin }) => {
	const { translation } = useI18n()
	const theme = useTheme()
	const formInitialValues = {
		email: ''
	}
	const {
		formState: { errors },
		watch,
		control,
		handleSubmit
	} = useForm<IForm>({
		defaultValues: formInitialValues,
		mode: 'all'
	})
	const email = watch('email')

	const onSubmit: SubmitHandler<IForm> = (data) => {
		handleLogin(data)
	}

	return (
		<Form
			autoSave="off"
			autoComplete="off"
			onSubmit={handleSubmit(onSubmit)}
		>
			<FormControl>
				<Controller
					name="email"
					control={control}
					rules={{ required: true }}
					render={({ field: { ref, onChange, value, ...props } }) => {
						return (
							<Input
								error={errors.email ? true : false}
								{...props}
								onChange={onChange}
								value={value}
								variant="filled"
								label={translation('email')}
								type="email"
								inputRef={ref}
							/>
						)
					}}
				/>
				{errors.email ? (
					<FormHelperText sx={{ color: theme.palette.error }}>
						Enter a valid email
					</FormHelperText>
				) : null}
			</FormControl>

			<LoadingButton
				disabled={
					loading || !email || Object.keys(errors).length > 0
						? true
						: false
				}
				text={translation('Access with email')}
				loading={loading}
				type="submit"
			/>
			<Row>
				<IconWrapper>
					<MobileScanner />
				</IconWrapper>
			</Row>
		</Form>
	)
}
