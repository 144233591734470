import { Button } from '@mui/material'
import styled from 'styled-components'

export const FormButton = styled(Button)(({ disabled, theme }) => ({
	borderRadius: '24px',
	color: '#fff',
	boxShadow: 'none',
	background: disabled ? theme.typography.body2 : theme.palette.primary,
	fontWeight: '600',
	padding: '8px 22px',
	textTransform: 'capitalize',
	'&.Mui-disabled': {
		color: '#fff'
	},
	'&:hover': {
		color: '#fff',
		boxShadow: 'none',
		background: disabled ? theme.typography.body2 : theme.palette.primary
	}
}))
