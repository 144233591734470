import { useEffect, useState } from 'react'
import { IError, IResponse } from './types'
import { useQuery } from '@tanstack/react-query'
import { useRequestClientContext } from '../../Context/RequestClientProvider'
import { useDispatch } from 'react-redux'
import {
	setVeridasToken,
	updateKYCStatus
} from '../../store/Reducers/Login/userSlice'
import { useSearchParams } from 'react-router-dom'
import { AxiosResponse, AxiosError } from 'axios'

export const useCompleteKYC = () => {
	const dispatch = useDispatch()
	const request = useRequestClientContext()
	const [searchParams, setSearchParams] = useSearchParams()
	const [url, setUrl] = useState<string>('')
	const { error, isRefetching, refetch, data } = useQuery<IResponse, IError>(
		['completeKyc'],

		async (): Promise<
			AxiosResponse<IResponse> | AxiosError<IError> | any
		> => {
			return await request?.get<IResponse, any, IError>({}, url)
		},
		{
			enabled: false
		}
	)

	 useEffect(() => {
	 	const userId = searchParams.get('id')

	 	if (url.length === 0 && userId) {
	 		setSearchParams({id: userId});
	 		setUrl(`/kyc-inquiry/${userId}`)
	 	}
	 }, [searchParams, setUrl, url, setSearchParams])

	useEffect(() => {
		const userId = searchParams.get('id')
		if ( url.length > 0 && !data && !error && userId) {
			refetch()
		}
	}, [url, data, error, refetch, searchParams])

	useEffect(() => {
		if (data) {
			dispatch(
				setVeridasToken({
					access_token: data.accessToken,
					validation_id: data.validationId
				})
			)
			dispatch(updateKYCStatus({ error: false, success: true }))
		}
	}, [dispatch, data])

	return {
		error,
		isLoading: isRefetching
	}
}
