import styled from 'styled-components'

export const ScanIcon = ()=>{
  return<Container>
      <SVG width="343" height="222" viewBox="0 0 343 222" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1088_32456)">
            <rect width="343" height="222" rx="16" fill="#F9FCFF"/>
            <path d="M212.647 28.0464L128.546 27.6491C123.379 27.6247 119.171 31.7934 119.147 36.9602L118.452 183.961C118.428 189.128 122.597 193.336 127.763 193.361L211.864 193.758C217.03 193.782 221.239 189.614 221.263 184.447L221.958 37.4458C221.982 32.2791 217.813 28.0708 212.647 28.0464Z" fill="url(#paint0_linear_1088_32456)" fillOpacity="0.4"/>
            <path d="M216.709 28.0643L132.608 27.6671C127.441 27.6426 123.233 31.8113 123.209 36.9781L122.514 183.979C122.49 189.146 126.659 193.354 131.825 193.379L215.926 193.776C221.093 193.8 225.301 189.632 225.325 184.465L226.02 37.4638C226.044 32.297 221.875 28.0887 216.709 28.0643Z" fill="#1066FF"/>
            <path d="M212.432 31.9399L136.85 31.5829C131.684 31.5585 127.475 35.7272 127.451 40.8939L126.807 177.135C126.783 182.301 130.952 186.51 136.118 186.534L211.7 186.891C216.867 186.915 221.075 182.747 221.1 177.58L221.743 41.3394C221.768 36.1726 217.599 31.9643 212.432 31.9399Z" fill="#FAF5F0"/>
            <path d="M160.123 44.1773L142.64 44.0947C141.902 44.0912 141.192 44.3813 140.667 44.9012C140.142 45.4211 139.845 46.1281 139.842 46.8668L139.759 64.3506C139.757 64.7201 139.903 65.0751 140.163 65.3376C140.423 65.6 140.776 65.7485 141.146 65.7502C141.515 65.7518 141.87 65.6066 142.132 65.3466C142.394 65.0866 142.543 64.7331 142.545 64.3638L142.614 49.6667C142.617 48.9282 142.914 48.2213 143.439 47.7016C143.963 47.1818 144.673 46.8918 145.412 46.8953L160.109 46.9647C160.292 46.966 160.474 46.931 160.644 46.8619C160.813 46.7928 160.968 46.6908 161.098 46.5619C161.228 46.433 161.332 46.2796 161.403 46.1106C161.474 45.9415 161.511 45.7602 161.511 45.5769C161.512 45.3938 161.477 45.2123 161.407 45.0428C161.338 44.8733 161.236 44.7192 161.107 44.5892C160.978 44.4592 160.825 44.3559 160.656 44.2852C160.487 44.2145 160.306 44.1779 160.123 44.1773Z" fill="#27277A"/>
            <path d="M204.409 44.3866L186.926 44.304C186.743 44.3031 186.562 44.3383 186.392 44.4075C186.223 44.4767 186.069 44.5786 185.939 44.7074C185.809 44.8361 185.706 44.9892 185.635 45.1579C185.565 45.3266 185.528 45.5075 185.527 45.6904C185.525 46.0597 185.67 46.4145 185.93 46.6768C186.19 46.9392 186.544 47.0875 186.913 47.0893L201.61 47.1587C201.976 47.1603 202.338 47.2341 202.675 47.3757C203.013 47.5173 203.319 47.724 203.577 47.9841C203.834 48.2441 204.038 48.5523 204.176 48.8911C204.314 49.2299 204.385 49.5926 204.383 49.9586L204.313 64.6557C204.312 64.8386 204.347 65.0199 204.417 65.1892C204.486 65.3585 204.588 65.5126 204.716 65.6426C204.845 65.7725 204.998 65.8759 205.167 65.9467C205.335 66.0175 205.516 66.0544 205.699 66.0552C206.068 66.0568 206.423 65.9116 206.685 65.6516C206.948 65.3917 207.096 65.0382 207.098 64.6688L207.18 47.185C207.182 46.8193 207.112 46.4568 206.974 46.1182C206.835 45.7796 206.632 45.4716 206.374 45.2117C206.117 44.9519 205.811 44.7452 205.474 44.6037C205.137 44.4621 204.775 44.3883 204.409 44.3866Z" fill="#27277A"/>
            <path d="M159.488 116.118L144.791 116.049C144.425 116.047 144.064 115.973 143.726 115.832C143.389 115.69 143.083 115.484 142.826 115.224C142.568 114.964 142.364 114.656 142.226 114.318C142.088 113.979 142.017 113.617 142.019 113.251L142.089 98.5538C142.089 98.3709 142.054 98.1896 141.985 98.0203C141.916 97.8509 141.814 97.6969 141.686 97.5669C141.557 97.4369 141.404 97.3336 141.235 97.2628C141.067 97.192 140.886 97.1551 140.703 97.1542C140.52 97.1534 140.339 97.1885 140.169 97.2578C140 97.327 139.846 97.4289 139.716 97.5576C139.586 97.6864 139.483 97.8394 139.412 98.0081C139.342 98.1768 139.305 98.3577 139.304 98.5406L139.221 116.023C139.22 116.389 139.29 116.751 139.428 117.09C139.566 117.428 139.77 117.736 140.027 117.996C140.285 118.256 140.591 118.463 140.928 118.604C141.265 118.746 141.627 118.82 141.993 118.821L159.475 118.904C159.839 118.898 160.186 118.749 160.442 118.49C160.697 118.231 160.841 117.882 160.843 117.518C160.845 117.154 160.704 116.804 160.451 116.542C160.198 116.281 159.852 116.129 159.488 116.119L159.488 116.118Z" fill="#27277A"/>
            <path d="M205.556 97.4596C205.373 97.4588 205.192 97.494 205.022 97.5632C204.853 97.6324 204.699 97.7343 204.569 97.863C204.439 97.9918 204.336 98.1449 204.265 98.3135C204.195 98.4822 204.158 98.6632 204.157 98.8461L204.088 113.543C204.084 114.282 203.787 114.989 203.262 115.508C202.737 116.028 202.028 116.318 201.289 116.315L186.592 116.245C186.228 116.252 185.881 116.4 185.626 116.659C185.37 116.919 185.226 117.267 185.224 117.631C185.222 117.995 185.363 118.346 185.616 118.607C185.87 118.868 186.215 119.02 186.579 119.03L204.062 119.113C204.428 119.115 204.79 119.044 205.129 118.906C205.467 118.768 205.775 118.564 206.035 118.306C206.295 118.049 206.501 117.743 206.643 117.406C206.784 117.068 206.858 116.707 206.86 116.341L206.942 98.8592C206.944 98.4898 206.799 98.1348 206.539 97.8723C206.279 97.6098 205.925 97.4614 205.556 97.4596Z" fill="#27277A"/>
            <path d="M173.813 112.445C190.397 112.523 203.905 99.1421 203.983 82.5578C204.062 65.9734 190.681 52.4656 174.097 52.3872C157.512 52.3089 144.004 65.6897 143.926 82.274C143.848 98.8584 157.228 112.366 173.813 112.445Z" fill="white"/>
            <path d="M173.889 108.366C188.145 108.433 199.755 96.9316 199.823 82.6762C199.89 68.4208 188.388 56.8099 174.133 56.7426C159.878 56.6753 148.267 68.177 148.199 82.4324C148.132 96.6878 159.634 108.299 173.889 108.366Z" fill="#1066FF"/>
            <path d="M171.529 53.4427L171.529 53.4427L171.561 53.4458L171.592 53.4391C181.801 51.241 189.855 56.5369 194.29 61.0707C195.371 62.1763 196.118 63.7344 196.636 65.5534C197.153 67.3709 197.438 69.4336 197.605 71.5341C197.766 73.5519 197.818 75.5969 197.866 77.486C197.868 77.5632 197.87 77.6401 197.872 77.7167C197.921 79.6651 197.971 81.4434 198.141 82.8256C198.472 85.5159 197.584 89.0732 196.596 91.9825C196.104 93.432 195.591 94.7114 195.201 95.6284C195.006 96.0868 194.842 96.4544 194.727 96.7071C194.669 96.8335 194.624 96.9311 194.593 96.997L194.564 97.0569C194.56 97.0603 194.555 97.064 194.55 97.0681C194.518 97.0937 194.469 97.132 194.403 97.1804C194.269 97.2773 194.067 97.4151 193.793 97.5745C193.245 97.8934 192.412 98.2993 191.281 98.6398C189.022 99.3202 185.565 99.7414 180.804 98.6734C176.011 97.5983 171.576 96.3786 168.018 94.4674C164.467 92.5593 161.804 89.9687 160.534 86.1521C159.251 82.2984 158.893 78.598 158.856 75.8596C158.838 74.4909 158.899 73.3638 158.966 72.5797C158.999 72.1877 159.033 71.8815 159.059 71.6739C159.072 71.57 159.083 71.4909 159.09 71.4379C159.094 71.4114 159.097 71.3915 159.099 71.3784L159.101 71.3637L159.102 71.3602L159.102 71.3595L159.102 71.3593L159.102 71.3593L159.119 71.2499L159.038 71.1754L159.038 71.1754L159.037 71.1753L159.037 71.1752L159.036 71.1737L159.028 71.1662C159.02 71.1592 159.009 71.1483 158.994 71.1334C158.964 71.1036 158.92 71.0582 158.864 70.9972C158.753 70.8752 158.596 70.6913 158.419 70.4471C158.065 69.9585 157.63 69.2297 157.312 68.273C156.677 66.3656 156.497 63.5308 158.38 59.8532C160.252 56.1992 163.526 54.5946 166.354 53.904C167.767 53.5588 169.063 53.4435 170.006 53.4146C170.477 53.4002 170.86 53.4073 171.124 53.4181C171.256 53.4234 171.358 53.4297 171.427 53.4345C171.461 53.4369 171.487 53.439 171.504 53.4405L171.524 53.4422L171.528 53.4426L171.529 53.4427L171.529 53.4427Z" fill="#D0DCF0" stroke="#031131" strokeWidth="0.410181"/>
            <path d="M156.828 65.9474C156.823 65.8538 156.821 65.8022 156.821 65.8022C156.819 65.8507 156.822 65.8993 156.828 65.9474Z" fill="#27277A"/>
            <path d="M192.165 81.051L192.165 81.051C193.19 87.0365 196.082 90.0424 198.493 91.9541C198.985 92.3438 199.457 92.6886 199.886 93.0017C199.981 93.0707 200.073 93.1381 200.163 93.2041C200.665 93.5724 201.09 93.8947 201.412 94.2054C201.736 94.5167 201.938 94.7992 202.017 95.0821C202.095 95.3556 202.065 95.6562 201.867 96.0248C201.46 96.7845 200.439 97.6516 199.04 98.5219C197.651 99.3859 195.922 100.233 194.142 100.964C192.362 101.695 190.536 102.306 188.955 102.701C187.363 103.098 186.05 103.267 185.279 103.138C183.659 102.866 178.41 101.582 173.243 99.776C170.66 98.8734 168.104 97.8432 166.036 96.7475C165.002 96.1997 164.094 95.6377 163.366 95.0698C162.637 94.5004 162.1 93.9336 161.796 93.3792C161.227 92.3438 160.72 91.4945 160.275 90.7492C160.212 90.6442 160.151 90.5413 160.091 90.4402C159.602 89.6199 159.197 88.9236 158.867 88.2189C158.213 86.8194 157.852 85.3745 157.775 82.8676C157.65 78.7824 157.235 70.1556 157.082 66.9908C157.278 67.2223 157.555 67.4532 157.947 67.6491C158.997 68.1739 160.843 68.4334 164.103 67.8236L164.103 67.8236C167.86 67.1193 171.716 65.1191 174.594 63.3296C176.034 62.4336 177.234 61.5875 178.059 60.9776C178.441 60.6954 178.742 60.4636 178.95 60.3008C178.976 60.4101 179.013 60.5347 179.063 60.6714C179.218 61.0961 179.502 61.6587 179.959 62.2855C180.874 63.5408 182.486 65.0576 185.159 66.2441C186.479 66.8301 187.438 67.3094 188.174 67.8797C188.902 68.4446 189.418 69.103 189.839 70.0572C190.264 71.0184 190.592 72.2797 190.942 74.0518C191.208 75.3999 191.485 77.0336 191.825 79.0441C191.931 79.675 192.044 80.343 192.165 81.051Z" fill="#D0DCF0" stroke="#031131" strokeWidth="0.410181"/>
            <path d="M170.493 87.4083C170.493 87.4083 170.205 95.6518 170.119 96.1476C170.034 96.6433 152.368 100.309 152.368 100.309C152.368 100.309 170.708 112.457 171.123 112.672C171.538 112.888 187.198 111.047 187.531 111.048C187.864 111.05 196.198 102.681 196.198 102.681L197.91 99.2068L180.442 96.1963L180.31 86.5585L170.493 87.4083Z" fill="#F3F7FF"/>
            <path d="M180.833 84.3778L167.153 83.0765L168.806 92.5623C168.806 92.5623 175.278 97.1721 181.627 92.4566C182.747 84.3869 180.833 84.3778 180.833 84.3778Z" fill="#FAF5F0"/>
            <path d="M188.927 79.0661C189.649 77.3469 188.84 75.3682 187.121 74.6465C185.402 73.9248 183.423 74.7335 182.701 76.4528C181.98 78.172 182.788 80.1507 184.507 80.8724C186.227 81.5941 188.205 80.7854 188.927 79.0661Z" fill="#FAF5F0"/>
            <path d="M167.627 79.465C168.348 77.7458 167.54 75.7671 165.821 75.0454C164.101 74.3237 162.123 75.1324 161.401 76.8517C160.679 78.5709 161.488 80.5496 163.207 81.2713C164.926 81.9929 166.905 81.1843 167.627 79.465Z" fill="#FAF5F0"/>
            <path d="M163.272 71.0648C163.272 71.0648 174.415 66.2837 178.273 62.1896C181.776 66.2131 186.808 70.2884 186.808 70.2884C186.808 70.2884 186.374 79.7206 184.746 83.6533C183.118 87.5859 179.257 91.2585 175.594 91.2412C171.932 91.2239 167.687 88.0687 165.709 83.7304C163.732 79.3921 163.272 71.0648 163.272 71.0648Z" fill="white"/>
            <g opacity="0.57">
            <path d="M185.038 83.5443C186.567 83.5515 187.814 81.9268 187.824 79.9153C187.833 77.9039 186.602 76.2675 185.073 76.2602C183.544 76.253 182.297 77.8777 182.287 79.8892C182.278 81.9006 183.51 83.537 185.038 83.5443Z" fill="#FFCCE9"/>
            </g>
            <g opacity="0.57">
            <path d="M164.986 84.4241C166.515 84.4313 167.762 82.8066 167.771 80.7951C167.781 78.7837 166.549 77.1473 165.021 77.14C163.492 77.1328 162.245 78.7575 162.235 80.769C162.226 82.7804 163.457 84.4168 164.986 84.4241Z" fill="#FFCCE9"/>
            </g>
            <path d="M169.753 75.2709C170.488 75.2743 171.089 74.4477 171.093 73.4246C171.098 72.4015 170.506 71.5692 169.77 71.5658C169.034 71.5623 168.434 72.3889 168.429 73.412C168.424 74.4352 169.017 75.2674 169.753 75.2709Z" fill="#031131"/>
            <path d="M180.532 75.3206C181.268 75.3241 181.868 74.4975 181.873 73.4744C181.877 72.4512 181.285 71.619 180.549 71.6155C179.814 71.612 179.213 72.4386 179.209 73.4618C179.204 74.4849 179.796 75.3171 180.532 75.3206Z" fill="#031131"/>
            <path d="M175.193 81.3319L175.229 73.6729C175.229 73.6729 172.906 77.783 172.901 78.9896C172.895 80.1962 175.193 81.3319 175.193 81.3319Z" fill="#27277A"/>
            <path d="M177.906 83.194C175.166 85.795 172.597 83.2808 172.488 83.1727C172.428 83.112 172.346 83.0776 172.26 83.0773C172.175 83.077 172.093 83.1106 172.032 83.1709C171.971 83.2312 171.937 83.3131 171.936 83.3987C171.936 83.4843 171.97 83.5665 172.03 83.6272C172.583 84.1483 173.241 84.5451 173.96 84.7914C174.38 84.9337 174.819 85.0084 175.262 85.0126C176.179 85.0169 177.256 84.6987 178.348 83.665C178.38 83.6362 178.406 83.6012 178.424 83.5621C178.442 83.5231 178.453 83.4807 178.454 83.4376C178.456 83.3945 178.449 83.3515 178.434 83.3111C178.419 83.2707 178.396 83.2338 178.366 83.2026C178.336 83.1713 178.3 83.1463 178.261 83.1291C178.221 83.1119 178.179 83.1027 178.136 83.1023C178.092 83.1018 178.05 83.11 178.01 83.1264C177.97 83.1427 177.934 83.167 177.903 83.1976L177.906 83.194Z" fill="#031131"/>
            <path d="M178.377 62.9225L178.227 62.7397L178.067 62.9143C174.606 66.6977 170.829 69.1148 167.916 70.586C166.46 71.3215 165.221 71.8202 164.348 72.1347C163.911 72.2919 163.565 72.4031 163.329 72.4747C163.272 72.4923 163.221 72.5075 163.176 72.5204L162.167 67.8397L178.783 58.927L188.03 69.8482C187.938 69.8051 187.839 69.7583 187.733 69.7078C187.04 69.3764 186.077 68.8871 184.998 68.2564C182.837 66.993 180.224 65.1705 178.377 62.9225Z" fill="#F3F7FF" stroke="#031131" strokeWidth="0.410181"/>
            <path d="M161.179 44.1819L143.697 44.0993C142.958 44.0958 142.248 44.3859 141.724 44.9058C141.199 45.4257 140.902 46.1328 140.898 46.8715L140.816 64.3553C140.814 64.7246 140.959 65.0795 141.219 65.3419C141.479 65.6044 141.832 65.7529 142.202 65.7548C142.571 65.7566 142.926 65.6115 143.188 65.3515C143.451 65.0915 143.599 64.7379 143.601 64.3684L143.671 49.6713C143.674 48.9328 143.971 48.2259 144.495 47.7062C145.02 47.1865 145.73 46.8964 146.468 46.8999L161.165 46.9693C161.535 46.9711 161.889 46.8261 162.152 46.5662C162.414 46.3063 162.563 45.9529 162.564 45.5836C162.565 45.4006 162.531 45.2192 162.462 45.0496C162.393 44.8801 162.291 44.7258 162.162 44.5956C162.034 44.4654 161.881 44.3619 161.712 44.2909C161.543 44.2199 161.362 44.1829 161.179 44.1819Z" fill="#1066FF"/>
            <path d="M205.476 44.3914L187.994 44.3088C187.624 44.307 187.269 44.4521 187.007 44.7121C186.744 44.9721 186.596 45.3257 186.594 45.6952C186.592 46.0645 186.738 46.4193 186.998 46.6816C187.258 46.944 187.611 47.0923 187.98 47.094L202.678 47.1635C203.043 47.1651 203.405 47.2388 203.743 47.3805C204.08 47.5221 204.386 47.7288 204.644 47.9889C204.901 48.2489 205.105 48.5571 205.243 48.8959C205.382 49.2347 205.452 49.5974 205.45 49.9633L205.38 64.6604C205.378 65.0298 205.523 65.3847 205.783 65.6471C206.043 65.9096 206.397 66.0581 206.766 66.06C207.135 66.0612 207.489 65.9158 207.751 65.6559C208.013 65.396 208.162 65.0427 208.163 64.6736L208.246 47.1898C208.249 46.4514 207.96 45.7419 207.44 45.2172C206.921 44.6924 206.214 44.3954 205.476 44.3914Z" fill="#1066FF"/>
            <path d="M160.557 116.123L145.86 116.054C145.121 116.05 144.414 115.754 143.894 115.229C143.375 114.704 143.085 113.995 143.088 113.256L143.158 98.559C143.159 98.1897 143.014 97.8348 142.755 97.5723C142.495 97.3099 142.141 97.1614 141.772 97.1594C141.402 97.1577 141.047 97.3028 140.785 97.5628C140.523 97.8228 140.374 98.1764 140.372 98.5459L140.29 116.028C140.286 116.767 140.576 117.477 141.096 118.002C141.616 118.526 142.323 118.823 143.062 118.827L160.545 118.909C160.909 118.903 161.256 118.754 161.511 118.495C161.767 118.236 161.911 117.887 161.913 117.523C161.915 117.159 161.774 116.809 161.521 116.547C161.267 116.286 160.922 116.134 160.558 116.124L160.557 116.123Z" fill="#1066FF"/>
            <path d="M206.617 97.4657C206.248 97.4642 205.893 97.6094 205.63 97.8693C205.368 98.1293 205.22 98.4828 205.218 98.8522L205.149 113.549C205.145 114.288 204.848 114.995 204.324 115.514C203.799 116.034 203.089 116.324 202.351 116.321L187.654 116.251C187.468 116.246 187.284 116.278 187.111 116.345C186.938 116.413 186.78 116.514 186.647 116.643C186.513 116.772 186.407 116.927 186.334 117.097C186.261 117.268 186.223 117.452 186.223 117.637C186.222 117.823 186.258 118.007 186.329 118.178C186.4 118.349 186.505 118.505 186.637 118.635C186.769 118.766 186.926 118.868 187.098 118.937C187.271 119.006 187.455 119.04 187.641 119.037L205.123 119.119C205.862 119.123 206.572 118.832 207.096 118.313C207.621 117.793 207.918 117.086 207.921 116.347L208.004 98.8653C208.005 98.6823 207.97 98.501 207.9 98.3316C207.831 98.1622 207.729 98.0081 207.6 97.8781C207.472 97.7482 207.318 97.6448 207.15 97.5741C206.981 97.5033 206.8 97.4665 206.617 97.4657Z" fill="#1066FF"/>
            <path d="M198.388 140.367L141.837 140.1C137.47 140.079 133.913 143.603 133.892 147.97L133.887 149.102C133.866 153.469 137.39 157.026 141.757 157.047L198.308 157.314C202.675 157.334 206.232 153.811 206.253 149.443L206.258 148.312C206.279 143.945 202.755 140.388 198.388 140.367Z" fill="#27277A"/>
            <path d="M200.721 139.409L144.171 139.142C139.804 139.121 136.246 142.645 136.226 147.012L136.22 148.143C136.2 152.511 139.724 156.068 144.091 156.088L200.641 156.356C205.008 156.376 208.565 152.853 208.586 148.485L208.591 147.354C208.612 142.987 205.088 139.429 200.721 139.409Z" fill="white"/>
            </g>
            <defs>
            <linearGradient id="paint0_linear_1088_32456" x1="215.968" y1="110.92" x2="118.8" y2="110.461" gradientUnits="userSpaceOnUse">
            <stop stopColor="#1066FF"/>
            <stop offset="1" stopColor="#1066FF" stopOpacity="0.54"/>
            </linearGradient>
            <clipPath id="clip0_1088_32456">
            <rect width="343" height="222" rx="16" fill="white"/>
            </clipPath>
            </defs>
        </SVG>
    </Container>
}

const SVG = styled.svg`
  width: 100%;
`
const Container = styled.div`
  background-color: #F9FCFF;
  border-radius: 16px;
`;
