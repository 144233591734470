import styled from 'styled-components'
import { CSSProps } from './types'

export const Loader = styled.span`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -35%);
	margin: 0px 0px 0px 5px;
	z-index: 999;
`

export const Text = styled.span<CSSProps>`
	opacity: ${({ loading }) => (loading ? 0.5 : 1)};
`
