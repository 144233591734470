import styled from 'styled-components'
import { CSSProps } from '../interface/Layout.interface'

export const AppContainer = styled.div`
	margin: 0;
	padding: 0;
	width: 100%;
	display: grid;
	grid-template-rows: 80px 1fr;
	grid-template-columns: 1fr;
	grid-template-areas:
		'header'
		'main';
`

export const Main = styled.main<CSSProps>`
	min-height: calc(100vh - 80px);
	display: flex;
	justify-content: center;
	align-items: center;
	@media (max-width: 600px) {
		min-height: ${({ isMobile }) =>
			isMobile ? 'calc(95vh - 80px)' : 'calc(100vh - 80px)'};
	}
`
