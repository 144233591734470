import { Typography } from "@mui/material";
import styled from "styled-components";

export const Form = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
`

export const Subtext = styled(Typography)``

export const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 16px;
`