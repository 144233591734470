import { FC } from 'react'
import { RotatingLines } from 'react-loader-spinner'
import { useTheme } from 'styled-components'
import { SubHeader } from '../Shared/SubHeader/SubHeader'
import { IProps } from './types'
import { Content, SubText } from './Loading.styled'
import { useI18n } from '../../hooks/useI18n'
export const Loading: FC<IProps> = ({ header, subText, light }) => {
	const theme = useTheme()
	const { translation } = useI18n()
	return (
		<Content>
			<RotatingLines
				strokeColor={light ? 'white' : 'grey'}
				strokeWidth="1"
				animationDuration="1"
				width="50"
				visible={true}
			/>
			{header ? <SubHeader text={header} /> : null}
			<SubText
				sx={{ color: light ? '#fff' : theme.typography.body2 }}
				variant="body2"
			>
				{subText ? subText : translation('loadingDefault')}
			</SubText>
		</Content>
	)
}
