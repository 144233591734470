import { useMutation } from '@tanstack/react-query'
import { IVariables, IResponse, IError } from './types'
import { useSession } from '../useSession'
import { useDispatch } from 'react-redux'
import { login as loginAction } from '../../store/Reducers/Login'
import { useRequestClientContext } from '../../Context/RequestClientProvider'
import { AxiosResponse, AxiosError } from 'axios'

export const useVerifyOTP = () => {
	const URL = '/auth/otp/verify'
	const request = useRequestClientContext()
	const dispatch = useDispatch()
	const { startSession } = useSession()
	const { data, isLoading, error, reset, mutate } = useMutation<
		IResponse,
		IError,
		IVariables,
		unknown
	>(
		async (
			variables: IVariables
		): Promise<AxiosResponse<IResponse> | AxiosError<IError> | any> => {
			return await request?.post<IResponse, IVariables, IError>(
				variables,
				URL
			)
		}
	)
	
	if (data) {
		startSession(
			data.token,
			data.grant,
			data.user._id
		)
		dispatch(loginAction(data.user))
	}
	return { verify: mutate, data, loading: isLoading, error, reset }
}
