import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { UserState, User, IToken, IKYCStatus } from '.'

const initialState: UserState = {
	documentId: null
}

export const userSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		login: (state: UserState, action: PayloadAction<User>) => {
			return action.payload
				? { ...initialState, ...state, user: { ...action.payload } }
				: { ...initialState, ...state, user: action.payload }
		},
		updateKyc: (state: UserState, action: PayloadAction<boolean>) => {
			return action.payload && state.user
				? {
						...initialState,
						...state,
						user: {
							...state.user,
							kyc: {
								isCompleted: action.payload,
								timestamp: Date.now().toString()
							}
						}
				  }
				: state
		},
		setDocumentId: (state: UserState, action: PayloadAction<string | null>) => {
			return { ...initialState, ...state, documentId: action.payload }
		},
		logout: () => {
			return { ...initialState, user: undefined }
		},
		updateKYCStatus: (
			state: UserState,
			action: PayloadAction<IKYCStatus | null>
		) => {
			return action.payload
				? {
						...initialState,
						...state,
						kycStatus: { ...action.payload }
				  }
				: { ...initialState, ...state }
		},
		setVeridasToken: (
			state: UserState,
			action: PayloadAction<IToken | null>
		) => {
			return {
				...initialState,
				...state,
				veridasAccessToken: action.payload
			}
		}
	}
})

export const {
	login,
	logout,
	setVeridasToken,
	setDocumentId,
	updateKyc,
	updateKYCStatus
} = userSlice.actions

export default userSlice.reducer
