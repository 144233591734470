import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { DefaultHeader } from './DefaultHeader'
import { AuthProvider } from '../Context/AuthProvider'
import { AppContainer, Main } from './styles/Layout.styled'
import { isMobile } from 'react-device-detect'

export const Layout: FC = () => {

  return(<AppContainer>
        <AuthProvider>
            <DefaultHeader />
            <Main isMobile={isMobile}>
                <Outlet />
            </Main>
        </AuthProvider>
    </AppContainer>
)}
