import { FC, useEffect, useMemo } from 'react'
import { Result } from '../../Components/Shared/Result/Result'
import { Arrangment } from '../../Components/Shared/Result/types'
import { useNavigate, useParams, useLocation, createSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
	setVeridasToken,
	updateKYCStatus
} from '../../store/Reducers/Login/userSlice'
import { ReactComponent as CircleWarning } from '../../assets/svgs/warnning-circle.svg';
import { IProps } from './types'
import { useI18n } from '../../hooks/useI18n'
import { IRoute } from '../../hooks/useSubscribeEvent/types'

export const Error: FC<IProps> = ({ qrKyc }) => {
	const { translation } = useI18n()
	const navigate = useNavigate()
	const location = useLocation()
	const dispatch = useDispatch()
	const { slug } = useParams()

	useEffect(()=>{
		dispatch(setVeridasToken(null))
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const redirect = () => {
		
		dispatch(updateKYCStatus(null))
		let redirectURI: IRoute = {pathname: '/select-country-and-document-type'}
		if (slug && slug.length > 0) {
			redirectURI = {pathname: `/${slug}/kyc`, search: createSearchParams(location.search).toString() }
		} 
		if (qrKyc) {
			redirectURI = { pathname: '/otp/login', search: createSearchParams(location.search).toString()}
		}
		navigate(redirectURI)
	}
	const header = useMemo(() => {
		if (qrKyc) {
			return translation('qrKYCFailedHeader')
		}
		return translation('KYCFailedHeader')
	}, [qrKyc, translation])

	return (
		<Result
			Icon={CircleWarning}
			kycError={qrKyc}
			header={header}
			subtext={translation('KYCFailedSubtext')}
			button={qrKyc ? undefined: {
				text: qrKyc ? translation('login') : translation('tryAgain'),
				arrangment: 'column' as Arrangment,
				callback: redirect,
				filled: true
			}}
		/>
	)
}
