import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { IError, IResponse, IVariables } from './types'
import { useRequestClientContext } from '../../Context/RequestClientProvider'
import { useParams } from 'react-router-dom'
import { AxiosResponse, AxiosError } from 'axios'
import { useLocalStorage } from '../useLocalStorage'

export const useSlugDetails = () => {
	const URL = '/tenants/find-by-slug'
	let { slug, company_id } = useParams()
	const { getItem } = useLocalStorage()
	const request = useRequestClientContext()
	const { error, isLoading, refetch, data } = useQuery<IResponse, IError>(
		['slugDetails'],

		async (): Promise<
			AxiosResponse<IResponse> | AxiosError<IError> | any
		> => {

			if(!slug){
				slug = company_id || getItem('TOGGGLE_REFERER_ID')?.companyId 
			}
			return await request?.get<IResponse, IVariables, IError>(
				{ slug },
				URL
			)
		},
		{
			enabled: false
		}
	)

	useEffect(() => {
		if ((!data && !error && (slug || company_id)) || (data && (data.slug !== slug || (company_id && data.slug !== company_id)))) {
			refetch()
		}
	}, [data, error, refetch, slug, company_id])

	return {
		error,
		isLoading,
		data
	}
}
