import styled from 'styled-components'
import { Typography } from '@mui/material'


export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  flex-grow: 0.35;
`

export const Text = styled(Typography)`
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 0.15px;
  color: ${(props)=> props.theme.typography.input}
`

export const LinkText = styled.a`
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: 0.15px;
  text-decoration: none;
  color: ${(props)=> props.theme.palette.primary}
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 60px;
`;
