export const customDataFromQuery = (query: URLSearchParams, email?: string ): Record<string, string> =>{
    let customData: {[name: string]: string} = {}
	let isQueryExist = localStorage.getItem('TOGGGLE_KYC_QUERY');
	query = isQueryExist ?  new URLSearchParams(JSON.parse(isQueryExist)?.query) : query;
	for (const entry of query.entries()) {
		customData[entry[0]] = entry[1]
	}
	if(!customData["email"] && email && email.length > 0){
		customData["email"] = email
	}
    return customData;
} 